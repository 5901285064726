// components/Breadcrumbs.js
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useLocation } from "react-router-dom";
import { Box, Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import { apeitNowConfig } from "../layouts/dashboard/nav/apeitNowConfig";
// import SvgColor from "src/components/svg-color";
import navconfig from "../layouts/dashboard/nav/config";
// import Dashboard from "../Assets/Dashboard.svg";
import Dashboard from "../Assets/breadDashboard.svg";
// import User from "../Assets/ph_user-fill.svg";
import User from "../Assets/breadUser.svg";
// import AppCustomization from "../Assets/ic_newSettings.svg";
import AppCustomization from "../Assets/breadApp.svg";
import cms from "../Assets/breadCms.svg";
// import cms from "../Assets/ic_cms123.svg";
// import report from "../Assets/ic_outline-report.svg";
import report from "../Assets/breadReported.svg";
// import customNotification from "../Assets/ic_outline-report123.svg";
import customNotification from "../Assets/breadCustom.svg";
import Iconify from "../components/iconify/Iconify";

const Breadcrumbs = () => {
  const location = useLocation();
  const path = location.pathname;
  const result = path?.split("/").filter(Boolean).join(" > ");

  function transformString(input) {
    // Step 1: Replace hyphen with space
    const withSpaces = input.replace(/-/g, " ");

    // Step 2: Split by spaces
    const words = withSpaces?.split(" ");

    // Step 3: Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1),
    );

    // Step 4: Join the words together
    const result = capitalizedWords.join(" ");

    return result;
  }
  const getIconPath = () => {
    switch (path?.split("/")[2]) {
      case "home":
        return (
          <img
            style={{
              width: 17,
              height: 17,
              color: " rgba(97, 177, 90, 1)",
            }}
            src={Dashboard}
            alt="app"
          />
        );
      case "user-management":
        return <img style={{ width: 17, height: 17 }} src={User} alt="User" />;
      case "app-customization":
        return (
          <img
            style={{ width: 17, height: 17 }}
            src={AppCustomization}
            alt="AppCustomization"
          />
        );
      case "cms":
        return <img style={{ width: 17, height: 17 }} src={cms} alt="cms" />;
      case "reported-users":
        return (
          <img
            style={{ width: 17, height: 17 }}
            src={report}
            alt="reported user icon"
          />
        );
      case "custom-notification":
        return (
          <img
            style={{ width: 17, height: 17 }}
            src={customNotification}
            alt="customNotification"
          />
        );
      default:
        return undefined;
    }
  };
  const newIcon = (iconName) => (
    <Box
      className="box"
      sx={{
        width: 25,
        height: 25,
        borderRadius: "0.25rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        color: "rgba(97, 177, 90, 1)",
      }}
    >
      <Iconify icon={iconName} />
    </Box>
  );
  const getIcon = (path) => {
    if (!navconfig) {
      return null; // or a default icon
    }

    const parentRoute =
      navconfig.find(
        (_r) =>
          _r.path === path ||
          (_r.subRoutes && _r.subRoutes.some((_sr) => _sr.path === path)),
      ) ||
      apeitNowConfig.find(
        (_r) =>
          _r.path === path ||
          (_r?.subRoutes && _r?.subRoutes.some((_sr) => _sr.path === path)),
      );

    return parentRoute ? (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {newIcon(parentRoute.icon.props.children.props.icon)}
        <ChevronRightIcon sx={{ ml: 0.5, color: "rgba(97, 177, 90, 1)" }} />
        <Link
          to={parentRoute?.path}
          style={{
            textDecoration: "none",
            color: "rgba(97, 177, 90, 1)",
            marginLeft: 1,
          }}
        >
          {transformString(parentRoute?.title)}
        </Link>
        {parentRoute.subRoutes && parentRoute.subRoutes.length > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#808080",
            }}
          >
            <ChevronRightIcon sx={{ ml: 0.5 }} />
            {parentRoute.subRoutes.map(
              (sub, index) =>
                path?.split("/")[3]?.split("-").join(" ").toLowerCase() ===
                  sub.name.toLowerCase() && (
                  <Typography key={index}>
                    {transformString(sub.name)}
                  </Typography>
                ),
            )}
          </Box>
        )}
        {/* <Typography>{parentRoute.subRoutes.name}</Typography> */}
      </Box>
    ) : null; // return null or a default icon if no match is found
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>{getIcon(path)}</Box>
  );
};

export default Breadcrumbs;
