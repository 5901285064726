import { Grid, Skeleton } from "@mui/material";
import React from "react";

const DashboardLoader = () => {
  return (
    <>
      <Grid container columnGap={2} rowGap={2} ml={3} py={2.5}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={250}
          height={100}
          sx={{ borderRadius: "10px" }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={250}
          height={100}
          sx={{ borderRadius: "10px" }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={250}
          height={100}
          sx={{ borderRadius: "10px" }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={250}
          height={100}
          sx={{ borderRadius: "10px" }}
        />
      </Grid>
      <Grid container columnGap={2} rowGap={2} ml={3} py={2.5}>
      <Skeleton
          variant="rectangular"
          animation="wave"
          width={350}
          height={600}
          sx={{ borderRadius: "10px" }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={750}
          height={500}
          sx={{ borderRadius: "10px" }}
        />
      </Grid>
    </>
  );
};

export default DashboardLoader;
