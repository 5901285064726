import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import { Cancel } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { useDispatch } from "react-redux";
import { apebuzzBaseURL } from "../../../../utils/url";
import api from "../../../../utils/axios";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../../ReduxToolkit/Slices/snackbarSlice";

const AnimationsRow = ({ animationData, reload }) => {
  const dispatch = useDispatch();
  const [animation, setanimation] = useState(animationData);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const handleChangePrice = (e) => {
    setanimation((prev) => ({ ...prev, price: e.target.value }));
    if (animationData.price === e.target.value) {
      setIsChanged(false);
    } else setIsChanged(true);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  // handle submit the formdata
  const handleSave = async (e) => {
    e.preventDefault();
    setLoadingButton(true);
    const formData = new FormData();
    try {
      console.log("submitted");
      if (selectedImage && selectedImage !== null) {
        formData.append("file", selectedImage);
      }
      formData.append("price", animation.price);
      const response = await api.put(
        `${apebuzzBaseURL}/api/admin/animations/animation/${animation._id}`,
        formData,
      );
      setSelectedImage(null);
      await reload();
      setIsChanged(false);
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      console.log("error=>", error);
    }
  };

  // activate deactivate
  const activateDeactivate = async (status) => {
    dispatch(showLoader());
    try {
      const res = await api.put(
        `${apebuzzBaseURL}/api/admin/animations/animation/${animation._id}`,
        {
          isActive: status,
        },
      );
      if (res?.data.success) {
        await reload();
        dispatch(hideLoader());
        setanimation((prev) => ({ ...prev, isActive: status }));
        dispatch(
          showSnackbar({
            message: `animation active status is updated successfully!`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : "something went wrong",
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };

  // handle change file
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setanimation((prev) => ({
      ...prev,
      animation: { url: URL.createObjectURL(file) },
    }));
    setIsChanged(true);
  };

  // handle rest the changes vlaue
  const handleReset = () => {
    setanimation(animationData);
    setSelectedImage(null);
  };

  return (
    <Grid
      component={"form"}
      elevation={3}
      onSubmit={handleSave}
      item
      xs={12}
      md={7}
      columnGap={1.5}
      sx={{
        // border: "1px solid black",
        // backgroundColor: "white",
        bgcolor: !animation?.isActive ? "#ggg" : "white",
        borderRadius: "6px",
        boxShadow:
          "0px 3px 3px -2px rgba(145, 158, 171, 0.2),0px 3px 4px 0px rgba(145, 158, 171, 0.14),0px 1px 8px 0px rgba(145, 158, 171, 0.12)",
        p: 2.5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          // component={Paper}
          sx={{
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
            // bgcolor: "red",
            width: "75px",
            height: "80px",
            // pt:.5
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Avatar
              alt="animation"
              src={animation?.animation?.url}
              sx={{
                width: "60px",
                height: "72px",
              }}
            />

            <Box
              size="small"
              htmlFor={`${animation._id}`}
              component={"label"}
              color="primary"
              sx={{
                position: "absolute",
                bottom: -5,
                right: -5,

                borderRadius: "100%",
                p: 0.7,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                cursor: "pointer",
                "&:active": {
                  scale: "1.05",
                },
                bgcolor: "rgb(242 237 221)",
                color: "#fb8706",
                "&:hover": {
                  backgroundColor: "white",
                },
                transition: "background ease-in-out 300ms",
              }}
            >
              <ModeEditIcon fontSize="inherit" />
              <input
                type="file"
                id={`${animation._id}`}
                name="animation-image"
                style={{ display: "none" }}
                onChange={handleChangeFile}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        alignItems={{ sm: "center", xs: "start" }}
        width={"100%"}
        columnGap={2}
        rowGap={1}
      >
        <Chip
          label={`${animation.name}`}
          variant="outlined"
          color="success"
          sx={{ minWidth: "100px" }}
        />
        <TextField
          fullWidth
          id="title"
          value={animation?.price}
          variant="outlined"
          label="Price (in pts)"
          focused
          onChange={handleChangePrice}
          type="number"
          size="small"
          //   disabled
          sx={{
            // width: "200px",
            maxWidth: "200px",
            "&>.Mui-disabled": {
              color: "rgba(134, 132, 139, 1)", // adjust background color to make it look disabled
              borderColor: "rgba(134, 132, 139, 1) !important", // adjust border color to make it look disabled
              "& .MuiInputBase-input": {
                cursor: "not-allowed", // change cursor to indicate it's disabled
                borderColor: "rgba(134, 132, 139, 1) !important",
              },
            },
            "&.MuiInputBase-input::placeholder": {
              color: "red", // style the placeholder text
            },
          }}
        />

        {loadingButton ? (
          <LoadingButton
            size="small"
            color="secondary"
            loading={loadingButton}
            disabled={
              animationData.price === animation.price &&
              animationData.animation.url === animation.animation.url
            }
            // loadingPosition="start"
            variant="contained"
            type="submit"
          >
            saving..
          </LoadingButton>
        ) : (
          <IconButton
            size="small"
            color="secondary"
            disabled={!isChanged}
            variant="contained"
            type="submit"
          >
            <SaveIcon />
          </IconButton>
        )}

        <IconButton
          size="small"
          color="error"
          onClick={handleReset}
          disabled={!isChanged}
          variant="contained"
          type="reset"
        >
          <Cancel />
        </IconButton>
      </Stack>
      <Stack
        alignItems={"center"}
        onClick={() => activateDeactivate(!animation?.isActive)}
        sx={{ cursor: "pointer" }}
      >
        <Button
          variant="outlined"
          color={animation.isActive ? "error" : "success"}
          sx={{ borderRadius: "10px", fontWeight: "light" }}
        >
          {animation?.isActive ? "Deactivate" : "Activate"}
        </Button>
      </Stack>
    </Grid>
  );
};

export default AnimationsRow;
