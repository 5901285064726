import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { hasPermissions } from "./reusablecomponents/RolePrmission/rolesPermissionAccess";

const ProtectedRoute = ({ children, slug }) => {
  const adminAuth = useSelector((state) => state.auth);
  const location = useLocation(); // To capture the current location

  // If the user is not authenticated, redirect to login page
  if (!adminAuth?.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If the user doesn't have the required permissions, redirect to 404 page
  const userHasPermission =
    adminAuth?.user?.superAdmin || hasPermissions(adminAuth?.user?.roles, slug);

  if (!userHasPermission) {
    return <Navigate to="/404" replace />;
  }

  // If the user is authenticated and has the required permissions, render the children
  return children;
};

export default ProtectedRoute;
