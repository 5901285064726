import React, { useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Pagination,
  Tooltip,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Iconify from "../../iconify";

const BusinessRequestTable = ({ rows, paginationData, setPaginationData }) => {
  const navigate = useNavigate();
  const [hoveredImage, setHoveredImage] = useState(null);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          overflow: "auto",
          "::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: "2px",
            borderRadius: "10px",
            height: "2px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "#61B15A",
            border: "none",
            borderRadius: "10px",
          },
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 650 }}
          aria-label="business requests table"
        >
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell align="left">Business Name</TableCell>
              <TableCell align="left">Document Number</TableCell>
              <TableCell align="left">Document Type</TableCell>
              <TableCell align="left">Uploaded Document</TableCell>
              <TableCell align="left">PAN Number</TableCell>
              <TableCell align="left">GST</TableCell>
              <TableCell align="right">Request Date</TableCell>
              <TableCell align="right">Business Email</TableCell>
              <TableCell align="center">Approval Status</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows?.length > 0 ? (
              rows?.map((row, _rowIndex) => (
                <TableRow
                  key={_rowIndex}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* User Column */}
                  <TableCell component="th" scope="row">
                    <Box
                      display="flex"
                      alignItems="center"
                      textTransform={"capitalize"}
                    >
                      {/* <Avatar
                        sx={{ height: "2.25rem", width: "2.25rem" }}
                        alt={row?.user?.name}
                        src={row?.user?.profilePicture?.imageUrl}
                      /> */}
                      {row?.user?.name ? row?.user?.name : "N/A"}
                    </Box>
                  </TableCell>

                  {/* Business Name Column */}
                  <TableCell align="left">
                    {row?.businessName || "N/A"}
                  </TableCell>

                  {/* Business Document Column */}
                  <TableCell align="left">
                    {row?.businessDocument?.documentNumber || "N/A"}
                  </TableCell>

                  {/* Business Document Column */}
                  <TableCell align="left">
                    {row?.businessDocument?.documentType || "N/A"}
                  </TableCell>

                  {/* Uploaded Document Column */}
                  <TableCell align="left">
                    {row?.businessDocument?.document ? (
                      <Tooltip
                        title={
                          <Box sx={{ width: "300px", height: "300px" }}>
                            <img
                              src={row?.businessDocument?.document?.docUrl}
                              alt={row?.businessDocument?.documentNumber}
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </Box>
                        }
                        arrow
                        onOpen={() =>
                          setHoveredImage(
                            row?.businessDocument?.document?.docUrl,
                          )
                        }
                        onClose={() => setHoveredImage(null)}
                      >
                        <Box
                          sx={{
                            width: "80px",
                            height: "80px",
                            border: "1px solid green",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={row?.businessDocument?.document?.docUrl}
                            alt={row?.businessDocument?.documentNumber}
                            style={{ height: "100%", width: "100%" }}
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>

                  {/* PAN Number Column */}
                  <TableCell align="left">
                    {row?.businessPAN || "N/A"}
                  </TableCell>

                  {/* GST Column */}
                  <TableCell align="left">
                    {row?.businessGST || "N/A"}
                  </TableCell>

                  {/* Request Date */}
                  <TableCell align="left">
                    {new Date(row?.dateOfRequest).toLocaleString("en-Us", {
                      dateStyle: "medium",
                    }) || "N/A"}
                  </TableCell>

                  {/* Email Column */}
                  <TableCell align="right">
                    {row?.businessEmail || "N/A"}
                  </TableCell>

                  {/* Approval Status Column */}
                  <TableCell align="center">
                    <Chip
                      label={row?.approvalStatus || "Pending"}
                      variant="filled"
                      color={
                        row?.approvalStatus === "approved"
                          ? "success"
                          : row?.approvalStatus === "rejected"
                          ? "error"
                          : "warning"
                      }
                    />
                  </TableCell>

                  {/* Action Column */}
                  <TableCell align="right">
                    <Box>
                      <IconButton
                        onClick={() =>
                          navigate(
                            `/dashboard/business/request/${row._id}`,
                          )
                        }
                      >
                        <Iconify icon="bx:bx-show" />
                      </IconButton>
                      {/* <IconButton>
                        <Iconify icon="mage:trash" />
                      </IconButton> */}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell colSpan={8} component="th" scope="row">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination & Row Selection */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 1.5,
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
          borderTop: "1px solid #F0F1F3",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          columnGap={2}
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="row"
            columnGap={1}
            alignItems="center"
          >
            <Typography>Rows per page</Typography>
            <TextField
              name="limit"
              id="pageSize"
              size="small"
              value={paginationData?.limit}
              onChange={(e) =>
                setPaginationData((prev) => ({
                  ...prev,
                  page: 1,
                  limit: e.target.value,
                }))
              }
              select
            >
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="20">20</MenuItem>
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="75">75</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </TextField>
          </Box>
          <Typography variant="body2" color="GrayText" fontSize="0.9rem">
            Showing{" "}
            {paginationData.page * paginationData.limit -
              paginationData.limit +
              1}
            -{paginationData.page * paginationData.limit} from{" "}
            {paginationData?.totalRecords}
          </Typography>
        </Box>

        <Pagination
          size="small"
          color="primary"
          count={paginationData?.totalPages}
          onChange={(e, value) =>
            setPaginationData((prev) => ({ ...prev, page: value }))
          }
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              backgroundColor: "transparent",
              color: "#006400",
              "&:hover": {
                backgroundColor: "#A3D09D",
              },
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#53760A",
              color: "white",
              "&:hover": {
                backgroundColor: "#A3D09D",
              },
            },
          }}
        />
      </Stack>
    </>
  );
};

export default BusinessRequestTable;
