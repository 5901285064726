import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  IconButton,
  Grid,
  Paper,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  CloudUpload as UploadIcon,
  Delete as TrashIcon,
  CheckBox,
} from "@mui/icons-material";
import ImageLoader from "./image/ImageLoader";
import { deleteFile, uploadFiles } from "../actions/api";

const MetaImageUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes,
  preSelectedFiles = [],
  resetCb,
  multiple = false,
  accept,
  tab,
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState(preSelectedFiles);
  const [status, setStatus] = useState("");
  const [dragging, setDragging] = useState(false);
  useEffect(() => {
    if (resetCb === "reset") {
      setFiles([]);
    }
  }, [resetCb]);
  const handleNewFileUpload = async (e) => {
    const newFiles = e.target.files;
    if (newFiles && newFiles.length > 0) {
      const validFiles = [];
      const fileData = new FormData();
      for (let i = 0; i < newFiles.length; i = 1 + 1) {
        if (newFiles[i].size <= maxFileSizeInBytes) {
          fileData.append(`media`, newFiles[i]);
          const uploadedFile = {
            name: newFiles[i].name,
            url: URL.createObjectURL(newFiles[i]),
            public_id: "",
          };
          validFiles.push(uploadedFile);
        } else {
          alert(`File size of ${newFiles[i].name} is too large (Max 2MB)`);
        }
      }
      if (validFiles?.length > 0) {
        if (!multiple) {
          const uploadResponse = await uploadFiles(fileData);
          setFiles([uploadResponse?.uploads[0]]);
          // updateFilesCb([uploadResponse?.uploadedFiles[0]]);
        } else {
          const uploadResponse = await uploadFiles(fileData);
          setFiles((prevFiles) => {
            const updatedFiles = [
              ...prevFiles,
              ...uploadResponse?.uploads,
            ];
            updateFilesCb(updatedFiles);
            return updatedFiles;
          });
        }
      }
    }
  };

  const removeFile = async (filePublicId) => {
    const uploadResponse = await deleteFile(filePublicId);
    const filteredFiles = files.filter(
      (file) => file.public_id !== filePublicId,
    );
    setFiles(filteredFiles);
    updateFilesCb(filteredFiles);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const droppedFiles = e.dataTransfer.files;
    handleNewFileUpload({ target: { files: droppedFiles } });
  };
  const handleUploadBtnClick = () => {
    fileInputField.current?.click();
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
          borderRadius: 1,
          cursor: "pointer",
          textAlign: "center",
        }}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <Typography variant="h6">{label}</Typography>
        <Typography sx={{ margin: 1, marginBottom: 0 }}>
          Drag and drop your files anywhere or
        </Typography>
        {/* <Typography variant="h6">{tab}</Typography> */}
        <Button
          variant="outlined"
          color="primary"
          onClick={handleUploadBtnClick}
          startIcon={<UploadIcon />}
          sx={{
            margin: 1,
            "&:hover": {
              backgroundColor: "rgba(82, 118, 10, 1)",
              boxShadow: "none",
              color: "white",
            },
          }}
        >
          Add meta Image
        </Button>
        <input
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          multiple={multiple}
          accept={accept}
          style={{ display: "none" }}
        />
      </Box>
      {status && (
        <Typography sx={{ color: "error.main", marginTop: 2 }}>
          {status}
        </Typography>
      )}
      <Box sx={{ mt: 0.5, width: "100%" }}>
        {/* <Typography>To Upload</Typography> */}
        <Grid container spacing={2}>
          {files.map((file, index) => (
            <Grid item key={file.media.name + index}>
              <Paper
                sx={{
                  padding: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 1,
                  boxShadow: 1,
                  position: "relative",
                }}
              >
                <ImageLoader
                  src={file.media.url}
                  alt={file.media.name}
                  width={100}
                  height={100}
                />
                <Box sx={{ position: "absolute", top: 0, right: -20 }}>
                  <IconButton
                    color="error"
                    onClick={() => removeFile(file.public_id)}
                  >
                    <TrashIcon color="error" />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default MetaImageUpload;
