import axios from "axios";
import { apeitnowBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";

export const apiUrl = "https://apebuzz.apeitnow.com";
// const apiUrl = "https://9jm8k3r4-5001.inc1.devtunnels.ms";
export const apilocal = "http://192.168.29.149:5001"
// Helper function to get the access token from cookies

const getAccessToken = () => {
  const token = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("userCookies="));
  if (!token) {
    throw new Error("Unauthorized Access");
  }
  return token.split("=")[1];
};

// Create Product
export const addProduct = async (productData) => {
  try {
    const response = await api.post(

      `${apeitnowBaseURL}/api/v1/apeitnow/merchandise/create/product`,

      productData,
    );
    console.log("product", response.data);
    return response.data; // Return the response data
  } catch (error) {
    throw new Error(`Request failed with status ${error.response?.status}`);
  }
};

export const uploadFiles = async (formData) => {
  try {
    // const accessToken = getAccessToken();
    const response = await api.post(

      `${apeitnowBaseURL}/api/v1/apeitnow/site-uploads/upload`,
      formData,
    );
    return response.data; // Return the response data
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage); // Throw an error with a meaningful message
  }
};

export const deleteFile = async (publicId) => {
  try {
    // const accessToken = getAccessToken();
    const response = await api.delete(
      `${apeitnowBaseURL}/api/v1/apeitnow/add/delete/media`,
      {
        publicId, // Pass the data directly for the DELETE request
      },
    );
    return response.data; // Return the response data
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage); // Throw an error with a meaningful message
  }
};

// export const fetchProductRequisites = async () => {
//   try {
//     const response = await axios.get("/prerquisites");
//     setPrequisiteData(response.data);
//   } catch (err) {
//     setError(err);
//   }
// };
