import React, { useState, useEffect, useMemo } from "react";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import DashboardLoader from "../../../reusablecomponents/SkeletonLoader/DashboardLoader";

import { apebuzzBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";

import AppStatsCards from "./AppStatsCards";
import Iconify from "../../../components/iconify";

function IconBox({
  icon,
  bgcolor = "#DEDEFA",
  borderColor = "#EFEFFD",
  color = "#5C59E8",
  ...props
}) {
  console.log("props-->", props);
  return (
    <Box
      sx={{
        bgcolor,
        border: `4px solid ${borderColor}`,
        color,
        p: 0.5,
        display: "flex",
        width: "3.25rem",
        height: "3.25rem",
        justifyContent: "center",
        // boxSizing: "border-box",
        alignItems: "center",
        borderRadius: "50%",
        ...props.sx, // Allow additional styles to be passed via props
      }}
      {...props} // Spread other props like event handlers
    >
      {icon} {/* Just render the passed icon element */}
    </Box>
  );
}

const AppStats = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);
  const [statsData, setStatsData] = useState(null);

  const fetchStatsData = async () => {
    try {
      const res = await api.get(
        `${apebuzzBaseURL}/api/auth/adminAuth/dashboardStats`,
      );
      console.log("re--s=>", res);
      setStatsData(res.data.data);
    } catch (error) {
      setError(error);
      console.log("error=>", error);
    }
  };

  // const fetchData = async () => {
  //   const endpoints = [
  //     `${apebuzzBaseURL}/api/auth/adminAuth/get/users/stats`,
  //     `${apebuzzBaseURL}/api/auth/adminAuth/get/new/users/stats`,
  //     `${apebuzzBaseURL}/api/auth/adminAuth/get/buzz/stats`,
  //     `${apebuzzBaseURL}/api/auth/adminAuth/get/reported/users/stats`,
  //   ];

  //   try {
  //     // const token = `Bearer ${store.getState().auth.token}`;
  //     // Fetch data from all endpoints concurrently
  //     const responses = await axios.all(
  //       endpoints.map((endpoint) =>
  //         axios.get(endpoint, {
  //           headers: {
  //             Authorization: `Bearer ${store.getState().auth.token}`,
  //           },
  //         }),
  //       ),
  //     );

  //     console.log("res-----ponses===>", responses);

  //     // Map responses to a stats object
  //     const stats = responses.reduce((acc, response) => {
  //       console.log("working inside");
  //       switch (response.config.url) {
  //         case `${apebuzzBaseURL}/api/auth/adminAuth/get/users/stats`:
  //           acc.allUsers = response.data;
  //           break;
  //         case `${apebuzzBaseURL}/api/auth/adminAuth/get/new/users/stats`:
  //           acc.newUsers = response.data;
  //           break;
  //         case `${apebuzzBaseURL}/api/auth/adminAuth/get/buzz/stats`:
  //           acc.totalBuzz = response.data;
  //           break;
  //         case `${apebuzzBaseURL}/api/auth/adminAuth/get/reported/users/stats`:
  //           acc.bugReports = response.data;
  //           break;
  //         default:
  //           break;
  //       }
  //       return acc;
  //     }, {});

  //     console.log("Fetched stats:", stats);
  //     setStats(stats);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     dispatch(hideLoader());
  //   }
  // };
  useEffect(() => {
    // fetchData(); // Call the fetch function
    fetchStatsData();
  }, []);

  if (error) {
    return (
      <Typography textAlign={"center"}>
        Some Error occured while fetching the data. Please try again.
      </Typography>
    );
  }

  return (
    <>
      {statsData === null ? (
        <DashboardLoader />
      ) : (
        <Grid container columnGap={2} rowGap={2} ml={3}>
          <Grid
            component={Paper}
            elevation={2}
            item
            xs={5.6}
            md={2.85}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2.5,
            }}
          >
            <Stack sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
                {statsData?.allUsers > 1000
                  ? `${(statsData?.allUsers / 1000).toFixed(1)}k`
                  : statsData?.allUsers}
              </Typography>
              <Typography>All Users</Typography>
            </Stack>
          </Grid>
          <Grid
            component={Paper}
            elevation={2}
            item
            xs={5.6}
            md={2.85}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2.5,
            }}
          >
            <Stack sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
                {statsData?.newUsers > 1000
                  ? `${(statsData?.newUsers / 1000).toFixed(1)}k`
                  : statsData?.newUsers}
              </Typography>
              <Typography>New Users</Typography>
            </Stack>
          </Grid>
          <Grid
            component={Paper}
            elevation={2}
            item
            xs={5.6}
            md={2.85}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2.5,
            }}
          >
            <Stack sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
                {statsData?.totalBuzz > 1000
                  ? `${(statsData?.totalBuzz / 1000).toFixed(1)}k`
                  : statsData?.totalBuzz}
              </Typography>
              <Typography>Total Buzz</Typography>
            </Stack>
          </Grid>
          <Grid
            component={Paper}
            elevation={2}
            item
            xs={5.6}
            md={2.85}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2.5,
            }}
          >
            <Stack sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
                {statsData?.totalReportedUsers > 1000
                  ? `${(statsData?.totalReportedUsers / 1000).toFixed(1)}k`
                  : statsData?.totalReportedUsers}
              </Typography>
              <Typography>Total Reports</Typography>
            </Stack>
          </Grid>
          {/* <AppStatsCards
            icon={
              <IconBox
                icon={<Iconify icon="ri:box-3-fill" />}
                bgcolor="#FBFFE0"
                borderColor="#FFF8BA"
                color="#FEC53D"
              />
            }
            statsNumber={"10"}
            statsName={"All User"}
          /> */}
        </Grid>
      )}
    </>
  );
};

export default AppStats;
