import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";

const CustomerDeliveryAddressess = ({ data }) => {
  return (
    <Box component={Paper} elevation={2} p={1} width={"100%"}>
      <Stack p={1} rowGap={1}>
        <Typography>Delivery Adress</Typography>
        <Divider />
        <Grid
          container
          display={"flex"}
          justifyContent={"space-between"}
          rowGap={2.5}
          maxHeight={"350px"}
          minHeight={"350px"}
          overflow={"auto"}
          sx={{
            "::-webkit-scrollbar": {
              backgroundColor: "transparent",
              width: "2px",
              borderRadius: "10px",
              height: "2px",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#61B15A",
              border: "none",
              borderRadius: "10px",
            },
          }}
        >
          {data && data?.length > 0 ? (
            data?.map((_address, _addIndex) => (
              <Grid
                key={_addIndex}
                p={1}
                component={Paper}
                bgcolor={"#F9FAFB"}
                item
                xs={5.75}
              >
                <Typography>{_address?.name}</Typography>
                <Typography>{_address?.phone}</Typography>
                <Typography>{_address?.email}</Typography>
                <Typography component={"address"}>
                  {`${_address?.house} ${_address?.area} ${_address?.landmark} ${_address?.city} ${_address?.state} ${_address?.country} ${_address?.zipCode}`}
                </Typography>
              </Grid>
            ))
          ) : (
            <>No records</>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};

export default CustomerDeliveryAddressess;
