import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
  TextField,
  Box,
  Typography,
  Avatar,
  Button,
  Chip,
} from "@mui/material";
import { Delete, Visibility } from "@mui/icons-material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// Define your columns
const columns = [
  { id: "orderId", label: "OrderId", minWidth: 150 },
  { id: "orderDate", label: "Order Date", minWidth: 150 },
  { id: "orderItems", label: "Order Items", minWidth: 200 },
  {
    id: "amount",
    label: "Amount",
    minWidth: 150,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "user",
    label: "Customer",
    minWidth: 150,
    align: "left",
  },
  { id: "paymentStatus", label: "Payment Status", minWidth: 150 },
  { id: "paymentMethod", label: "Order Type", minWidth: 150 },
  { id: "deliveryStatus", label: "Delivery Status", minWidth: 150 },
  { id: "invoice", label: "Download Invoice", minWidth: 150 },
  { id: "action", label: "Action", minWidth: 150 },
];

const paymentOptions = ["paid", "unpaid", "failed"];
const deliveryOptions = [
  "pending",
  "received",
  "in progress",
  "packaged",
  "shipped",
  "delivered",
  "canceled",
];

const OrderTable = ({
  rows,
  page,
  rowsPerPage,
  total,
  onPageChange,
  onRowsPerPageChange,
  handleView, // Props for handling the actions
  handleDelete,
  handlePaymentStatusChange, // Function to handle payment status change
  handleDeliveryStatusChange, // Function to handle delivery status change
}) => {
  // Function to generate PDF from HTML content
  const generatePDF = (invoice) => {
    console.log("Invoice data: ", invoice); // Log to see the HTML content

    // Create a hidden div to render the HTML content
    const element = document.createElement("div");
    // element.style.visibility = "hidden"; // Make it invisible to the user
    element.innerHTML = invoice; // Set the HTML content

    // Append it to the body (it will be removed automatically after use)
    document.body.appendChild(element);

    // const element = document.getElementById("pr_invoice");

    // Use html2canvas to capture the HTML content as a canvas
    html2canvas(element, { useCORS: true, logging: true })
      .then((canvas) => {
        console.log("Canvas: ", canvas); // Log canvas to debug

        // Ensure canvas is being generated
        if (canvas.width === 0 || canvas.height === 0) {
          console.error("Canvas is empty, check HTML content or styles.");
          return;
        }

        // Create a PDF from the canvas
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF("p", "mm", "a4"); // A4 size, portrait orientation
        const imgData = canvas.toDataURL("image/png"); // Convert the canvas to image data
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Add image to PDF
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

        // Save the generated PDF
        pdf.save("invoice.pdf");

        // Remove the hidden element after generating the PDF
        document.body.removeChild(element);
      })
      .catch((error) => {
        console.error("Error generating canvas from HTML: ", error);
      });
  };

  // Export to CSV
  const exportToCSV = (tableId) => {
    const table = document.getElementById(tableId);
    let csvContent = "";

    // Get headers, exclude "Actions" column (assume it's the 3rd column)
    const headers = table.querySelectorAll("thead th");
    const headerRow = Array.from(headers)
      .filter((header, index) => index !== 2) // Exclude "Actions" column
      .map((header) => header.innerText)
      .join(",");
    // eslint-disable-next-line
    csvContent += headerRow + "\n";

    // Get data rows
    const rows = table.querySelectorAll("tbody tr");
    rows.forEach((row) => {
      const columns = row.querySelectorAll("td");
      const rowData = Array.from(columns)
        .filter((col, index) => index !== 2) // Exclude "Actions" column
        .map((col) => col.innerText)
        .join(",");
      // eslint-disable-next-line
      csvContent += rowData + "\n";
    });

    // Create Blob for CSV download
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.csv";
    link.click();
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows?.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns?.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "action" ? (
                          <div>
                            {/* Action buttons */}
                            <IconButton onClick={() => handleView(row.orderId)}>
                              <Visibility />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(row.orderId)}
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        ) : column.id === "paymentStatus" ? (
                          // Dropdown for paymentStatus
                          <TextField
                            select
                            size="small"
                            disabled={
                              row?.paymentMethod.toLowerCase() !== "cod" ||
                              row?.paymentStatus?.toLowerCase() === "paid" ||
                              row?.paymentStatus?.toLowerCase() === "failed"
                            }
                            value={row?.paymentStatus?.toLowerCase()}
                            onChange={(e) =>
                              handlePaymentStatusChange(
                                row.orderId,
                                e.target.value,
                              )
                            }
                            fullWidth
                          >
                            {paymentOptions.map((option) => (
                              <MenuItem
                                key={option}
                                sx={{ textTransform: "capitalize" }}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : column.id === "deliveryStatus" ? (
                          // Dropdown for deliveryStatus
                          <TextField
                            select
                            size="small"
                            disabled={
                              row?.paymentStatus?.toLowerCase() === "failed"
                            }
                            value={row.deliveryStatus.toLowerCase()}
                            onChange={(e) =>
                              handleDeliveryStatusChange(
                                row.orderId,
                                e.target.value,
                              )
                            }
                            fullWidth
                          >
                            {deliveryOptions.map((option) => (
                              <MenuItem
                                key={option}
                                disabled={
                                  deliveryOptions.indexOf(
                                    option.toLocaleLowerCase(),
                                  ) <
                                  deliveryOptions.indexOf(
                                    row.deliveryStatus.toLowerCase(),
                                  )
                                }
                                value={option.toLocaleLowerCase()}
                                sx={{ textTransform: "capitalize" }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : column.format && typeof value === "number" ? (
                          column.format(value)
                        ) : column.id === "user" ? (
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"start"}
                            columnGap={1}
                          >
                            {console.log("value-->", value)}
                            <Avatar
                              sx={{ height: "1.75rem", width: "1.75rem" }}
                              src={value?.account?.profilePicture?.imageUrl}
                              alt={
                                value?.isGuest &&
                                value?.isGuest === true &&
                                value?.guestDetail?.name
                                  ? value?.guestDetail?.name
                                  : value?.account?.name
                              }
                            />
                            <Typography>
                              {value?.isGuest &&
                              value?.isGuest === true &&
                              value?.guestDetail?.name
                                ? value?.guestDetail?.name
                                : value?.account?.name}
                            </Typography>
                          </Box>
                        ) : column.id === "invoice" ? (
                          <>
                            {value && value?.invoiceHTML ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                disabled={!(value && value?.invoiceHTML)}
                                onClick={() => generatePDF(value?.invoiceHTML)}
                                sx={{ color: "green" }}
                              >
                                Download
                              </Button>
                            ) : (
                              <Chip
                                label="N/A"
                                variant="outlined"
                                sx={{ width: "100%" }}
                                color="warning"
                              />
                            )}
                          </>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </TableRow>
      </TableFooter>
    </Paper>
  );
};

export default OrderTable;
