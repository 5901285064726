import { Box, Chip } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

const SemiCircleGaugeChart = ({data}) => {
  const chartData = {
    series: [`${data?.value}`],
    chart: {
      type: "radialBar",
      offsetY: -35,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7", // Track color (light gray)
          strokeWidth: "40%",
          stroke: {
            lineCap: "round", // Ensure the ends are rounded
          },
          margin: 30, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#F0F1F3",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          // Hide the name label, but keep the value
          name: {
            show: false, // Hide name
          },
          value: {
            show: true, // Show the value
            offsetY: -45, // Adjust vertical position to avoid overlap
            fontSize: "22px",
            fontWeight: "light",
            color: "#333843", // Color of the value
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100], // Create smooth gradient stops for green color
        colorStops: [
          { offset: 0, color: "#A5D6A7", opacity: 1 }, // Light green
          { offset: 50, color: "#61B15A", opacity: 1 }, // Dark green
          { offset: 100, color: "#388E3C", opacity: 1 }, // Dark green
        ],
      },
    },
    stroke: {
      lineCap: "round", // Ensure the ends are rounded
    },
    labels: [], // No labels here, as we are adding a custom chip instead
  };

  return (
    <Box width={"100%"} position="relative">
      {/* Render the chart */}
      <Chart
        options={chartData}
        series={chartData?.series}
        type="radialBar"
        width="100%"
        height={"400px"}
      />

      {/* Render the MUI Chip component */}
      <Box
        position="absolute"
        bottom="10%" // Vertically center the chip
        left="50%" // Horizontally center the chip
        sx={{
          transform: "translate(-50%, -50%)", // Center the chip properly
          zIndex: 10, // Ensure it appears above the chart
        }}
      >
        <Chip
          label={`${data?.change}`}
          color="primary"
          size="small"
          sx={{ color: "#0D894F", bgcolor: "#E7F4EE" }}
        />
      </Box>
    </Box>
  );
};

export default SemiCircleGaugeChart;
