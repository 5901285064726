import React, { useState, useEffect, useRef } from "react";

import {
  Box,
  CardMedia,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Paper,
  Sticker,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apebuzzBaseURL } from "../../../../utils/url";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../../ReduxToolkit/Slices/snackbarSlice";
import DeleteModal from "../../../../reusablecomponents/DeleteModal/DeleteModal";
import UploadIcon from "../../../../Assets/solar_upload-line-duotone.png";
import TrashIcon from "../../../../Assets/solar_trash-bin-2-bold-duotone.svg";
import { getErrorMessage } from "../../../../reusablecomponents/CustomError/errorMessages";
import api from "../../../../utils/axios";
import StickersRow from "./StickersRow";

const ManageStickers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState(null);

  const [stickers, setstickers] = useState([]);
  const [fileSize, setFileSize] = useState(null);
  const [DeletedId, setDeletedId] = useState([]);
  const imageRef = useRef();
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  const handleDeleteImages = (index) => {
    setImages((prevImages) => prevImages?.filter((image, i) => i !== index));
  };
  const getAllSticker = async (e) => {
    dispatch(showLoader());

    try {
      const res = await api.get(`${apebuzzBaseURL}/api/admin/stickers/sticker`);
      dispatch(hideLoader());
      setstickers(res?.data?.data);
    } catch (error) {
      if (error.response) {
        dispatch(hideLoader());
        console.error("Response error:", error.response.data);
      } else if (error.request) {
        console.error("Request error:", error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  };
  useEffect(() => {
    getAllSticker();
  }, []);

  const createFormData = async () => {
    const formData = new FormData();
    if (images && images.length > 0) {
      images.forEach((img) => {
        formData.append("file", img);
      });
    }
    formData.append("name", name);
    formData.append("price", price);
    return formData;
  };

  const addSticker = async (e) => {
    e.preventDefault();
    if (images.length === 0) {
      dispatch(
        showSnackbar({ message: "Sticker is compulsory", severity: "error" }),
      );
      return;
    }
    dispatch(showLoader());
    try {
      const getFormData = await createFormData();
      const data = await api.post(
        `${apebuzzBaseURL}/api/admin/stickers/sticker`,
        getFormData,
      );
      setName("");
      setPrice(null);
      setImages([]);
      dispatch(
        showSnackbar({
          message: "Sticker added successfully!",
          severity: "success",
        }),
      );
      dispatch(hideLoader());
      if (data?.data?.success) {
        await getAllSticker();
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const imagesArray = [];
    const maxFileSizeMB = 2;
    const maxImages = 1;
    if (files.length > 1) {
      dispatch(
        showSnackbar({
          message: "Only one Sticker can be uploaded",
          severity: "error",
        }),
      );
      return;
    }
    if (images.length > 0) {
      dispatch(
        showSnackbar({
          message: "Only one Sticker can be uploaded",
          severity: "error",
        }),
      );
      return;
    }
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      const fileType = file.type.split("/")[0];
      const sizeInMB = file.size / 1024 / 1024;
      if (sizeInMB > maxFileSizeMB) {
        dispatch(
          showSnackbar({
            message: "File size should be max 2 MB",
            severity: "error",
          }),
        );
        break;
      }
      // Check if the file type is image and not more than 5 images
      if (fileType === "image") {
        if (imagesArray.length < maxImages) {
          imagesArray.push(file);
        } else {
          dispatch(
            showSnackbar({
              message: "Only 1 Sticker can be uploaded",
              severity: "error",
            }),
          );
          break;
        }
      } else {
        dispatch(
          showSnackbar({
            message: "Videos are not allowed",
            severity: "error",
          }),
        );
        break;
      }
    }
    imageRef.current.value = null;
    setImages((prevImages) => [...prevImages, ...imagesArray]);
  };
  const handleClose = () => {
    setDialog({
      open: false,
      title: "",
      message: "",
      buttonText: "",
    });
  };

  return (
    <>
      <Grid container columnGap={2} rowGap={3}>
        <Grid
          item
          xs={6}
          component={Paper}
          elevation={2}
          md={7}
          sx={{ display: "flex", p: 2 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              width: 250,
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 500,
                color: "rgba(110, 107, 123, 1)",
              }}
            >
              Add Sticker
            </Typography>
          </Box>
          <Stack
            spacing={3}
            width={"60%"}
            pr={5}
            component={"form"}
            onSubmit={addSticker}
          >
            <Box
              py={1.2}
              component={"label"}
              border={"2px dashed gray"}
              borderRadius={"10px"}
              htmlFor="image-upload"
              xs={12}
              md={4}
              minHeight={100}
              maxHeight={200}
              height={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              bgcolor={"primary"}
              sx={{ cursor: "pointer", bgcolor: "rgba(243, 243, 243, 1)" }}
            >
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                hidden
                ref={imageRef}
                onChange={handleFileChange}
              />
              <img
                src={UploadIcon}
                alt="upload icon"
                style={{ width: "25px", height: "25px" }}
              />
              <Typography fontSize="15px">Upload Sticker</Typography>
              <Typography fontSize="10px">Max : 2 MB / Sticker</Typography>
            </Box>
            {images.map((image, index) => (
              <Box key={index}>
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: -20,
                      left: 40,
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeleteImages(index)}
                    aria-label="delete"
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Box>
                <CardMedia
                  component="img"
                  image={URL.createObjectURL(image)}
                  alt={`Selected Image ${index}`}
                  sx={{ width: "50px", height: "50px" }}
                />
              </Box>
            ))}
            <Stack rowGap={1}>
              <TextField
                fullWidth
                placeholder="Write name here"
                id="name"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                required
                label="Sticker Name"
              />
              <TextField
                fullWidth
                placeholder="Provide price for the sticker"
                id="price"
                value={price}
                type="number"
                onChange={(e) => setPrice(e.target.value)}
                variant="outlined"
                required
                name="price"
                label="Price"
              />
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "start" }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  width: "150px",
                  "&:hover": {
                    backgroundColor: "rgba(82, 118, 10, 1)",
                    boxShadow: "none",
                    color: "white",
                  },
                }}
              >
                save
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Box py={4}>
        <Typography
          sx={{
            color: "rgba(110, 107, 123, 1)",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          All Sticker
        </Typography>
      </Box>
      <Grid container rowGap={1.5}>
        {stickers.length === 0
          ? "No Data found"
          : stickers?.map((sticker, _index) => {
              return (
                <React.Fragment key={sticker._id}>
                  <StickersRow
                    stickerData={sticker}
                    setDialog={setDialog}
                    reload={getAllSticker}
                  />
                </React.Fragment>
              );
            })}
      </Grid>
      {/* {dialog.open && (
        <DeleteModal
          open={dialog.open}
          handleClose={handleClose}
          dialog={dialog}
          deleteFunc={() => activateDeactivate()}
        />
      )} */}
    </>
  );
};

export default ManageStickers;
