import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { SearchRounded } from "@mui/icons-material";
import CustomerProfileCard from "./CustomerProfileCard";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";
import CustomerDeliveryAddressess from "./CustomerDeliveryAddressess";
import CustomerOrdersTable from "./CustomerOrdersTable";
import Iconify from "../../components/iconify";

const CustomerDetail = () => {
  const { customerId } = useParams();
  const [customerProfileData, setCustomerProfileData] = useState(null);
  const navigate = useNavigate();

  const [customerAllOrders, setCustomerAllOrders] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
    totalRecords: 10,
  });

  console.log("records-->", customerAllOrders);

  // get customer detail
  const getCustomerData = async () => {
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/admin/get/single/customer/${customerId}`,
      );
      console.log("custimerdata=>", response);
      setCustomerProfileData(response.data.data);
    } catch (error) {
      console.log("error==>", error);
    }
  };

  useEffect(() => {
    if (customerId) {
      getCustomerData();
    }
  }, []);

  // Function to get all customers and update pagination data
  const getCustomerOrdersList = useCallback(async () => {
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/admin/get/single/customer/orders/${customerId}?limit=${paginationData.limit}&page=${paginationData.page}`,
      );
      console.log("response customer orders=>", response.data);
      setCustomerAllOrders(response.data.data);
      setPaginationData((prevData) => ({
        ...prevData,
        page: response.data.page || prevData.page,
        limit: response.data.limit || prevData.limit,
        totalPages: response.data.totalPages || prevData.totalPages,
        totalRecords: response.data.totalOrders || prevData.totalRecords,
      }));
    } catch (error) {
      console.log("error=>", error);
    }
  }, [paginationData.page, paginationData.limit]);

  useEffect(() => {
    if (customerId) {
      getCustomerOrdersList();
    }
  }, []);

  // Transform the data for the table
  const rows =
    customerAllOrders && customerAllOrders.length > 0
      ? customerAllOrders.map((order, _i) => ({
          _id: order._id,
          products: order.products,
          total: order.payAmount,
          orderStatus: order.status,
          trackingId: 45885 + _i,
          date: order.createdAt,
          courierPartner: "Ekart",
        }))
      : [];

  return (
    <Stack rowGap={3}>
      <Typography variant="h4" fontWeight={"light"}>
        Customer Details
      </Typography>
      <Grid
        container
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"start"}
      >
        <Grid
          item
          xs={12}
          sm={5.75}
          md={3.75}
          display={"flex"}
          justifyContent={"center"}
        >
          <CustomerProfileCard
            data={{
              detail: customerProfileData?.customer,
              totalOrders: customerProfileData?.totalOrders,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5.75} md={8}>
          <CustomerDeliveryAddressess
            data={customerProfileData?.deliveryAddressess}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          padding: 2,
          overflow: "auto",
          // height: "100%",
          "::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: "2px",
            borderRadius: "10px",
            height: "2px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "#61B15A",
            border: "none",
            borderRadius: "10px",
          },
        }}
        component={Paper}
      >
        {/* Heading and Filter Button in Row */}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Typography variant="h6">Order History</Typography>
          <Button
            variant="contained"
            color="inherit"
            size="small"
            startIcon={<Iconify icon="lets-icons:export-light" />}
            sx={{ fontWeight: "light", bgcolor: "#CFEDCC", color: "#53760A" }}
          >
            Export
          </Button>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          {/* <TextField
            name="search"
            placeholder="Search customer. . ."
            variant="outlined"
            sx={{
              outline: "none",
              "&:focus": {
                outline: "none",
                border: "none",
              },
            }}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment postion="start" >
                  <SearchRounded />
                </InputAdornment>
              ),
            }}
          /> */}
          <Typography variant="h6">Order History</Typography>
          <Button
            variant="contained"
            color="inherit"
            size="small"
            startIcon={<Iconify icon="bx:slider" />}
            sx={{ fontWeight: "light", bgcolor: "#CFEDCC", color: "#53760A" }}
          >
            Filter
          </Button>
        </Box>
        <CustomerOrdersTable
          rows={rows}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
        />
      </Box>
    </Stack>
  );
};

export default CustomerDetail;
