import React from 'react'

const Shippments = () => {
  return (
    <div>
      Shippments
    </div>
  )
}

export default Shippments
