import * as React from "react";
import { Pagination, Stack, Typography, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import PropTypes from "prop-types";

export default function Table({
  rows,
  columns,
  CustomToolbar,
  changePage,
  pageInfo,
}) {
  console.log("pageInfo.limitPerPage", pageInfo.limitPerPage);
  const calculateHeight = (height) => {
    switch (height) {
      case 5:
        return 430;
      case 10:
        return 680;
      case 20:
        return 1200;
      case 30:
        return 1710;
      case 50:
        return 2610;
      case 100:
        return 4010;
      default:
        return height * 55;
    }
  };
  const getHeight = calculateHeight(pageInfo.limitPerPage);
  return (
    <Box
      sx={{
        width: "100%",
        height: "500px",
      }}
    >
      <DataGrid
        rows={rows}
        getRowId={(row) => row._id}
        columns={columns}
        // checkboxSelection
        // disableRowSelectionOnClick
        hideFooterPagination
        hideFooter
        slots={{
          toolbar: CustomToolbar,
        }}
        sx={{
          width: "fit-content",
          minWidth: "100%",
          fontWeight: 400,
          color: "#344767",
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
            {
              outline: "none",
            },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#F4F6F8 !important",
            color: "black",
            position: "sticky",
            top: 0,
            zIndex: 1,
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            color: "rgba(102, 102, 102, 1)",
            fontSize: "16px",
          },
          // // Zebra striping for rows
          // "& .MuiDataGrid-row:nth-of-type(odd)": {
          //   backgroundColor: "#F9FAFB", // Color for odd rows
          // },
          // "& .MuiDataGrid-row:nth-of-type(even)": {
          //   backgroundColor: "#FFFFFF", // Color for even rows
          // },
          // // Zebra striping for columns, but respect row background color
          // "& .MuiDataGrid-row:nth-of-type(odd) .MuiDataGrid-cell:nth-of-type(odd)":
          //   {
          //     backgroundColor: "#DDEEFE", // Odd rows and odd columns
          //   },
          // "& .MuiDataGrid-row:nth-of-type(odd) .MuiDataGrid-cell:nth-of-type(even)":
          //   {
          //     backgroundColor: "#F9FAFB", // Odd rows and even columns (keeps the row zebra color)
          //   },
          // "& .MuiDataGrid-row:nth-of-type(even) .MuiDataGrid-cell:nth-of-type(odd)":
          //   {
          //     backgroundColor: "#E3F2FD", // Even rows and odd columns
          //   },
          // "& .MuiDataGrid-row:nth-of-type(even) .MuiDataGrid-cell:nth-of-type(even)":
          //   {
          //     backgroundColor: "#FFFFFF", // Even rows and even columns (keeps the row zebra color)
          //   },
        }}
        disableColumnMenu
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography sx={{ fontSize: "14.36px" }}>{`Showing ${
          (pageInfo?.currentPage - 1) * pageInfo?.limitPerPage + 1
        } to ${Math.min(
          pageInfo?.currentPage * pageInfo?.limitPerPage,
          pageInfo?.totalData,
        )} of ${pageInfo?.totalData} entries`}</Typography>

        <Pagination
          count={pageInfo?.totalPages}
          variant="outlined"
          sx={{ float: "right", m: 2 }}
          onChange={(e, page) => changePage(page)}
        />
      </Stack>
    </Box>
  );
}
Table.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  CustomToolbar: PropTypes.func,
  changePage: PropTypes.func,
  pageInfo: PropTypes.object,
};
