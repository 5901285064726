import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Paper,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";

const WarehouseForm = () => {
  const [formData, setFormData] = useState({
    warehouseName: "",
    mobileNumber: "",
    email: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
    defaultWarehouse: false,
  });

  // Prevent negative number input and prevent scroll from changing value
  const handleNumberInput = (e) => {
    // Prevent non-numeric input
    if (e.key === "-" || e.key === "." || !/^\d*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleWheel = (e) => {
    // Prevent scroll to change value
    e.preventDefault();
  };

  // Handle form data change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Submit form data (for example, log it to console or send it to an API)
    console.log("Form Data Submitted:", formData);
    try {
      const response = await api.post(
        `${apeitnowBaseURL}/api/v1/apeitnow/delhivery/warehouse`,
        formData,
      );
      setFormData({
        warehouseName: "",
        mobileNumber: "",
        email: "",
        address: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
        defaultWarehouse: false,
      });
      console.log("response-->", response);
    } catch (error) {
      console.log("error=>", error);
    }
  };

  return (
    <Box component={Paper} sx={{ padding: 2, width: "100%", rowGap: 2 }}>
      <Stack mb={2}>
        <Typography variant="h4" component={"div"}>
          Warehouse Creation
        </Typography>
        <Typography variant="body2" mb={1} component={"p"}>
          Create a warehouse or pickup location in order to create an order.
        </Typography>
        <Divider />
      </Stack>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Warehouse Name"
              fullWidth
              size="small"
              name="warehouseName"
              value={formData.warehouseName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Mobile Number"
              fullWidth
              size="small"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Email"
              fullWidth
              size="small"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address"
              fullWidth
              size="small"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Pincode"
              fullWidth
              size="small"
              name="pincode"
              type="number"
              onWheel={handleWheel}
              InputProps={{
                inputProps: {
                  min: 0, // You can set the minimum value to 0 to prevent negative values
                },
              }}
              value={formData.pincode}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="City"
              fullWidth
              size="small"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="State"
              fullWidth
              size="small"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Country"
              fullWidth
              size="small"
              name="country"
              value={formData.country}
              onChange={handleChange}
              //   required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.defaultWarehouse}
                  onChange={handleChange}
                  name="defaultWarehouse"
                />
              }
              label="Set as Default Warehouse"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default WarehouseForm;
