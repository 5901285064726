import axios from "axios";
// import { baseUrl } from "./utils";
import { store } from "../ReduxToolkit/Store/store";


const api = axios.create({
  //   baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
api.interceptors.request.use((config) => {
  //   const token = JSON.parse(localStorage.getItem("auth_token"));
  if (store.getState().auth.token) {
    config.headers.Authorization = `Bearer ${store.getState().auth.token}`;
  }
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});
export default api;
