import React from "react";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import { areProductImagesInserted } from "../../../ReduxToolkit/Slices/imageSlice";
import ProductFileUpload from "../../../layouts/apeitnowdashboard/components/ProductFileUpload";

const ProductImage = ({
  updateDisplayProductImage,
  resetCb,
  tab,
  openedWith,
  handleClose,
}) => {
  const ProductImagesInserted = useSelector(areProductImagesInserted);
  const dispatch = useDispatch();
  const handleInsert = () => {
    if (ProductImagesInserted) {
      dispatch(
        showSnackbar({
          message: `Products inserted successfully !`,
          severity: "success",
        }),
      );

      handleClose();
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <ProductFileUpload
        accept=".jpg,.png,.jpeg"
        label={"Products Images (500px x 500px)"}
        multiple
        maxFileSizeInBytes={20971520}
        updateFilesCb={updateDisplayProductImage}
        resetCb={resetCb}
        tab={tab}
        openedWith={openedWith}
        handleClose={handleClose}
      />
      {/* <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              bgcolor: "#CFEDCC",
              color: "#61B15A",
              "&:hover": {
                backgroundColor: "rgba(82, 118, 10, 1)",
                boxShadow: "none",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleInsert}
            sx={{
              bgcolor: "#CFEDCC",
              color: "#61B15A",
              ml: 2,
              "&:hover": {
                backgroundColor: "rgba(82, 118, 10, 1)",
                boxShadow: "none",
                color: "white",
              },
            }}
          >
            Insert
          </Button>
        </Box>
      </Box> */}
    </Box>
  );
};

export default ProductImage;
