import {
  Box,
  Button,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { SearchRounded } from "@mui/icons-material";
import BusinessRequestTable from "./BusinessRequestsTable";
import { apebuzzBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";
import Iconify from "../../iconify";
import { hideLoader, showLoader } from "../../../ReduxToolkit/Slices/loaderSlice";

const BusinessOnboardingRequests = () => {
  const dispatch = useDispatch();
  const [allBusinessRequests, setAllBusinessRequests] = useState([]);
  const [requestTab, setRequestTab] = React.useState("pending");
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
    totalRecords: 10,
  });

  // Function to get all customers and update pagination data
  const getallBusinessRequests = useCallback(async () => {
    try {
      dispatch(showLoader())
      const response = await api.get(
        `${apebuzzBaseURL}/api/admin/businessOnboard/business?status=${requestTab}&limitPerPage=${paginationData.limit}&pageNo=${paginationData.page}`,
      );
     
      setAllBusinessRequests(response.data.data);
      setPaginationData((prevData) => ({
        ...prevData,
        page: response?.pageInfo?.currentPage || prevData.page,
        limit: response?.pageInfo?.limitPerPage || prevData.limit,
        totalPages: response?.pageInfo?.totalPages || prevData.totalPages,
        totalRecords: response?.pageInfo?.totalData || prevData.totalRecords,
      }));
      dispatch(hideLoader())
    } catch (error) {
      console.log("error=>", error);
    }
  }, [paginationData.page, paginationData.limit, requestTab]);

  useEffect(() => {
    getallBusinessRequests(); // Call API whenever page or limit changes
  }, [requestTab]);

  console.log("records-->", allBusinessRequests);

  // Transform the data for the table
  const rows =
    allBusinessRequests && allBusinessRequests.length > 0
      ? allBusinessRequests.map((business) => ({
          approvalStatus: business?.approvalStatus,
          businessName: business?.businessName,
          businessType: business?.businessType,
          businessEmail: business?.businessEmail,
          businessDocument: business?.businessDocument,
          businessPAN: business?.panNumber ? business?.panNumber : "N/A",
          businessGST: business?.gstNumber ? business?.gstNumber : "N/A",
          dateOfRequest: business?.createdAt,
          _id: business?._id,
          user: business?.user,
        }))
      : [];

  // change business request tabs
  const handleChangeRequestTab = (event, newrequestTab) => {
    setRequestTab(newrequestTab);
  };

  return (
    <Box
      sx={{
        padding: 2,
        overflow: "auto",
        height: "100%",
        "::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: "2px",
          borderRadius: "10px",
          height: "2px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#61B15A",
          border: "none",
          borderRadius: "10px",
        },
      }}
    >
      {/* Heading and Filter Button in Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Business Requests</Typography>
        <Button
          variant="contained"
          color="inherit"
          size="small"
          startIcon={<Iconify icon="lets-icons:export-light" />}
          sx={{ fontWeight: "light", bgcolor: "#CFEDCC", color: "#53760A" }}
        >
          Export
        </Button>
      </Box>
      <Box sx={{ width: "100%", mb: 2 }}>
        <Tabs
          value={requestTab}
          onChange={handleChangeRequestTab}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="business requests"
        >
          <Tab value="pending" label="Pending" />
          <Tab value="rejected" label="Rejected" />
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <TextField
          name="search"
          placeholder="Search customer. . ."
          variant="outlined"
          sx={{
            outline: "none",
            "&:focus": {
              outline: "none",
              border: "none",
            },
          }}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          color="inherit"
          size="small"
          startIcon={<Iconify icon="bx:slider" />}
          sx={{ fontWeight: "light", bgcolor: "#CFEDCC", color: "#53760A" }}
        >
          Filter
        </Button>
      </Box>
      <BusinessRequestTable
        rows={rows}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
      />
    </Box>
  );
};

export default BusinessOnboardingRequests;
