import React from "react";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Iconify from "../../components/iconify";

const TransactionsTable = ({ rows, paginationData, setPaginationData }) => {
  console.log("rows===>", rows);
  const navigate = useNavigate();


  
  return (
    <>
    <TableContainer
      component={Paper}
      sx={{
        // maxHeight: 400,
        overflow: "auto",
        "::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: "2px",
          borderRadius: "10px",
          height: "2px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#61B15A",
          border: "none",
          borderRadius: "10px",
        },
      }}
    >
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Payment Id</TableCell>
            <TableCell align="left">Order Id</TableCell>
            <TableCell align="left">Paid Amount</TableCell>
            <TableCell align="center">Currency</TableCell>
            <TableCell align="right">Payment Mode</TableCell>
            <TableCell align="right">Payment Date</TableCell>
            <TableCell align="center">Payment Status</TableCell>
            <TableCell align="right">Buyer Name</TableCell>
            <TableCell align="right">Buyer Email</TableCell>
            <TableCell align="right">Buyer Phone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows?.length > 0 ? (
            rows?.map((row, _rowIndex) => (
              <TableRow
                key={_rowIndex}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {/* <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    columnGap={1}
                  >
                    <Avatar
                      sx={{ height: "2.25rem", width: "2.25rem" }}
                      alt={
                        row?.isGuest
                          ? row?.guestDetail?.name
                          : row?.account?.name
                      }
                      src={row?.account?.profilePicture?.imageUrl}
                    />
                    {row?.isGuest
                      ? row?.guestDetail?.name
                      : row?.account?.name
                      ? row?.account?.name
                      : "N/A"}
                  </Box> */}
                  {row?.paymentId}
                </TableCell>
                <TableCell align="left">
                  {/* {row?.email ? row?.email : "N/A"} */}
                  {row?.orderId}
                </TableCell>
                <TableCell align="left">
                  {/* {row?.phone} */}
                  {row?.paymentDetail?.amount}
                </TableCell>
                <TableCell align="center">
                  {/* {row?.totalOrders} */}
                  {row?.paymentDetail?.currency}
                </TableCell>
                <TableCell align="center">
                  {row?.paymentDetail?.payment_mode}
                </TableCell>
                <TableCell align="right">
                  {new Date(row?.createdAt).toLocaleString("en-In", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={`${row?.paymentDetail?.status}`}
                    variant="outlined"
                    // color="success"
                    sx={{
                      minWidth: "5rem",
                      bgcolor:
                        row?.paymentDetail?.status.toLowerCase() === "pending"
                          ? "warning.main" // MUI standard color for warning (orange)
                          : row?.paymentDetail?.status.toLowerCase() ===
                            "failed"
                          ? "error.main" // MUI standard color for error (red)
                          : row?.paymentDetail?.status.toLowerCase() ===
                            "refunded"
                          ? "info.main" // MUI standard color for info (blue)
                          : "success.main", // MUI standard color for success (green)
                      // color: "white",
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  {row?.paymentDetail?.buyer_name}
                </TableCell>
                <TableCell align="center">
                  {row?.paymentDetail?.email}
                </TableCell>
                <TableCell align="center">
                  {row?.paymentDetail?.phone}
                </TableCell>

                {/* <TableCell align="right">
                  <Box>
                    <IconButton
                      onClick={() =>
                        navigate(
                          `/dashboard/manage-customer/customer-detail/${row._id}`,
                        )
                      }
                    >
                      <Iconify icon="bx:bx-show" />
                    </IconButton>
                    <IconButton>
                      <Iconify icon="mage:trash" />
                    </IconButton>
                  </Box>
                </TableCell> */}
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell colSpan={6} component="th" scope="row">
                No Data Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          p: 1.5,
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #F0F1F3",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          columnGap={2}
          alignItems={"center"}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            columnGap={1}
            alignItems={"center"}
          >
            <Typography>Rows per page</Typography>
            <TextField
              name="limit"
              id="pageSize"
              size="small"
              value={paginationData?.limit}
              onChange={(e) =>
                setPaginationData((prev) => ({
                  ...prev,
                  page: 1,
                  limit: e.target.value,
                }))
              }
              select
            >
              <MenuItem value={"10"}>10</MenuItem>
              <MenuItem value={"20"}>20</MenuItem>
              <MenuItem value={"50"}>50</MenuItem>
              <MenuItem value={"75"}>75</MenuItem>
              <MenuItem value={"100"}>100</MenuItem>
            </TextField>
          </Box>
          <Typography variant="body2" color={"GrayText"} fontSize={"0.9rem"}>
            Showing{" "}
            {paginationData.page * paginationData.limit -
              paginationData.limit +
              1}
            -{paginationData.page * paginationData.limit} from{" "}
            {paginationData?.totalRecords}
          </Typography>
        </Box>

        <Pagination
          size="small"
          color="primary"
          count={paginationData?.totalPages}
          onChange={(e, value) =>
            setPaginationData((prev) => ({ ...prev, page: value }))
          }
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              // Inactive page numbers
              backgroundColor: "transparent",
              color: "#006400", // Inactive text color
              "&:hover": {
                backgroundColor: "#A3D09D", // Inactive hover state
              },
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              // Active page number
              backgroundColor: "#53760A", // Active background color
              color: "white", // Active text color (green in this example)
              "&:hover": {
                backgroundColor: "#A3D09D", // Hover effect for active page
              },
            },
          }}
        />
      </Stack>
    </>
  );
};

export default TransactionsTable;
