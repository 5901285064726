import {
  Avatar,
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useState } from "react";

const CartTable = ({ rows, paginationData, setPaginationData }) => {
  // Use an object to track which row is open, where the key is the row ID or index
  const [openRow, setOpenRow] = useState({});

  // Handle row toggle
  const toggleRow = (rowId) => {
    setOpenRow((prevOpenRow) => ({
      ...prevOpenRow,
      [rowId]: !prevOpenRow[rowId], // Toggle the specific row's open state
    }));
  };

  console.log("rows===>", rows);
  const navigate = useNavigate();

  return (
    <>
    <TableContainer
      component={Paper}
      sx={{
        // maxHeight: 400,
        overflow: "auto",
        "::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: "2px",
          borderRadius: "10px",
          height: "2px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#61B15A",
          border: "none",
          borderRadius: "10px",
        },
      }}
    >
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Total Items</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell align="left">Email ID</TableCell>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="right">Last Interaction</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows?.length > 0 ? (
            rows?.map((row, _rowIndex) => (
              <React.Fragment key={_rowIndex}>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        columnGap: 0.5,
                      }}
                    >
                      <Typography variant="body2" component="div">
                        {`Items : ${row.totalItems}`}
                      </Typography>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleRow(row._id)} // Toggle the specific row
                      >
                        {openRow[row._id] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexDirection={"row"}
                      columnGap={1}
                      sx={{ textTransform: "capitalize" }}
                    >
                      <Avatar
                        sx={{ height: "2.25rem", width: "2.25rem" }}
                        alt={row?.user?.account?.name}
                        src={row?.user?.account?.profilePicture?.imageUrl}
                      />
                      {row?.user?.account?.name
                        ? row?.user?.account?.name
                        : "N/A"}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    {row?.email ? row?.email : "N/A"}
                  </TableCell>
                  <TableCell align="left">{row?.phone}</TableCell>
                  <TableCell align="right">
                    {new Date(row?.lastIntraction).toLocaleString("en-In", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ bgcolor: "#CFEDCC" }}>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={5}
                  >
                    <Collapse
                      in={openRow[row._id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1, mt: 2, mb: 2 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          Products
                        </Typography>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>Product</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Discounted Price</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Discount</TableCell>
                              <TableCell>Total price</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row?.items?.map((pr, _index) => (
                              <TableRow key={_index}>
                                <TableCell>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={pr?.productId?.image[0].url}
                                      alt={pr?.productId?.name}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        marginRight: 10,
                                        borderRadius: "25%",
                                      }}
                                    />

                                    <Typography variant="body2">
                                      {pr?.productId?.name}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell>{pr?.price}</TableCell>
                                <TableCell>{pr?.salePrice}</TableCell>
                                <TableCell>{pr?.quantity}</TableCell>
                                <TableCell>{pr?.discount}</TableCell>
                                <TableCell>{pr?.total}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          ) : (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell colSpan={6} component="th" scope="row">
                No Data Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
      {/* Pagination and Footer */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          p: 1.5,
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
          borderTop: "1px solid #F0F1F3",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          columnGap={2}
          alignItems={"center"}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            columnGap={1}
            alignItems={"center"}
          >
            <Typography>Rows per page</Typography>
            <TextField
              name="limit"
              id="pageSize"
              size="small"
              value={paginationData?.limit}
              onChange={(e) =>
                setPaginationData((prev) => ({
                  ...prev,
                  page: 1,
                  limit: e.target.value,
                }))
              }
              select
            >
              <MenuItem value={"10"}>10</MenuItem>
              <MenuItem value={"20"}>20</MenuItem>
              <MenuItem value={"50"}>50</MenuItem>
              <MenuItem value={"75"}>75</MenuItem>
              <MenuItem value={"100"}>100</MenuItem>
            </TextField>
          </Box>
          <Typography variant="body2" color={"GrayText"} fontSize={"0.9rem"}>
            Showing{" "}
            {paginationData.page * paginationData.limit -
              paginationData.limit +
              1}
            -{paginationData.page * paginationData.limit} from{" "}
            {paginationData?.totalRecords}
          </Typography>
        </Box>

        <Pagination
          size="small"
          color="primary"
          count={paginationData?.totalPages}
          onChange={(e, value) =>
            setPaginationData((prev) => ({ ...prev, page: value }))
          }
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              // Inactive page numbers
              backgroundColor: "transparent",
              color: "#006400", // Inactive text color
              "&:hover": {
                backgroundColor: "#A3D09D", // Inactive hover state
              },
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              // Active page number
              backgroundColor: "#53760A", // Active background color
              color: "white", // Active text color (green in this example)
              "&:hover": {
                backgroundColor: "#A3D09D", // Hover effect for active page
              },
            },
          }}
        />
      </Stack>
    </>
  );
};

export default CartTable;
