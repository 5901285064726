import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  setAllSelectedVideos,
  setVideo,
} from "../../../ReduxToolkit/Slices/imageSlice";

const ProductVideoContainer = ({ videos, setProductVideo, tab }) => {
  const dispatch = useDispatch();
  const removeFile = (indexToDelete) => {
    const updatedImages = [...videos];
    updatedImages.splice(indexToDelete, 1);
    dispatch(setAllSelectedVideos(updatedImages));
  };
  return (
    <>
      <Grid container spacing={2}>
        {videos.length !== 0 &&
          videos.map((file, index) => (
            <Grid item key={file.file.media.name + index}>
              <Paper
                sx={{
                  padding: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 1,
                  boxShadow: 1,
                  position: "relative",
                }}
              >
                <video
                  width="300"
                  controls
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    // objectFit: "contain",
                  }}
                >
                  <source src={file.file.media.url} type="video/mp4" />
                </video>

                <Box sx={{ position: "absolute", top: 0, right: 5 }}>
                  <IconButton color="error" onClick={() => removeFile(index)}>
                    <CloseIcon sx={{ color: "#61B15A" }} />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default ProductVideoContainer;
