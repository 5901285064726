import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import SplineCharts from "./components/SplineCharts";

const Statistics = ({ data }) => {
  return (
    <Box width={"100%"} component={Paper} p={1}>
      <Typography variant="body1" component={"div"}>
        Statistics
        <Typography variant="body2" component={"p"}>
          Revenue and Sales
        </Typography>
      </Typography>
      <SplineCharts
        data={{
          salesCollecion:
            data?.monthlyStats?.length > 0
              ? data?.monthlyStats?.map((_data) => _data.totalSalesCollection)
              : [],
          revenue:
            data?.monthlyStats?.length > 0
              ? data?.monthlyStats?.map((_data) => _data.totalRevenue)
              : [],
          months:
            data?.monthlyStats?.length > 0
              ? data?.monthlyStats?.map((_data) => _data.month)
              : [],

          totalSales:
            data?.monthlyStats?.length > 0
              ? data?.monthlyStats?.map((_data) => _data.totalSale)
              : [],
        }}
      />
    </Box>
  );
};

export default Statistics;
