import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  all: [],
  thumbnail: [], // Single image for thumbnail
  thumbnailImages: [], // Array of objects for product images
  productImages: [], // Array of objects for product images
  varientImages: [], // Array of objects for product images
  allVarientImages: [], // Array of objects for product images
  categoryImage: [], // Single image for category
  video: [], // Single video
  selectedVideos: [], // Single video
};

const imageSlice = createSlice({
  name: "Images",
  initialState,
  reducers: {
    setAllProduct: (state, action) => {
      state.all = action.payload;
    },
    setThumbnail: (state, action) => {
      state.thumbnail = action.payload;
    },
    setThumbnailImages: (state, action) => {
      state.thumbnailImages = action.payload;
    },
    addThumbnail: (state, action) => {
      state.thumbnail.push(...action.payload);
    },
    setProductImages: (state, action) => {
      state.productImages = action.payload;
    },
    setVarientImages: (state, action) => {
      state.varientImages = action.payload;
    },
    setAllVarientImages: (state, action) => {
      state.allVarientImages = action.payload;
    },
    addVarientImages: (state, action) => {
      state.varientImages.push(...action.payload);
    },
    addProductImage: (state, action) => {
      console.log("action.payload", action.payload);
      state.all.push(...action.payload);
    },
    setCategoryImage: (state, action) => {
      state.categoryImage = action.payload;
    },
    setVideo: (state, action) => {
      state.video = action.payload;
    },
    setAllSelectedVideos: (state, action) => {
      state.selectedVideos = action.payload;
    },
    addVideo: (state, action) => {
      state.video.push(...action.payload);
    },
    clearImages: (state) => {
      state.thumbnail = [];
      state.thumbnailImages = [];
      state.productImages = [];
      state.categoryImage = [];
      state.video = [];
      state.selectedVideos = [];
      state.all = [];
    },
  },
});
// Selectors to check image insertion status
export const isThumbnailInserted = (state) => state.Images.thumbnail.length > 0;
export const areProductImagesInserted = (state) =>
  state.Images.productImages.length > 0;
export const areAllProduct = (state) => state.Images.all.length > 0;
export const areVarientImagesInserted = (state) =>
  state.Images.varientImages.length > 0;
export const isCategoryImageInserted = (state) =>
  state.Images.categoryImage !== null;
export const isVideoInserted = (state) => state.Images.video?.length > 0;

export const {
  setAllProduct,
  setThumbnail,
  setThumbnailImages,
  addThumbnail,
  setProductImages,
  setVarientImages,
  setAllVarientImages,
  addVarientImages,
  addProductImage,
  setCategoryImage,
  setVideo,
  setAllSelectedVideos,
  addVideo,
  clearImages,
} = imageSlice.actions;

// Export the reducer to configure the store
export default imageSlice.reducer;
