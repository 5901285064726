import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Chip,
  Paper,
  Popover,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Table from "../../reusablecomponents/Table/Table";
import CustomToolbar from "../../reusablecomponents/CustomToolbar/CustomToolbar";
import { ColumnHeader, fontColor } from "../../GlobelStyles/commonStyle";
import dataFormatter from "../../reusablecomponents/DataFormatter/dataFormatter";
import DeleteModal from "../../reusablecomponents/DeleteModal/DeleteModal";
import { hideLoader, showLoader } from "../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../ReduxToolkit/Slices/snackbarSlice";
import { getErrorMessage } from "../../reusablecomponents/CustomError/errorMessages";
import ProductTable from "./ProductTable";
import { apeitnowBaseURL } from "../../utils/url";
import api from "../../utils/axios";

// https://apebuzz.apeitnow.com/api/v1/apeitnow/merchandise/all/products?
const AllProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // To Open View Profile and delete Popover
  const [anchorEl, setAnchorEl] = useState(null);
  // for pagination
  const [paginationData, setPaginationData] = useState({
    count: 5,
    totalProducts: 5,
    totalPages: 1,
    page: 1,
  });
  const [product, setAllProduct] = useState([]);
  const getAllProduct = async () => {
    dispatch(showLoader());
    try {
      const res = await api.get(

        `${apeitnowBaseURL}/api/v1/apeitnow/merchandise/all/products?count=${paginationData.count}&pageNo=${paginationData.page}`,

      );
      console.log("all product", res?.data);
      setAllProduct(res?.data?.products);
      setPaginationData({
        count: res?.data.count,
        totalProducts: res?.data.totalProducts,
        totalPages: res?.data.totalPages,
        page: res?.data.page,
      });
      if (res?.data.success) {
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getAllProduct();
  }, []);
  const [active, setActive] = useState({
    id: "",
    status: false,
  });
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  // to open single profile
  const [preview, setPreview] = useState({ id: "", open: false });
  // It is using in popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // Configuration object
  const config = {
    staticKeys: {
      SrNo: (item, index) =>
        `${
          index +
          paginationData.page * paginationData.count -
          paginationData.count +
          1
        }`,
    },
  };
  const formattedProducts = dataFormatter(product, config);
  // to change pagination
  const changePage = (page) => {
    setPaginationData({ ...paginationData, page });
  };
  // to change pageSize
  const changePageSize = (count) => {
    setPaginationData({ ...paginationData, page: 1, count });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDialog({
      open: false,
      title: "",
      buttonText: "",
      data: {
        id: null,
      },
    });
  };
  // to close the popover
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  // to set the active state
  const handleClick = (event, _id, status) => {
    setActive({ _id, status });
    setAnchorEl(event.currentTarget);
  };
  // to set the active state
  const handlePreview = (_id, status) => {
    setActive({ _id, status });
    setPreview({ id: _id, open: true });
  };
  const columns = [
    {
      field: "SrNo",
      width: 85,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Sr.No</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            fontSize="15px"
            sx={{
              py: 1,
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "name",
      width: 255,
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Name</Typography>,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt="userProfile"
              src={params?.row?.image[0]?.url}
              sx={{ width: "30px", height: "33.5px" }}
            />
            <Typography
              sx={{
                ...fontColor,
                fontWeight: 400,
                py: 1,
                ml: 2,
              }}
              //   onClick={() => {
              //     handlePreview(params.row._id, params.row.isActive);
              //   }}
            >
              {params.value === ""
                ? "N/A"
                : params.value
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "sku",
      width: 145,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>SKU</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              fontWeight: 400,
              py: 1.6,
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "category",
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Category</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              fontWeight: 400,
              py: 1.6,
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "price",
      align: "center",
      headerAlign: "center",
      width: 120,
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Sale Price</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              py: 1.6,
              fontWeight: 400,
            }}
          >
            RS {params.value}.00
            {/* {params.value > 1000
              ? `${(params.value / 1000).toFixed(1)}k`
              : params.value} */}
          </Typography>
        );
      },
    },
    // {
    //   field: "Status",
    //   width: 140,
    //   align: "center",
    //   headerAlign: "center",
    //   sortable: false,
    //   filterable: false,
    //   renderHeader: () => <Typography sx={ColumnHeader}>Status</Typography>,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Box width="100%">
    //           <Chip
    //             label={params.value}
    //             color={params.value === "Active" ? "success" : "error"}
    //             // label="active"
    //             // color="success"
    //             size="small"
    //             sx={{
    //               borderRadius: "5px",
    //               minWidth: "6rem",
    //               color: "white !important",
    //             }}
    //           />
    //         </Box>
    //       </>
    //     );
    //   },
    // },
    {
      field: "Action",
      width: 115,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Action</Typography>,
      renderCell: (params) => {
        return (
          <>
            <Typography
              sx={{
                fontSize: "20.33px",
                fontWeight: "400",
                cursor: "pointer",
                py: 1.6,
              }}
              //   onClick={(e) =>
              //     handleClick(e, params.row._id, params.row.isActive)
              //   }
            >
              <MoreVertIcon />
            </Typography>
            <Popover
              id={id}
              elevation={3}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Stack
                direction={"column"}
                spacing={0.5}
                component={Paper}
                variant={"gradient"}
                sx={{ width: "130px", p: 1 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    color: "#6E6B7B",
                    textDecoration: "none",
                    borderRadius: "5px",
                    // width: "138px",
                    justifyContent: "center",
                    "&:hover": {
                      bgcolor: "rgba(82, 118, 10, 1)",
                      color: "white !important",
                      "& p": {
                        color: "white !important",
                      },
                    },
                  }}
                  //   onClick={() => {
                  //     setDialog({
                  //       open: true,
                  //       title: `Are you sure you want to ${
                  //         active.status ? "deactivate" : "activate"
                  //       } this user ?`,
                  //       buttonText: `${
                  //         active.status ? "Deactivate" : "Activate"
                  //       }`,
                  //       data: {
                  //         id: active.id,
                  //       },
                  //     });
                  //     setAnchorEl(null);
                  //   }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      py: 0.5,
                      "&:hover": {
                        color: "white.main",
                      },
                    }}
                  >
                    {active.status ? "Deactivate User" : "Activate User"}
                  </Typography>
                </Box>
              </Stack>
            </Popover>
          </>
        );
      },
    },
  ];
  const handleClosePreview = () => {
    setPreview({ id: "", open: false });
  };
  return (
    <>
      <Stack direction={"column"}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{
              bgcolor: "#53760A",
              color: "white",
              "&:hover": {
                bgcolor: "#53760A",
                color: "white",
              },
            }}
            onClick={() =>
              navigate("/dashboard/product/add-product", { replace: true })
            }
          >
            Add Product
          </Button>
        </Box>
        <Box mt={2}>
          {/* Table component */}
          {formattedProducts?.length === 0 ? (
            "No data found"
          ) : (
            <ProductTable
              rows={formattedProducts}
              columns={columns}
              CustomToolbar={() =>
                CustomToolbar(paginationData, changePageSize)
              }
              pageCount={paginationData.totalPages}
              pageInfo={paginationData}
              changePage={changePage}
            />
          )}
        </Box>
      </Stack>
    </>
  );
};

export default AllProduct;
