import { Clear, Close, SearchRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import Iconify from "../../components/iconify";
import CustomerTable from "./CustomerTable";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";

const CustomerList = () => {
  const [allCustomers, setAllCustomers] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
    totalRecords: 10,
  });
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // Function to get all customers and update pagination data
  const getAllCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/admin/get/all/customers?limit=${paginationData.limit}&page=${paginationData.page}`,
      );

      setLoading(false);
      setAllCustomers(response.data.data);
      setPaginationData((prevData) => ({
        ...prevData,
        page: response.data.page || prevData.page,
        limit: response.data.limit || prevData.limit,
        totalPages: response.data.totalPages || prevData.totalPages,
        totalRecords: response.data.totalUsers || prevData.totalRecords,
      }));
    } catch (error) {
      setLoading(false);
      console.log("error=>", error);
    }
  }, [paginationData.page, paginationData.limit]);

  useEffect(() => {
    getAllCustomers(); // Call API whenever page or limit changes
  }, [getAllCustomers]);

  // Transform the data for the table
  const rows =
    allCustomers && allCustomers.length > 0
      ? allCustomers.map((user) => ({
          account: user.account,
          email: user.email,
          totalOrders: user.orderCount,
          phone: user.phone,
          accountCreated: user.createdAt,
          _id: user._id,
        }))
      : [];

  const filteredRows = useMemo(() => {
    return rows?.filter(
      (row) => {
        return (
          row.account.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.phone.toLowerCase().includes(searchTerm.toLowerCase())
        );
      },
      [rows, searchTerm, loading],
    );
  });

  return (
    <Box
      sx={{
        padding: 2,
        overflow: "auto",
        height: "100%",
        "::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: "2px",
          borderRadius: "10px",
          height: "2px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#61B15A",
          border: "none",
          borderRadius: "10px",
        },
      }}
    >
      {/* Heading and Filter Button in Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Customers</Typography>
        {/* <Button
          variant="contained"
          color="inherit"
          size="small"
          startIcon={<Iconify icon="lets-icons:export-light" />}
          sx={{ fontWeight: "light", bgcolor: "#CFEDCC", color: "#53760A" }}
        >
          Export
        </Button> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <TextField
          name="search"
          placeholder="Search"
          variant="outlined"
          sx={{
            outline: "none",
            "&:focus": {
              outline: "none",
              border: "none",
            },
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {searchTerm && (
                  <IconButton onClick={() => setSearchTerm("")} edge="end">
                    <Clear />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />

        {/* <Button
          variant="contained"
          color="inherit"
          size="small"
          startIcon={<Iconify icon="bx:slider" />}
          sx={{ fontWeight: "light", bgcolor: "#CFEDCC", color: "#53760A" }}
        >
          Filter
        </Button> */}
      </Box>
      <CustomerTable
        rows={filteredRows}
        loading={loading}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
      />
    </Box>
  );
};

export default CustomerList;
