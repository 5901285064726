import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Box,
  Chip,
  Paper,
  Popover,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import axios from "axios";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Table from "../../reusablecomponents/Table/Table";
import CustomToolbar from "../../reusablecomponents/CustomToolbar/CustomToolbar";
import { ColumnHeader, fontColor } from "../../GlobelStyles/commonStyle";
import dataFormatter from "../../reusablecomponents/DataFormatter/dataFormatter";
import DeleteModal from "../../reusablecomponents/DeleteModal/DeleteModal";
import { hideLoader, showLoader } from "../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../ReduxToolkit/Slices/snackbarSlice";
import { getErrorMessage } from "../../reusablecomponents/CustomError/errorMessages";
import ProductTable from "../Product/ProductTable";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";

const AllCoupon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // To Open View Profile and delete Popover
  const [anchorEl, setAnchorEl] = useState(null);
  // for pagination
  const [paginationData, setPaginationData] = useState({
    count: 5,
    totalProducts: 5,
    totalPages: 1,
    page: 1,
  });
  const [coupon, setAllCoupon] = useState([]);

  const getAllCoupon = async () => {
    dispatch(showLoader());
    try {
      const res = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/coupons/all/coupon?limit=${paginationData.count}&page=${paginationData.page}`,
      );
      console.log("all coupon", res?.data);
      setAllCoupon(res?.data?.data || []);
      setPaginationData({
        count: res?.data.count,
        totalProducts: res?.data.totalCoupons,
        totalPages: res?.data.totalPages,
        page: res?.data.page,
      });
      dispatch(hideLoader());
      // if (res?.data.success) {
      // }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getAllCoupon();
  }, [paginationData.page, paginationData.count]);
  const [active, setActive] = useState({
    id: "",
    status: false,
  });
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  // to open single profile
  const [preview, setPreview] = useState({ id: "", open: false });
  // It is using in popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // Configuration object
  const config = {
    staticKeys: {
      SrNo: (item, index) =>
        `${
          index +
          paginationData.page * paginationData.count -
          paginationData.count +
          1
        }`,
      Discount: (item, index) => item.discount.value,
    },
  };
  const formattedCoupons = dataFormatter(coupon, config);
  // to change pagination
  const changePage = (page) => {
    setPaginationData({ ...paginationData, page });
  };
  // to change pageSize
  const changePageSize = (count) => {
    setPaginationData({ ...paginationData, page: 1, count });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDialog({
      open: false,
      title: "",
      buttonText: "",
      data: {
        id: null,
      },
    });
  };
  // to close the popover
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  // to set the active state
  const handleClick = (event, _id, status) => {
    setActive({ _id, status });
    setAnchorEl(event.currentTarget);
  };
  // to set the active state
  const handlePreview = (_id, status) => {
    setActive({ _id, status });
    setPreview({ id: _id, open: true });
  };
  function formatMongooseDate(mongooseDateString) {
    return dayjs(mongooseDateString).format("D MMM YYYY");
  }
  const columns = [
    {
      field: "SrNo",
      width: 85,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Sr.No</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            fontSize="15px"
            sx={{
              py: 1,
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "couponName",
      width: 255,
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Title</Typography>,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                ...fontColor,
                fontWeight: 400,
                py: 1,
                ml: 2,
              }}
              //   onClick={() => {
              //     handlePreview(params.row._id, params.row.isActive);
              //   }}
            >
              {params.value === ""
                ? "N/A"
                : params.value
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "couponCode",
      width: 145,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => (
        <Typography sx={ColumnHeader}>Voucher Code</Typography>
      ),
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              fontWeight: 400,
              py: 1.6,
            }}
          >
            {params.value === "" ? "N/A" : params.value}
          </Typography>
        );
      },
    },
    {
      field: "Discount",
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Discount</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              fontWeight: 400,
              py: 1.6,
            }}
          >
            {params.value === "" ? "N/A" : params.value}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      align: "center",
      headerAlign: "center",
      width: 120,
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Active</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              py: 1.6,
              fontWeight: 400,
            }}
          >
            {formatMongooseDate(params.value)}
          </Typography>
        );
      },
    },
    {
      field: "validTill",
      align: "center",
      headerAlign: "center",
      width: 120,
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Expire</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              py: 1.6,
              fontWeight: 400,
            }}
          >
            {formatMongooseDate(params.value)}
          </Typography>
        );
      },
    },
    // {
    //   field: "Action",
    //   width: 115,
    //   sortable: false,
    //   filterable: false,
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 1,
    //   renderHeader: () => <Typography sx={ColumnHeader}>Action</Typography>,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             py: 1.6,
    //           }}
    //         >
    //           {/* <Icon
    //             icon="ic:round-edit"
    //             style={{ fontSize: "25px", cursor: "pointer" }}
    //             onClick={() =>
    //               navigate(
    //                 `/dashboard/couponManagement/update-coupon/${params.row._id}`,
    //               )
    //             }
    //           /> */}
    //           <Icon
    //             icon="material-symbols:delete-outline"
    //             style={{ fontSize: "25px", marginLeft: 8 }}
    //           />
    //         </Box>
    //       </>
    //     );
    //   },
    // },
  ];
  const handleClosePreview = () => {
    setPreview({ id: "", open: false });
  };

  return (
    <>
      <Stack direction={"column"}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{
              bgcolor: "#53760A",
              color: "white",
              "&:hover": {
                bgcolor: "#53760A",
                color: "white",
              },
            }}
            onClick={() =>
              navigate("/dashboard/coupon-management/add-coupon", {
                replace: true,
              })
            }
          >
            Add Coupon
          </Button>
        </Box>
        <Box mt={2}>
          {/* It is a product table reusable component i am using same one for coupon Table  */}
          {formattedCoupons?.length === 0 ? (
            "No data found"
          ) : (
            <ProductTable
              rows={formattedCoupons}
              columns={columns}
              CustomToolbar={() =>
                CustomToolbar(paginationData, changePageSize)
              }
              pageCount={paginationData.totalPages}
              pageInfo={paginationData}
              changePage={changePage}
            />
          )}
        </Box>
      </Stack>
    </>
  );
};

export default AllCoupon;
