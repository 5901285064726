import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import Iconify from "../../components/iconify";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";

const ShippingChargesCard = ({ title, shippingCharges, save, name }) => {
  const [charges, setCharges] = useState(shippingCharges);
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  // API call to save the updated charges
  const handleSaveCharges = async () => {
    if (charges === "" || charges < 0) {
      alert("Please enter a valid charge amount.");
      return;
    }

    setLoading(true);

    try {
      // Make an API call to update the charges
      const response = await api.post(
        `${apeitnowBaseURL}/api/v1/apeitnow/shipping/charges/add-update`,
        charges,
      );

      // Assuming the response contains the updated charges
      if (response.data.success) {
        alert("Shipping charges updated successfully!");
        save(charges); // Update parent component's state with the new charges
        setIsEditable(false); // Disable editing after saving
      } else {
        alert("Failed to update shipping charges.");
      }
    } catch (error) {
      console.error("Error saving charges:", error);
      alert("Error updating charges. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // UseEffect to re-render when the shippingCharges prop changes
  useEffect(() => {
    setCharges(shippingCharges);
  }, [shippingCharges]); // This will run whenever shippingCharges prop changes

  return (
    <Stack
      component={Paper}
      rowGap={2.5}
      p={4}
      minWidth={"250px"}
      maxWidth={"250px"}
      elevation={2}
    >
      <Typography variant={"body1"}>{title}</Typography>
      <Stack rowGap={2}>
        <TextField
          size="small"
          fullWidth
          name={name}
          disabled={!isEditable}
          value={charges[name]}
          onChange={(e) =>
            setCharges((prev) => ({ ...prev, [name]: e.target.value }))
          } // Update charges value
          type="number"
          inputProps={{ min: 0 }} // Prevent negative values
        />
        {isEditable ? (
          <>
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveCharges}
              sx={{
                bgcolor: "#53760A",
                color: "white",
                gap: 0.5,
                ":hover": {
                  color: "white",
                },
              }}
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="success"
            onClick={() => setIsEditable(true)} // Enable editing
            sx={{
              bgcolor: "#CFEDCC",
              color: "#61B15A",
              gap: 0.5,
              ":hover": {
                color: "white",
              },
            }}
          >
            <span>Edit</span>
            <Iconify icon="ic:twotone-edit" />
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const ManageShippingCharges = () => {
  const [shippingCharges, setShippingCharges] = useState({
    fixedCost: 100,
    internationalCost: 500,
  });

  // Function to update the shipping charge in the parent component
  const handleSave = (newCharge, type) => {
    // setShippingCharges((prev) => ({
    //   ...prev,
    //   [type]: newCharge, // Update the specific shipping charge
    // }));
  };

  const getShippingCharges = async () => {
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/shipping/get-charges`,
      );
      console.log("response=>", response);

      if (response.data.data) {
        setShippingCharges({
          fixedCost: response.data.data.fixedCost || 100,
          internationalCost: response.data.data.internationalCost || 500,
        });
      }
    } catch (error) {
      console.log("Error fetching charges:", error);
    }
  };

  useEffect(() => {
    getShippingCharges(); // Fetch the charges on page load
  }, []);

  return (
    <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} columnGap={2}>
      <ShippingChargesCard
        title={"Shipping Charge in India"}
        shippingCharges={shippingCharges} // Pass correct charge from parent
        name={"fixedCost"}
        save={(newCharges) => handleSave(newCharges, "fixedCost")}
      />
      <ShippingChargesCard
        title={"International Shipping Charge"}
        shippingCharges={shippingCharges} // Pass correct charge from parent
        name={"internationalCost"}
        save={(newCharges) => handleSave(newCharges, "internationalCost")}
      />
    </Box>
  );
};

export default ManageShippingCharges;
