import React, { useState, useEffect } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// mocks_
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import { logout } from "../../../ReduxToolkit/Slices/authSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import account from "../../../_mock/account";
import { apebuzzBaseURL, apecenterBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  // },
  {
    label: "Profile",
    icon: "eva:person-fill",
  },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const adminProfile = useSelector((state) => state.auth.profile);
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleLogout = async () => {
    try {
      dispatch(logout());
      dispatch(
        showSnackbar({
          message: `Logout Successfully !`,
          severity: "success",
        }),
      );
      navigate("/login");
      // }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
    }
  };
  const vieProfilePageHandler = () => {
    handleClose();
    navigate("/dashboard/profile");
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: "5px",
          maxWidth: "250px",
          minWidth: "110px",
          px: 1,
        }}
        columnGap={2}
      >
        <Typography variant="subtitle2" sx={{ color: "text.primary", flex: 1 }}>
          {adminProfile?.name}
        </Typography>

        <IconButton
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              "&:before": {
                zIndex: 1,
                content: "''",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                position: "absolute",
                // borderColor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          {!adminProfile?.profilePicture?.url ? (
            <Avatar src={account.photoURL} alt="photoURL" />
          ) : (
            <Avatar
              src={adminProfile?.profilePicture?.url}
              alt="photoURL"
              sx={{ width: "33px", height: "33px" }}
            />
          )}
        </IconButton>
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 120,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 0.5 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={vieProfilePageHandler}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 0.5 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
