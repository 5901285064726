import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  MenuItem,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Menu,
  Box,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import axios from "axios";
import { Preview, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import OrdersTable from "./OrdersTable";
import { apeitnowBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";

const Orders = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch orders with pagination
  const getAllOrdersData = useCallback(async () => {
    setLoading(true); // Set loading state to true
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/orders/all?page=${
          page + 1
        }&limit=${rowsPerPage}`,
      );
      setRows(
        response?.data?.orders && response?.data?.orders?.length > 0
          ? response?.data?.orders?.map((_order) => ({
              orderId: _order._id,
              orderDate: new Date(_order.orderDate).toLocaleDateString("en-Us"),
              orderItems: _order.products
                .map((product) => product.productName)
                .join(", "),
              amount: _order.payAmount,
              paymentStatus: _order.paymentStatus,
              paymentMethod: _order.paymentMethod,
              deliveryStatus: _order.status,
              user: _order?.user,
              invoice: _order?.invoice,
            }))
          : [],
      );
      setTotal(response?.data?.pagination?.totalOrders);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false); // Set loading state to false after data fetch completes
    }
  }, [page, rowsPerPage]);

  // Handlers for filters, pagination, and search
  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset page number when search term changes
  }, []);

  const handleFilterIconClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleFilterChange = useCallback(
    (filterValue) => {
      if (filterValue && !selectedFilters.includes(filterValue)) {
        setSelectedFilters((prevFilters) => [...prevFilters, filterValue]);
      }
      setAnchorEl(null);
      setPage(0); // Reset page number when filters change
    },
    [selectedFilters],
  );

  const handlePaymentTypeChange = useCallback((event, newValue) => {
    setSelectedPaymentType(newValue);
    setPage(0); // Reset page number when payment type changes
  }, []);

  const handleRemoveFilter = useCallback((filter) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.filter((item) => item !== filter),
    );
    setPage(0); // Reset page number when filter is removed
  }, []);

  const handleResetFilters = useCallback(() => {
    setSearchTerm("");
    setSelectedFilters([]);
    setSelectedPaymentType("");
    setPage(0); // Reset page number when filters are reset
  }, []);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset page number when rows per page changes
  }, []);

  const handleView = useCallback(
    (id) => {
      navigate(`/dashboard/orders/view/${id}`);
    },
    [navigate],
  );

  const handleDelete = useCallback((id) => {
    // Logic to open the modal for deletion confirmation
    console.log("Delete ID:", id);
  }, []);

  useEffect(() => {
    getAllOrdersData();
  }, [getAllOrdersData]);

  const filteredRows = useMemo(() => {
    return rows?.filter((row) => {
      return (
        (row.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.orderItems.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (selectedFilters.length > 0
          ? selectedFilters.includes(row.deliveryStatus)
          : true) &&
        (selectedPaymentType
          ? selectedPaymentType.toLowerCase() === "cod"
            ? row.paymentMethod.toLowerCase() === "cod"
            : row.paymentMethod.toLowerCase() !== "cod"
          : true)
      );
    });
  }, [rows, searchTerm, selectedFilters, selectedPaymentType, loading]);

  console.log("filtered row ==>", filteredRows);

  // Function to handle payment status change
  const handlePaymentStatusChange = async (targetId, value) => {
    try {
      setLoading(true);
      const response = await api.put(
        `${apeitnowBaseURL}/api/v1/apeitnow/orders/update/${targetId}/payment-status`,
        {
          paymentStatus: value?.toLowerCase(),
        },
      );

      // setRows((prev) => [
      //   prev.map((_row) => {
      //     if (_row.orderId === targetId) {
      //       return { ..._row, paymentStatus: value?.toLowerCase() };
      //     }
      //     return _row;
      //   }),
      // ]);
      console.log("response==>", response);
      if (response.success) {
        getAllOrdersData();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const handleDeliveryStatusChange = async (targetId, value) => {
    try {
      setLoading(true);
      const response = await api.put(
        `${apeitnowBaseURL}/api/v1/apeitnow/orders/update/${targetId}/delivery-status`,
        {
          deliveryStatus: value?.toLowerCase(),
        },
      );
      console.log("response===>", response);
      if (response.success) {
        getAllOrdersData();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  return (
    <Box>
      {/* Search and Filters */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Manage Orders</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton onClick={() => setSearchTerm("")} edge="end">
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          display={"flex"}
          columnGap={2}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <FormControl variant="outlined" size="small">
            <ToggleButtonGroup
              value={selectedPaymentType}
              exclusive
              onChange={handlePaymentTypeChange}
              aria-label="payment type"
              size="small"
            >
              <ToggleButton value="" aria-label="All">
                All
              </ToggleButton>
              <ToggleButton value="COD" aria-label="COD">
                COD
              </ToggleButton>
              <ToggleButton value="Non-COD" aria-label="Non-COD">
                Non-COD
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>

          <IconButton onClick={handleFilterIconClick} size="medium">
            <FilterListIcon />
          </IconButton>

          {/* Filter Menu */}
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleFilterChange("")}>All</MenuItem>
            <MenuItem onClick={() => handleFilterChange("Pending")}>
              Pending
            </MenuItem>
            <MenuItem onClick={() => handleFilterChange("Received")}>
              Received
            </MenuItem>
            <MenuItem onClick={() => handleFilterChange("In Progress")}>
              In Progress
            </MenuItem>
            <MenuItem onClick={() => handleFilterChange("Packaged")}>
              Packaged
            </MenuItem>
            <MenuItem onClick={() => handleFilterChange("Shipped")}>
              Shipped
            </MenuItem>
            <MenuItem onClick={() => handleFilterChange("Delivered")}>
              Delivered
            </MenuItem>
            <MenuItem onClick={() => handleFilterChange("Cancelled")}>
              Cancelled
            </MenuItem>
          </Menu>
        </Grid>
        {selectedFilters?.length > 0 && (
          <Grid item xs={12}>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {selectedFilters.map((filter) => (
                <Chip
                  key={filter}
                  label={filter}
                  onDelete={() => handleRemoveFilter(filter)}
                  color="primary"
                />
              ))}
              {selectedPaymentType && (
                <Chip
                  label={`Payment Type: ${selectedPaymentType}`}
                  onDelete={() => setSelectedPaymentType("")}
                  color="secondary"
                />
              )}
              <Chip
                label="Reset Filters"
                onDelete={handleResetFilters}
                color="default"
              />
            </div>
          </Grid>
        )}
      </Grid>

      {/* Loading Indicator */}
      {loading ? (
        <Box sx={{ width: "100%", margin: "auto" }} mt={4}>
          <Skeleton animation="wave" sx={{ height: 100 }} />
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{ height: 200 }}
          />
          <Skeleton animation="wave" sx={{ height: 100 }} />
        </Box>
      ) : (
        <Box mt={2}>
          <OrdersTable
            rows={filteredRows}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            handleDelete={handleDelete}
            handleView={handleView}
            handlePaymentStatusChange={handlePaymentStatusChange} // Function to handle payment status change
            handleDeliveryStatusChange={handleDeliveryStatusChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default Orders;
