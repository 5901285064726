import {
  Badge,
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import parse from "html-react-parser";

import { apeitnowBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";

const SingleOrderScreen = () => {
  const [loading, setLoading] = useState(false);
  const { orderId } = useParams();
  const [orderInfo, setOrderInfo] = useState(null);
  const [invoice, setInvoice] = useState(null);

  const currencySymbol = "₹";
  const navigate = useNavigate();
  const dateFormat = (date) => new Date(date).toLocaleDateString();

  const decimalBalance = (amount) => amount;

  const checkPercentage = (total, percentage) => total * (percentage / 100);

  const handleGetOrderDetail = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/orders/get/sinlge/order/${orderId}`,
      );
      console.log("Data==>", response.data);
      setOrderInfo(response?.data.order);
      setInvoice(response?.data.invoice);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    if (orderId) {
      handleGetOrderDetail();
    }

    return () => {
      setOrderInfo(null);
    };
  }, []);

  // Function to generate PDF from HTML content
  const generatePDF = () => {
    console.log("Invoice data: ", invoice); // Log to see the HTML content

    // Create a hidden div to render the HTML content
    const element = document.createElement("div");
    // element.style.visibility = "hidden"; // Make it invisible to the user
    element.innerHTML = invoice; // Set the HTML content

    // Append it to the body (it will be removed automatically after use)
    document.body.appendChild(element);

    // const element = document.getElementById("pr_invoice");

    // Use html2canvas to capture the HTML content as a canvas
    html2canvas(element, { useCORS: true, logging: true })
      .then((canvas) => {
        console.log("Canvas: ", canvas); // Log canvas to debug

        // Ensure canvas is being generated
        if (canvas.width === 0 || canvas.height === 0) {
          console.error("Canvas is empty, check HTML content or styles.");
          return;
        }

        // Create a PDF from the canvas
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF("p", "mm", "a4"); // A4 size, portrait orientation
        const imgData = canvas.toDataURL("image/png"); // Convert the canvas to image data
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Add image to PDF
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

        // Save the generated PDF
        pdf.save("invoice.pdf");

        // Remove the hidden element after generating the PDF
        document.body.removeChild(element);
      })
      .catch((error) => {
        console.error("Error generating canvas from HTML: ", error);
      });
  };

  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%", margin: "auto" }} mt={4}>
          <Skeleton animation="wave" sx={{ height: 100 }} />
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{ height: 200 }}
          />
          <Skeleton animation="wave" sx={{ height: 100 }} />
        </Box>
      ) : (
        <Box component={Paper} bgcolor={"transparent"} border="1px solid red">
          <Paper sx={{ p: 2 }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5" component={"p"}>
                Order Details
              </Typography>
              <Box
                columnGap={1}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                {invoice && invoice !== null ? (
                  <>
                    <Button
                      variant="outlined"
                      onClick={generatePDF}
                      color="info"
                    >
                      Download Invoce
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Stack>
          </Paper>

          {/* {invoice ? (
            <div
              dangerouslySetInnerHTML={{ __html: invoice }}
              id="pr_invoice"
              style={{
                width: "100%",
                backgroundColor: "white",
                // visibility: "hidden",
                // position: "absolute",
                // zIndex: -200,
                // top: 0,
              }}
            />
          ) : ( */}
            <Box p={3}>
              {orderInfo?._id && (
                <div>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <Typography variant="h6">Order Details:</Typography>
                      {orderInfo?.paymentId && (
                        <Typography>
                          Transaction Id: <span>{orderInfo?.paymentId}</span>
                        </Typography>
                      )}
                      <Typography>
                        Order ID: <span>{orderInfo?._id}</span>
                      </Typography>
                      <Typography>
                        Order Date:{" "}
                        <span>{dateFormat(orderInfo?.orderDate)}</span>
                      </Typography>
                      <Typography>
                        Payment Status:{" "}
                        <Badge
                          sx={{
                            bgcolor:
                              orderInfo?.paymentStatus &&
                              typeof orderInfo?.paymentStatus === "string" &&
                              orderInfo?.paymentStatus.toLowerCase() === "paid"
                                ? "green"
                                : "red",
                            color: "white",
                            px: 0.2,
                            py: 0.05,
                          }}
                        >
                          {orderInfo?.paymentStatus}
                        </Badge>
                      </Typography>
                      <Typography>
                        Order Status:{" "}
                        <Badge color="primary">{orderInfo?.status}</Badge>
                      </Typography>
                      <Typography>
                        Payment Method: <span>{orderInfo?.paymentMethod}</span>
                      </Typography>
                      {orderInfo?.coupon?.code && (
                        <Typography>
                          Applied Coupon:{" "}
                          <span>{`${orderInfo?.coupon?.code} - ${orderInfo?.coupon?.discount}%`}</span>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="h6">
                        Delivery Information:
                      </Typography>

                      {orderInfo?.deliveryInfo?.area && (
                        <Typography>
                          Delivery Area:{" "}
                          <span>{orderInfo?.deliveryInfo?.area}</span>
                        </Typography>
                      )}
                      <Typography>
                        Delivery Cost: <span>Free</span>
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="h6">Billing Address:</Typography>
                      <Typography>
                        Full Name: <span>{orderInfo?.billingInfo?.name}</span>
                      </Typography>
                      <Typography>
                        Phone: <span>{orderInfo?.billingInfo?.phone}</span>
                      </Typography>
                      <Typography>
                        Email: <span>{orderInfo?.billingInfo?.email}</span>
                      </Typography>
                      <Typography>
                        House: <span>{orderInfo?.billingInfo?.house}</span>
                      </Typography>
                      <Typography>
                        City: <span>{orderInfo?.billingInfo?.city}</span>
                      </Typography>
                      <Typography>
                        State/Province:{" "}
                        <span>{orderInfo?.billingInfo?.state}</span>
                      </Typography>
                      <Typography>
                        Post/Zip Code:{" "}
                        <span>{orderInfo?.billingInfo?.zipCode}</span>
                      </Typography>
                      <Typography>
                        Country: <span>{orderInfo?.billingInfo?.country}</span>
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="h6">Shipping Address:</Typography>
                      <Typography>
                        Full Name: <span>{orderInfo?.shippingInfo?.name}</span>
                      </Typography>
                      <Typography>
                        Phone: <span>{orderInfo?.shippingInfo?.phone}</span>
                      </Typography>
                      <Typography>
                        Email: <span>{orderInfo?.shippingInfo?.email}</span>
                      </Typography>
                      <Typography>
                        House: <span>{orderInfo?.shippingInfo?.house}</span>
                      </Typography>
                      <Typography>
                        City: <span>{orderInfo?.shippingInfo?.city}</span>
                      </Typography>
                      <Typography>
                        State/Province:{" "}
                        <span>{orderInfo?.shippingInfo?.state}</span>
                      </Typography>
                      <Typography>
                        Post/Zip Code:{" "}
                        <span>{orderInfo?.shippingInfo?.zipCode}</span>
                      </Typography>
                      <Typography>
                        Country: <span>{orderInfo?.shippingInfo?.country}</span>
                      </Typography>
                    </Grid>
                  </Grid>

                  <TableContainer component={Paper} sx={{ mt: 3 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Products</TableCell>
                          <TableCell>Image</TableCell>

                          <TableCell>Quantity</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Sub Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderInfo?.products?.map((product, idx) => (
                          <TableRow key={idx}>
                            <TableCell>{idx + 1}</TableCell>
                            <TableCell>{product?.productName}</TableCell>
                            <TableCell>
                              <img
                                src={`${product?.product?.image[0]?.url}`}
                                width={50}
                                height={50}
                                alt={product?.productName}
                              />
                            </TableCell>

                            <TableCell>{product?.quantity}</TableCell>
                            <TableCell>
                              {currencySymbol + product?.price}
                            </TableCell>
                            <TableCell>{product?.total}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Box mt={3}>
                    <Typography variant="h6">Order Summary:</Typography>
                    <Typography>
                      Sub Total:{" "}
                      <span>{currencySymbol + orderInfo?.totalPrice}</span>
                    </Typography>
                    <Typography>
                      Discount:{" "}
                      <span>
                        {currencySymbol +
                          decimalBalance(
                            checkPercentage(
                              orderInfo?.totalPrice,
                              orderInfo?.coupon?.discount || 0,
                            ),
                          )}
                      </span>
                    </Typography>
                    <Typography>
                      Shipping Charge:{" "}
                      <span>{currencySymbol + orderInfo?.shipping}</span>
                    </Typography>

                    <Typography>
                      Total:{" "}
                      <span>
                        {currencySymbol + decimalBalance(orderInfo?.payAmount)}
                      </span>
                    </Typography>
                  </Box>
                </div>
              )}
            </Box>
          {/* )} */}
        </Box>
      )}
    </>
  );
};

export default SingleOrderScreen;
