// component
import { Box } from "@mui/material";
import SvgColor from "../../../components/svg-color";
import Iconify from "../../../components/iconify/Iconify";

export const newIcon = (iconName) => (
  <Box
    className="box"
    sx={{
      width: 25,
      height: 25,
      borderRadius: "0.25rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#BDC4CB",
      color: "currentcolor",
    }}
  >
    <Iconify icon={iconName} />
  </Box>
);

export const icon = (name) => (
  // <img
  //   style={{ width: 25, height: 25 }}
  //   src={`/assets/icons/navbar/${name}.svg`}
  //   alt="sidebarIcon"
  // />

  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{
      width: 1,
      height: 1,
      bgcolor: "rgba(207, 237, 204, 1)",
      color: "rgba(97, 177, 90, 1)", // Set icon color
    }}
  />
);

const superAdminConfig = [
  {
    type: "route",
    title: "manage role",
    path: "/dashboard/role-management",
    icon: newIcon("ph:user-fill"),
    // subRoutes: [
    //   {
    //     type: "route",
    //     name: "create role",
    //     key: "create-role",
    //     path: "/dashboard/role-management/create-role",
    //   },
    // ],
  },
  // {
  //   type: "route",
  //   title: "manage permissions",
  //   path: "/dashboard/permissions",
  //   icon: newIcon("ph:user-fill"),
  // },
  {
    type: "route",
    title: "manage admins",
    path: "/dashboard/manage-admins",
    icon: newIcon("ph:user-fill"),
  },
];

export default superAdminConfig;
