import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";


const TopSellingProductTable = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState({ name: "", country: "" });


  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleApplyFilter = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData =
    data?.filter(
      (row) =>
        filter.name
          ? row.productName.toLowerCase().includes(filter?.name?.toLowerCase())
          : true,
      //   &&
      // (filter.country
      //   ? row.country.toLowerCase().includes(filter.country.toLowerCase())
      //   : true),
    ) || 0;

  return (
    <Box
      sx={{
        padding: 2,
        overflow: "auto",
        height: "100%",
        "::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: "2px",
          borderRadius: "10px",
          height: "2px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#CFEDCC",
          border: "none",
          borderRadius: "10px",
        },
      }}
    >
      {/* Heading and Filter Button in Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="body2" fontSize={"1rem"}>
          Top Selling Products
        </Typography>

        {/* <Button
          variant="contained"
          color="inherit"
          onClick={handleFilterClick}
          startIcon={<Iconify icon="bx:slider" />}
        >
          Filter Options
        </Button> */}
      </Box>

      {/* Filter Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
        MenuListProps={{
          "aria-labelledby": "filter-button",
        }}
      >
        <MenuItem>
          <TextField
            label="Product Name"
            variant="outlined"
            fullWidth
            value={filter.name}
            onChange={(e) => setFilter({ ...filter, name: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
        </MenuItem>
        {/* <MenuItem>
          <TextField
            label="Country"
            variant="outlined"
            fullWidth
            value={filter.country}
            onChange={(e) => setFilter({ ...filter, country: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
        </MenuItem> */}
        <MenuItem>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilter}
          >
            Apply Filter
          </Button>
        </MenuItem>
      </Menu>

      {/* Table with Scrollable Container */}
      <TableContainer
        sx={{
          maxHeight: 400,
          overflow: "auto",
          height: "100%",
          "::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: "2px",
            borderRadius: "10px",
            height: "2px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
            border: "none",
            borderRadius: "10px",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead sx={{ backgroundColor: "#E0E2E7" }}>
            <TableRow>
              <TableCell>
                <strong>Product</strong>
              </TableCell>
              <TableCell>
                <strong>Sales</strong>
              </TableCell>
              <TableCell>
                <strong>Grand Total</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={row?.product.image?.url}
                        alt={row?.product?.productName}
                        style={{ width: 40, height: 40, marginRight: 10 }}
                      />
                      <Box>
                        <Typography variant="body1">
                          {row?.product?.productName}
                        </Typography>
                        {/* <Typography variant="body2" color="textSecondary">
                          {row?.product?.productSku}
                        </Typography> */}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{row?.product?.quantity}</TableCell>
                  <TableCell>{row?.product?.total}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination with Custom Styling */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page"
        sx={{
          "& .MuiTablePagination-select": {
            color: "#4CAF50", // Green color for rows per page
          },
          "& .MuiTablePagination-toolbar": {
            color: "#4CAF50", // Green color for pagination toolbar
          },
          "& .MuiTablePagination-menuItem": {
            color: "#4CAF50", // Green color for menu items
          },
        }}
      />
    </Box>
  );
};

export default TopSellingProductTable;
