import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  IconButton,
  Grid,
  Paper,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  CloudUpload as UploadIcon,
  Delete as TrashIcon,
  CheckBox,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import {
  addProductImage,
  areAllProduct,
  areProductImagesInserted,
  setAllProduct,
  setProductImages,
  setThumbnailImages,
} from "../../../ReduxToolkit/Slices/imageSlice";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import ImageLoader from "./image/ImageLoader";
import { deleteFile, uploadFiles } from "../actions/api";
import api from "../../../utils/axios";
import { apeitnowBaseURL } from "../../../utils/url";

const ProductFileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes,
  preSelectedFiles = [],
  resetCb,
  multiple = false,
  accept,
  tab,
  openedWith,
  handleClose,
}) => {
  const fileInputField = useRef(null);
  const dispatch = useDispatch();
  const [files, setFiles] = useState(preSelectedFiles);
  const [status, setStatus] = useState("");
  const [dragging, setDragging] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const ProductImagesInserted = useSelector(areProductImagesInserted);
  const ProductCheck = useSelector(areAllProduct);
  const ALLProduct = useSelector((state) => state.Images.all);
  console.log("all product", ALLProduct);
  const allProdect = async () => {
    // dispatch(showLoader());
    try {
      const res = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/site-uploads/get/uploads?purpose=product`,
      );
      dispatch(setAllProduct(res?.data?.media || []));
      // if (res?.data.status) {
      //   dispatch(hideLoader());
      // }
    } catch (error) {
      console.log("error", error);
      dispatch(hideLoader());
    }
  };
  const handleInsert = () => {
    if (ProductImagesInserted) {
      dispatch(
        showSnackbar({
          message: `Products inserted successfully !`,
          severity: "success",
        }),
      );

      handleClose();
    }
  };
  useEffect(() => {
    if (!ProductCheck) {
      allProdect();
    }
    if (resetCb === "reset") {
      setFiles([]);
    }
  }, [resetCb]);
  const handleUploadBtnClick = () => {
    fileInputField.current?.click();
  };

  const handleNewFileUpload = async (e) => {
    const newFiles = e.target.files;
    if (newFiles && newFiles.length > 0) {
      const validFiles = [];
      const fileData = new FormData();
      for (let i = 0; i < newFiles.length; i = 1 + 1) {
        if (newFiles[i].size <= maxFileSizeInBytes) {
          fileData.append(`media`, newFiles[i]);
          fileData.append(`purpose`, "product");
          const uploadedFile = {
            name: newFiles[i].name,
            url: URL.createObjectURL(newFiles[i]),
            public_id: "",
          };
          validFiles.push(uploadedFile);
        } else {
          alert(`File size of ${newFiles[i].name} is too large (Max 2MB)`);
        }
      }

      if (validFiles.length > 0) {
        if (!multiple) {
          const uploadResponse = await uploadFiles(fileData);
          console.log("uploadResponse", uploadResponse);
          dispatch(addProductImage(uploadResponse?.uploads));
          // setFiles([uploadResponse?.uploadedFiles[0]]);
          // allProdect();
          // updateFilesCb([uploadResponse?.uploadedFiles[0]]);
        } else {
          const uploadResponse = await uploadFiles(fileData);
          console.log("uploadResponse", uploadResponse);
          dispatch(addProductImage(uploadResponse?.uploads));

          // allProdect();
          // setFiles((prevFiles) => {
          //   const updatedFiles = [
          //     ...prevFiles,
          //     ...uploadResponse?.uploadedFiles,
          //   ];
          //   // updateFilesCb(updatedFiles);
          //   return updatedFiles;
          // });
        }
      }
    }
  };
  const handleSelectImage = (file, index) => {
    // Check if the image is already selected by its index
    const isSelected = selectedImages.some((image) => image.index === index);

    if (isSelected) {
      // If the image is selected, remove it from the array (deselect)
      setSelectedImages(
        selectedImages.filter((image) => image.index !== index),
      );
    } else {
      // If the image is not selected, add it to the array (select)
      setSelectedImages([...selectedImages, { file, index }]);
    }
  };
  useEffect(() => {
    console.log("opened with", openedWith);
    if (openedWith === "Product") {
      dispatch(setProductImages(selectedImages));
    }
    if (openedWith === "Banner") {
      dispatch(setThumbnailImages(selectedImages));
    }
  }, [selectedImages]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          padding: 2,
          borderRadius: 1,
          cursor: "pointer",
          textAlign: "center",
        }}
        // onDragOver={handleDragOver}
        // onDragEnter={handleDragEnter}
        // onDragLeave={handleDragLeave}
        // onDrop={handleDrop}
      >
        {/* <Typography variant="h6">{label}</Typography>
            <Typography sx={{ margin: 1, marginBottom: 0 }}>
              Drag and drop your files anywhere or
            </Typography> */}
        {/* <Typography variant="h6">{tab}</Typography> */}
        <Button
          variant="outlined"
          color="primary"
          onClick={handleUploadBtnClick}
          startIcon={<UploadIcon />}
          sx={{
            margin: 1,
            "&:hover": {
              backgroundColor: "rgba(82, 118, 10, 1)",
              boxShadow: "none",
              color: "white",
            },
          }}
        >
          Add new
        </Button>
        <input
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          multiple={multiple}
          accept={accept}
          style={{ display: "none" }}
        />
      </Box>
      {status && (
        <Typography sx={{ color: "error.main", marginTop: 2 }}>
          {status}
        </Typography>
      )}
      <Box sx={{ mt: 0.5, width: "100%" }}>
        {/* <Typography>To Upload</Typography> */}
        <Grid container spacing={2}>
          {ALLProduct.length === 0
            ? "No Images Found"
            : ALLProduct?.map((file, index) => (
                <Grid item key={file.media.name + index}>
                  <Paper
                    sx={{
                      padding: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 1,
                      boxShadow: 1,
                      position: "relative",
                    }}
                  >
                    <ImageLoader
                      src={file.media.url}
                      alt={file.media.name}
                      width={100}
                      height={100}
                    />
                    <Box sx={{ position: "absolute", top: 0, right: -20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // Checkbox is checked if the image's index is in selectedImages array
                            checked={selectedImages.some(
                              (image) => image.index === index,
                            )}
                            onChange={() => handleSelectImage(file, index)}
                            color="primary"
                          />
                        }
                        label=""
                      />
                    </Box>
                  </Paper>
                </Grid>
              ))}
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              bgcolor: "#CFEDCC",
              color: "#61B15A",
              "&:hover": {
                backgroundColor: "rgba(82, 118, 10, 1)",
                boxShadow: "none",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleInsert}
            sx={{
              bgcolor: "#CFEDCC",
              color: "#61B15A",
              ml: 2,
              "&:hover": {
                backgroundColor: "rgba(82, 118, 10, 1)",
                boxShadow: "none",
                color: "white",
              },
            }}
          >
            Insert
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ProductFileUpload;
