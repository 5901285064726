import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Icon,
  Paper,
} from "@mui/material";
import Iconify from "../../components/iconify"; // Assuming Iconify is your icon component

const orderData = [
  {
    id: 1,
    orderNumber: "ORD123456",
    productName: "AirSpin AS-ER-101 Scarlet Gold",
    productImage:
      "https://apeitnow.com/_next/image?url=http%3A%2F%2Fres.cloudinary.com%2Fdfhmzpkxb%2Fimage%2Fupload%2Fv1727269633%2Fapeitnow%2Fmerchandise%2Fajpprea6txyfwtdn189g.jpg&w=256&q=75", // Path to the image
    productSku: "SKU67890",
    customerName: "John Doe",
    customerEmail: "johndoe@example.com",
    dateTime: "2024-11-20 14:30",
    total: "$1500",
    courierPartner: "FedEx",
    trackingNumber: "TRACK123456",
    status: "Shipped",
  },
  {
    id: 2,
    orderNumber: "ORD123457",
    productName: "AirSpin AS-ER-101 Iced Purple",
    productImage:
      "https://apeitnow.com/_next/image?url=http%3A%2F%2Fres.cloudinary.com%2Fdfhmzpkxb%2Fimage%2Fupload%2Fv1727270661%2Fapeitnow%2Fmerchandise%2Fl4a70ludamnb8zjeopar.png&w=256&q=75", // Path to the image
    productSku: "SKU12345",
    customerName: "Jane Smith",
    customerEmail: "janesmith@example.com",
    dateTime: "2024-11-19 10:15",
    total: "$800",
    courierPartner: "DHL",
    trackingNumber: "TRACK123457",
    status: "Delivered",
  },
  {
    id: 3,
    orderNumber: "ORD123458",
    productName: "AirSpin AS-ER-101 Moon Silver",
    productImage:
      "https://apeitnow.com/_next/image?url=http%3A%2F%2Fres.cloudinary.com%2Fdfhmzpkxb%2Fimage%2Fupload%2Fv1727269164%2Fapeitnow%2Fmerchandise%2Fylliys6l3f69nenjyr4w.jpg&w=256&q=75", // Path to the image
    productSku: "SKU11223",
    customerName: "Alice Brown",
    customerEmail: "alicebrown@example.com",
    dateTime: "2024-11-18 18:45",
    total: "$250",
    courierPartner: "UPS",
    trackingNumber: "TRACK123458",
    status: "Pending",
  },
];

const RecentOrdersTable = ({ data }) => {
  console.log("recent orders data table==>", data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState({ customerName: "", orderStatus: "" });

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleApplyFilter = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data?.filter(
    (order) =>
      (filter.customerName
        ? order.customerName
            .toLowerCase()
            .includes(filter.customerName.toLowerCase())
        : true) &&
      (filter.orderStatus
        ? order.status.toLowerCase().includes(filter.orderStatus.toLowerCase())
        : true),
  );

  // console.log("")

  return (
    <Box
      sx={{
        padding: 2,
        overflow: "auto",
        height: "100%",
        "::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: "2px",
          borderRadius: "10px",
          height: "2px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#61B15A",
          border: "none",
          borderRadius: "10px",
        },
      }}
      component={Paper}
    >
      {/* Heading and Filter Button in Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Recent Orders</Typography>

        {/* Filter Button */}
        {/* <Button
          variant="contained"
          color="inherit"
          onClick={handleFilterClick}
          startIcon={<Iconify icon="bx:slider" />}
        >
          Filter Options
        </Button> */}
      </Box>

      {/* Filter Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
        MenuListProps={{
          "aria-labelledby": "filter-button",
        }}
      >
        <MenuItem>
          <TextField
            label="Customer Name"
            variant="outlined"
            fullWidth
            value={filter.customerName}
            onChange={(e) =>
              setFilter({ ...filter, customerName: e.target.value })
            }
            sx={{ marginBottom: 2 }}
          />
        </MenuItem>
        <MenuItem>
          <TextField
            label="Order Status"
            variant="outlined"
            fullWidth
            value={filter?.orderStatus}
            onChange={(e) =>
              setFilter({ ...filter, orderStatus: e.target.value })
            }
            sx={{ marginBottom: 2 }}
          />
        </MenuItem>
        <MenuItem>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilter}
          >
            Apply Filter
          </Button>
        </MenuItem>
      </Menu>

      {/* Table Container */}
      <TableContainer
        sx={{
          maxHeight: 400,
          overflow: "auto",
          "::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: "2px",
            borderRadius: "10px",
            height: "2px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "#61B15A",
            border: "none",
            borderRadius: "10px",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead sx={{ backgroundColor: "#E0E2E7" }}>
            <TableRow>
              <TableCell>
                <strong>Order Number</strong>
              </TableCell>
              <TableCell>
                <strong>Product</strong>
              </TableCell>
              <TableCell>
                <strong>Date & Time</strong>
              </TableCell>
              <TableCell>
                <strong>Customer</strong>
              </TableCell>
              <TableCell>
                <strong>Total</strong>
              </TableCell>
              <TableCell>
                <strong>Courier Partner</strong>
              </TableCell>
              <TableCell>
                <strong>Tracking Number</strong>
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
              {/* <TableCell>
                <strong>Action</strong>
              </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {data
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((order) => (
                <TableRow key={order.orderId}>
                  {/* Order Number */}
                  <TableCell>{order.orderId}</TableCell>

                  {/* Product Column */}
                  <TableCell>
                    <Box
                      width={"100%"}
                      flex
                      flexDirection={"column"}
                      display={"flex"}
                      maxHeight={"100px"}
                      minWidth={"200px"}
                      overflow={"auto"}
                      // bgcolor={"red"}
                      // flexWrap={"wrap"}
                      sx={{
                        "::-webkit-scrollbar": {
                          backgroundColor: "transparent",
                          width: "2px",
                          borderRadius: "10px",
                          height: "2px",
                        },
                        "::-webkit-scrollbar-thumb": {
                          backgroundColor: "#4771AB",
                          border: "none",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {order?.products?.map((pr, _index) => (
                        <Box
                          key={_index}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={pr?.image.url}
                            alt={pr?.productName}
                            style={{
                              width: "25px",
                              height: "25px",
                              marginRight: 10,
                              borderRadius: "25%",
                            }}
                          />
                          <Box>
                            <Typography variant="body2">
                              {pr?.productName}
                            </Typography>
                            {/* <Typography variant="body2" color="textSecondary">
                            {pr?.productSku}
                          </Typography> */}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </TableCell>

                  {/* Date & Time */}
                  <TableCell>
                    {new Date(order.orderDate).toLocaleString("en-Us")}
                  </TableCell>

                  {/* Customer Column */}
                  <TableCell>
                    <Typography variant="body1">
                      {order?.customer?.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {order?.customer?.email}
                    </Typography>
                  </TableCell>

                  {/* Total */}
                  <TableCell>{order?.total}</TableCell>

                  {/* Courier Partner */}
                  <TableCell>
                    {order?.courierPartner ? order?.courierPartner : "N/A"}
                  </TableCell>

                  {/* Tracking Number */}
                  <TableCell>
                    {order?.trackingNumber ? order?.trackingNumber : "N/A"}
                  </TableCell>

                  {/* Status */}
                  <TableCell>{order.status}</TableCell>

                  {/* Action Column */}
                  {/* <TableCell>
                    <IconButton>
                      <Iconify icon="bx:bx-show" width={20} height={20} />
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page"
        sx={{
          "& .MuiTablePagination-select": {
            color: "#4CAF50", // Green color for rows per page
          },
          "& .MuiTablePagination-toolbar": {
            color: "#4CAF50", // Green color for pagination toolbar
          },
          "& .MuiTablePagination-menuItem": {
            color: "#4CAF50", // Green color for menu items
          },
        }}
      /> */}
    </Box>
  );
};

export default RecentOrdersTable;
