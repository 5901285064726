import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Stack, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { hideLoader, showLoader } from "../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../ReduxToolkit/Slices/snackbarSlice";

import ModalComponent from "../../reusablecomponents/ModalComponent/ModalComponent";
import Loader from "../../reusablecomponents/isLoading/Loader";
import DeleteModal from "../../reusablecomponents/DeleteModal/DeleteModal";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";

const SingleReview = ({ preview, handleClose, getAllReviews }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader.open);
  const [review, setReview] = useState({});
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  const getReview = async () => {
    if (preview.id) {
      dispatch(showLoader());
      try {
        const res = await api.get(
          `${apeitnowBaseURL}/api/v1/apeitnow/review-and-rating/get/sinlge/reviews/${preview.id}`,
        );
        setReview(res?.data || {});

        dispatch(hideLoader());
      } catch (error) {
        // dispatch(
        //   showSnackbar({
        //     message: `${error?.response.data.message} !`,
        //     severity: "error",
        //   }),
        // );
        dispatch(hideLoader());
      }
    }
  };
  useEffect(() => {
    getReview();
  }, [preview.id]);
  const formatDate = (isoDateString) => {
    return dayjs(isoDateString).format("MMMM D, YYYY");
  };
  const handleCloseDeleteModal = () => {
    setDialog({
      open: false,
      title: "",
      buttonText: "",
      data: {
        id: null,
      },
    });
  };
  const handleDelete = async () => {
    try {
      const res =
        dialog.buttonText === "Delete"
          ? await api.delete(
              `${apeitnowBaseURL}/api/v1/apeitnow/review-and-rating/delete/sinlge/reviews/${preview.id}`,
            )
          : await api.put(
              `${apeitnowBaseURL}/api/v1/apeitnow/review-and-rating/sanitize/product-reviews`,
              {
                reviews_and_Rating_Id: preview.id,
                sanitized: true,
              },
            );
      handleClose();
      getAllReviews();
      handleCloseDeleteModal();
    } catch (error) {
      console.log("error", error);
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
    }
  };

  // const handlesenitized = async () => {
  //   try {
  //     const res = await axios.post(
  //       `${apeitnowBaseURL}/api/v1/apeitnow/review-and-rating/sanitize/product-reviews`,
  //       {
  //         reviews_and_Rating_Id: preview.id,
  //         sanitized: true,
  //       },
  //     );
  //     dispatch(
  //       showSnackbar({
  //         message: `Review has sentized successfully!`,
  //         severity: "success",
  //       }),
  //     );
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  return (
    <ModalComponent open={preview.open}>
      {isLoading ? (
        <Loader />
      ) : (
        <Stack
          spacing={1}
          sx={{
            width: {
              md: 600,
              sm: 400,
              xs: 310,
            },
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ fontSize: "16px" }} />
            <Typography sx={{ alignSelf: "center", ml: 0.5 }}>
              Cancel
            </Typography>
          </Box>
          <Typography sx={{ fontWeight: 600, fontSize: "19px" }}>
            {review?.data?.customer
              ? review?.data?.customer?.account.name
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : "N/A"}
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            {review?.data?.comment ? review?.data?.comment : "N/A"}
          </Typography>

          <Rating
            name="read-only"
            value={review?.data?.rating ? review?.data?.rating : 0}
            readOnly
          />
          <Typography>
            {review?.data?.createdAt
              ? `Posted on ${formatDate(review?.data?.createdAt)}`
              : ""}
          </Typography>
          {review?.data?.uploads && (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {review?.data?.uploads?.length > 0
                ? review?.data?.uploads.map((image, index) => (
                    <img
                      src={image.url}
                      alt={image.name}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "10px",
                        margin: "4px",
                      }}
                    />
                  ))
                : ""}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 2,
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                bgcolor: "green",
              }}
              onClick={() =>
                setDialog({
                  open: true,
                  title: "Are you sure you want to delete this review ?",
                  buttonText: "Delete",
                  data: {
                    id: null,
                  },
                })
              }
            >
              Delete Review
            </Button>
            {review?.data?.sanitized ? (
              ""
            ) : (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  // bgcolor: "green",
                  ml: 1,
                }}
                onClick={() =>
                  setDialog({
                    open: true,
                    title: "Are you sure you want to sanitize this review ?",
                    buttonText: "Sanitize",
                    data: {
                      id: null,
                    },
                  })
                }
              >
                Sanitize
              </Button>
            )}
          </Box>
        </Stack>
      )}
      {dialog.open && (
        <DeleteModal
          open={dialog.open}
          dialog={dialog}
          handleClose={handleCloseDeleteModal}
          deleteFunc={handleDelete}
        />
      )}
    </ModalComponent>
  );
};

export default SingleReview;
