import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography, Paper, Stack } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hideLoader, showLoader } from "../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../ReduxToolkit/Slices/snackbarSlice";
// @mui

import AppStats from "../sections/@dashboard/app/AppStats";
// components
import Iconify from "../components/iconify";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from "../sections/@dashboard/app";
import MonthlyFeedbacks from "./MonthlyFeedbacks";
import MonthlyBuzzCount from "./MonthlyBuzzCount";
import UserManagement from "../components/adminSection/UserManagement/UserManagement";
import NewUsers from "./NewUsers";
import ProtectedRoute from "./ProtectedRoute";
import { apebuzzBaseURL } from "../utils/url";
import api from "../utils/axios";

export default function DashboardAppPage() {
  const theme = useTheme();
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [stats, setStats] = useState(null);

  const getUserStats = async () => {
    dispatch(showLoader());
    try {
      const res = await api.get(
        `${apebuzzBaseURL}/api/auth/adminAuth/get/users/stats`,
      );
      setStats(res?.data);
      dispatch(hideLoader());
    } catch (error) {
      console.error(error);
      console.log("error", error);
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getUserStats();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        <Grid container spacing={3}>
          <AppStats />

          <Grid item xs={12} md={6} lg={4}>
            {stats !== null && (
              <AppCurrentVisits
                title="All Users"
                chartData={[
                  {
                    label: "Active User",
                    value:
                      stats.activeUsers > 1000
                        ? `${stats.activeUsers / 1000}k`
                        : stats.activeUsers,
                  },
                  {
                    label: "Deactivated User",
                    value:
                      stats.deActiveUsers > 1000
                        ? `${stats.deActiveUsers / 1000}k`
                        : stats.deActiveUsers,
                  },
                  {
                    label: "Deleted User",
                    value:
                      stats.deletedUsers > 1000
                        ? `${stats.deletedUsers / 1000}k`
                        : stats.deletedUsers,
                  },
                ]}
                chartColors={[
                  // theme.palette.info.main,
                  "#5C59E8",
                  // "#008000",
                  theme.palette.warning.main,
                  // theme.palette.primary.main,
                  theme.palette.error.main,
                ]}
              />
            )}
          </Grid>
          <MonthlyFeedbacks />

          <Grid item xs={12}>
            <NewUsers />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
