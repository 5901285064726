import React, { useEffect, useState } from "react";
import { Container, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import CouponForm from "./CouponForm";
import CouponTable from "./CouponTable";
import api from "../../../utils/axios";
import { apebuzzBaseURL } from "../../../utils/url";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";

const CouponManagemet = () => {
  const dispatch = useDispatch();
  const [coupons, setCoupons] = useState([]);

  //   get all created coupons
  const handleGetCoupon = async () => {
    dispatch(showLoader());
    try {
      const response = await api.get(
        `${apebuzzBaseURL}/api/admin/coupons/coupon`,
      );
      setCoupons(response.data.data);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      console.log("error=>", error);
      dispatch(
        showSnackbar({
          message: "Something went wront",
          severity: "error",
        }),
      );
    }
  };

  //   create coupon
  const handleCouponCreate = async (newCoupon) => {
    dispatch(showLoader());
    try {
      console.log("coupond==>", newCoupon);
      const response = await api.post(
        `${apebuzzBaseURL}/api/admin/coupons/${
          newCoupon.type === "code" ? "codeCoupon" : "moneyCoupon"
        }`,
        newCoupon,
      );
      console.log("response==>", response.data.data);
      setCoupons((prev) => [response.data.data, ...prev]);
      dispatch(hideLoader());
    } catch (error) {
      console.log("error=>", error);
      dispatch(
        showSnackbar({
          message: "Something went wront",
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
    // setCoupons([...coupons, newCoupon]);
  };

  useEffect(() => {
    handleGetCoupon();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <Paper elevation={3} sx={{ padding: 2, marginTop: 3 }}>
        <CouponForm onCouponCreate={handleCouponCreate} />
      </Paper>

      <Paper elevation={3} sx={{ padding: 2, marginTop: 3 }}>
        <CouponTable coupons={coupons} />
      </Paper>
    </Container>
  );
};

export default CouponManagemet;
