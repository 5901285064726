import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { store } from "./ReduxToolkit/Store/store";
import Loader from "./reusablecomponents/isLoading/Loader";
import { closeSnackbar } from "./redux/action/defaultActions";
import Router from "./routes";
import ThemeProvider from "./theme";
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";

// ----------------------------------------------------------------------

// axios.defaults.withCredentials = true;
// Interceptor to set Authorization token globally
// axios.defaults.headers.common.Authorization = `Bearer ${store.getState().auth.token}`;
// Interceptor to set Authorization token globally

// console.log("store--->", store.getState().auth.token);

export default function App() {

  const admin = useSelector((state)=>state.auth)

  // useEffect(() => {
 
  // }, [])
  
  return (
    <>
      <Loader />
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}
