import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  TextField,
  IconButton,
  Container,
  Grid,
  Typography,
  Box,
  FormControl,
  Input,
  Stack,
  InputLabel,
  Paper,
} from "@mui/material";
import { PhotoCamera, Edit } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import DeleteModal from "../../../reusablecomponents/DeleteModal/DeleteModal";
import ChangleProfileModal from "./ChangleProfileModal";
import editIcon from "../../../Assets/editIcon.svg";
import ChangePassword from "./ChangePassword";
import { getErrorMessage } from "../../../reusablecomponents/CustomError/errorMessages";
import { apebuzzBaseURL, apecenterBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";
import { updateProfile } from "../../../ReduxToolkit/Slices/authSlice";

function Overview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  const [file, setFile] = useState(null);
  const [FileUrl, setFileUrl] = useState(null);
  const [isFormChanged, setIsFormChanged] = useState(true);
  const [focus, setFocus] = useState({ active: true, field: "" });
  const [focusMob, setFocusMob] = useState({ active: true, field: "" });
  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
  });
  const [values, setValues] = useState({
    name: "",
    phone: "",
  });
  const [open, setOpen] = useState(false);
  const adminProfile = async () => {
    dispatch(showLoader());
    try {
      // const res = await api.get(`${apebuzzBaseURL}/api/auth/adminAuth/get/profile/data`);
      const res = await api.get(
        `${apecenterBaseURL}/api/v1/ape-control-center/subadmin/get/profile`,
      );

      setValues(res?.data?.adminData);
      dispatch(updateProfile(res?.data?.adminData));
      setFileUrl(res?.data.adminData?.profilePicture?.url);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    adminProfile();
  }, []);
  // console.log("values", values)
  // console.log("FileUrl", FileUrl)

  const handleUpload = (e) => {
    const file = e.target.files[0];

    // Check if the file is an image
    if (file && file.type.startsWith("image/")) {
      setIsFormChanged(false);
      setFileUrl(URL.createObjectURL(file));
      setFile(file);
    } else {
      dispatch(
        showSnackbar({ message: `Only images are allow`, severity: "error" }),
      );
    }
    // setIsFormChanged(false);
    // setFileUrl(URL.createObjectURL(e.target.files[0]));
    // setFile(e.target.files[0]);
  };
  const handleChange = (prop) => (event) => {
    // setIsFormChanged(false);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSave = async () => {
    dispatch(showLoader());
    const formData = new FormData();
    if (values.name !== "") {
      formData.append("userName", values?.name);
    }

    if (values.phone !== "") {
      formData.append("phoneNo", values.phone);
    }

    if (file !== null) {
      formData.append("profile", file);
    }
    try {
      const res =
        file !== null
          ? await api.post(
              `${apecenterBaseURL}/api/v1/ape-control-center/subadmin/update-profile-picture`,
              formData,
            )
          : await api.post(
              `${apecenterBaseURL}/api/v1/ape-control-center/subadmin/update-detail`,
              {
                name: values.name,
                phone: values.phone,
              },
            );
      adminProfile();
      if (res?.data.success) {
        setFile(null);
        setFormData({
          name: "",
          phoneNo: "",
        });
        dispatch(hideLoader());
        setFocus({ active: true, field: "" });
        setIsFormChanged(true);
        dispatch(
          showSnackbar({
            message: `Account updated successfully !`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  const handleSaveMobile = async () => {
    dispatch(showLoader());
    const formData = new FormData();
    if (values.name !== "") {
      formData.append("userName", values?.name);
    }

    if (values.phone !== "") {
      formData.append("phoneNo", values.phone);
    }

    if (file !== null) {
      formData.append("file", file);
    }
    try {
      const res =
        file !== null
          ? await api.post(
              `${apebuzzBaseURL}/api/auth/adminAuth/update/profile/image`,
              formData,
            )
          : await api.put(
              `${apebuzzBaseURL}/api/auth/adminAuth/update/account`,
              {
                name: values.name,
                phone: values.phone,
              },
            );
      adminProfile();
      if (res?.data.success) {
        setFile(null);
        setFormData({
          name: "",
          phoneNo: "",
        });
        dispatch(hideLoader());
        setFocusMob({ active: true, field: "" });
        setIsFormChanged(true);
        dispatch(
          showSnackbar({
            message: `Account updated successfully !`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const removeDp = () => {
    setFileUrl(null);
  };
  const handleClose = () => {
    // setFileUrl(null);
    setDialog({
      open: false,
      title: "",
      message: "",
      buttonText: "",
      data: {
        postId: null,
      },
    });
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Typography>My Profile</Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent={"start"} pl={5}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"start"}
          width={{ xs: "100%", sm: "80%", md: "55%" }}
          // bgcolor={"red"}
        >
          <Grid
            container
            sx={{ borderRadius: "10px" }}
            spacing={2}
            mt={0.5}
            px={2}
            py={2}
            component={Paper}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {FileUrl ? (
                <>
                  <Stack position={"relative"}>
                    <Box
                      sx={{
                        width: "110px",
                        height: "110px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "rgb(123,128,154,0.3)",
                        // p:2
                      }}
                    >
                      <img
                        src={FileUrl}
                        alt="logo"
                        accept="image/*"
                        style={{
                          height: "90px",
                          width: "90px",
                          borderRadius: "50%",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          right: 26,
                          bottom: 6,
                          width: "26px",
                          height: "26px",
                          borderRadius: "50%",
                          background: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setDialog({
                            open: true,
                            title: `Are you sure to change profile picture?`,
                            message: "",
                            buttonText: "Change",
                            data: {
                              postId: "",
                            },
                          });
                        }}
                      >
                        {
                          <img
                            alt="edit"
                            style={{ height: "16px" }}
                            src={editIcon}
                          />
                          // <Edit
                          //   sx={{
                          //     color: "rgb(232,112,35)",
                          //   }}
                          // />
                        }
                        {/* <Box sx={{ width: 100, height: 100, borderRadius: "50%", color: "red",}}></Box> */}
                      </Box>
                    </Box>
                  </Stack>
                </>
              ) : (
                <Stack position={"relative"}>
                  <Box
                    sx={{
                      width: "125px",
                      height: "125px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "rgb(123,128,154,0.3)",
                      // p:2
                    }}
                  >
                    <Box
                      variant="h5"
                      gutterBottom
                      sx={{
                        width: "120px",
                        height: "120px",
                        borderRadius: "50%",
                        background: "transparent",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "white",
                      }}
                    >
                      <Avatar
                        sx={{
                          height: "90px",
                          width: "90px",
                          borderRadius: "50%",
                          bgcolor: "rgb(123,128,154)",
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      position: "absolute",
                      right: 26,
                      bottom: 6,
                      width: "26px",
                      height: "26px",
                      borderRadius: "50%",
                      background: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl>
                      <Input
                        type="file"
                        accept="image/*"
                        sx={{ display: "none" }}
                        id="fileInput"
                        onChange={handleUpload}
                      />
                    </FormControl>
                    <InputLabel htmlFor="fileInput">
                      <PhotoCameraIcon
                        sx={{
                          color: "rgb(232,112,35)",
                          width: "20px",
                          height: "20px",
                          mt: 0.2,
                        }}
                      />
                    </InputLabel>
                  </Box>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: "rgba(121, 116, 126, 1)" }}>
                Full Name
              </Typography>
              <Box display="flex" alignItems="center" position={"relative"}>
                <TextField
                  fullWidth
                  id="name"
                  name="userName"
                  variant="standard"
                  focus={focus.active}
                  value={values && values.name ? values.name : "N/A"}
                  // defaultValue={values?.name}
                  onChange={(e) => {
                    setValues({ ...values, name: e.target.value });
                    // setIsFormChanged(false);
                  }}
                  disabled={focus.active}
                />
                {focus.active && (
                  <Box
                    position={"absolute"}
                    sx={{
                      right: 0,
                      bottom: 10,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setFocus({ active: false, field: "fullName" })
                    }
                  >
                    <Typography sx={{ fontSize: "14px" }}>Edit</Typography>
                    <Typography>
                      <img
                        alt="edit"
                        style={{ marginLeft: "10px", height: "16px" }}
                        src={editIcon}
                      />
                    </Typography>
                  </Box>
                )}
                {!focus.active && focus.field === "fullName" && (
                  <Box
                    position={"absolute"}
                    sx={{
                      right: 0,
                      bottom: 10,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFocus({ active: true, field: "fullName" });
                      handleSave();
                    }}
                  >
                    <CheckIcon sx={{ color: "green" }} />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: "rgba(121, 116, 126, 1)" }}>
                Email
              </Typography>
              <Box display="flex" alignItems="center" position={"relative"}>
                <TextField
                  fullWidth
                  value={values.email}
                  onChange={handleChange("email")}
                  variant="standard"
                  disabled
                  id="email"
                  name="email"
                />
                {/* <Box position={'absolute'} sx={{ right: 0, bottom: 10, display: "flex", alignItems: "center", cursor: "pointer" }}>
                <Typography sx={{ fontSize: "16px" }}>Edit</Typography>
                <Edit sx={{ ml: 1 }} />
              </Box> */}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: "rgba(121, 116, 126, 1)" }}>
                Mobile Number
              </Typography>
              <Box display="flex" alignItems="center" position={"relative"}>
                <TextField
                  fullWidth
                  value={values && values.phone ? values.phone : ""}
                  focus={focusMob.active}
                  id="phoneNo"
                  name="phoneNo"
                  type="tel"
                  inputProps={{
                    maxLength: 10, // This will work with "tel", but not with "number"
                    inputMode: "numeric", // Ensures only numeric input is shown on mobile devices
                    pattern: "[0-9]*", // Allows only numbers
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, "");
                    setValues({ ...values, phone: newValue });
                  }}
                  variant="standard"
                  disabled={focusMob.active}
                  required
                />
                {focusMob.active && (
                  <Box
                    position={"absolute"}
                    sx={{
                      right: 0,
                      bottom: 10,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setFocusMob({ active: false, field: "mobileNumber" })
                    }
                  >
                    <Typography sx={{ fontSize: "14px" }}>Edit</Typography>
                    <Typography>
                      <img
                        alt="edit"
                        style={{ marginLeft: "10px", height: "16px" }}
                        src={editIcon}
                      />
                    </Typography>
                  </Box>
                )}
                {!focusMob.active && focusMob.field === "mobileNumber" && (
                  <Box
                    position={"absolute"}
                    sx={{
                      right: 0,
                      bottom: 10,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFocusMob({ active: true, field: "mobileNumber" });
                      handleSaveMobile();
                    }}
                  >
                    <CheckIcon sx={{ color: "green" }} />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: "rgba(121, 116, 126, 1)" }}>
                Password
              </Typography>
              <Box display="flex" alignItems="center" position={"relative"}>
                <TextField
                  fullWidth
                  value="*****************"
                  // onChange={handleChange('password')}
                  variant="standard"
                  id="password"
                  type="password"
                  disabled
                />
                <Box
                  position={"absolute"}
                  sx={{
                    right: 0,
                    bottom: 10,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(true)}
                >
                  <Typography sx={{ fontSize: "14px" }}>
                    Change Password
                  </Typography>
                  <Typography>
                    <img
                      alt="edit"
                      style={{ marginLeft: "10px", height: "16px" }}
                      src={editIcon}
                    />
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleSave}
                  disabled={isFormChanged}
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 1,
                    width: "150px",
                    "&:hover": {
                      backgroundColor: "rgba(82, 118, 10, 1)",
                      boxShadow: "none",
                      color: "white",
                    },
                  }}
                >
                  Update Profile
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {dialog.open && (
        <ChangleProfileModal
          open={dialog.open}
          handleClose={handleClose}
          dialog={dialog}
          deleteFunc={() => removeDp()}
        />
      )}
      {open && (
        <ChangePassword open={open} handleCloseModal={handleCloseModal} />
      )}
    </>
  );
}

export default Overview;
