import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Grid,
  Typography,
  FormHelperText,
  Box,
} from "@mui/material";
import TextEditor from "../../../layouts/apeitnowdashboard/components/TextEditor";

const CouponForm = ({ onCouponCreate }) => {
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [formData, setFormData] = useState({
    category: "",
    type: "",
    couponDescription: "",
    expiresAt: "",
    amount: "",
    pointsPrice: "",
    code: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (
      !formData.category ||
      !formData.type ||
      !formData.expiresAt ||
      !termsAndConditions
    ) {
      setError("All fields are required!");
      return;
    }

    setError("");
    onCouponCreate({ ...formData, termsAndConditions });
    setFormData({
      category: "",
      type: "",
      couponDescription: "",
      expiresAt: "",
      amount: "",
      pointsPrice: "",
      code: "",
    });
    setTermsAndConditions("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Create Coupon</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
            >
              <MenuItem value="Silver">Silver</MenuItem>
              <MenuItem value="Bronze">Bronze</MenuItem>
              <MenuItem value="Gold">Gold</MenuItem>
            </Select>
            <FormHelperText>{error}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
            >
              <MenuItem value="money">Money</MenuItem>
              <MenuItem value="code">Code</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Coupon Description"
            name="couponDescription"
            fullWidth
            value={formData.couponDescription}
            onChange={handleChange}
            required
            multiline
            rows={3}
          />
        </Grid>

        <Grid item xs={12}>
          {/* <TextField
            label="Terms and Conditions"
            name="terms"
            fullWidth
            value={formData.terms}
            onChange={handleChange}
            multiline
            rows={3}
          /> */}
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              Terms and Conditions
            </Typography>
            <TextEditor
              previousValue={termsAndConditions}
              updatedValue={setTermsAndConditions}
              height={200}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Expires At"
            name="expiresAt"
            type="date"
            fullWidth
            value={formData.expiresAt}
            onChange={handleChange}
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {formData.type === "money" && (
          <Grid item xs={6}>
            <TextField
              label="Amount"
              name="amount"
              type="number"
              fullWidth
              value={formData.amount}
              onChange={handleChange}
            />
          </Grid>
        )}

        {formData.type === "code" && (
          <Grid item xs={6}>
            <TextField
              label="Coupon Code"
              name="code"
              fullWidth
              value={formData.code}
              onChange={handleChange}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <TextField
            label="Point Price"
            name="pointsPrice"
            type="number"
            fullWidth
            value={formData.pointsPrice}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Create Coupon
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CouponForm;
