import { Box, Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isVideoInserted, } from "../../../ReduxToolkit/Slices/imageSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import ProductVideoContainer from "../../../layouts/apeitnowdashboard/components/ProductVideoContainer";
import ProductVideoUpload from "../../../layouts/apeitnowdashboard/components/ProductVideoUpload";

const ProductVideos = ({ updateProductVideo, resetCb, tab, handleClose }) => {
  const VideoInserted = useSelector(isVideoInserted);
  const dispatch = useDispatch();
  const handleInsert = () => {
    if (VideoInserted) {
      dispatch(
        showSnackbar({
          message: `Product video has inserted successfully !`,
          severity: "success",
        }),
      );

      handleClose();
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <ProductVideoUpload
        accept="video/*"
        label={"Products video"}
        maxFileSizeInBytes={26214400}
        updateFilesCb={updateProductVideo}
        resetCb={resetCb}
        tab={tab}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default ProductVideos;
