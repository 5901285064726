import React from "react";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isThumbnailInserted } from "../../../ReduxToolkit/Slices/imageSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import FileUpload from "../../../layouts/apeitnowdashboard/components/FileUpload";

const Thumbnail = ({
  resetCb,
  updateFilesCb,
  tab,
  openedWith,
  handleClose,
}) => {
  const thumbnailInserted = useSelector(isThumbnailInserted);
  const dispatch = useDispatch();
  const handleInsert = () => {
    if (thumbnailInserted) {
      dispatch(
        showSnackbar({
          message: `Product banner inserted successfully !`,
          severity: "success",
        }),
      );

      handleClose();
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <FileUpload
        accept=".jpg,.png,.jpeg"
        label={"Thumbnail Image (300px x 300px)"}
        maxFileSizeInBytes={20971520}
        updateFilesCb={updateFilesCb}
        resetCb={resetCb}
        tab={tab}
        openedWith={openedWith}
        handleClose={handleClose}
      />
      {/* <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              bgcolor: "#CFEDCC",
              color: "#61B15A",
              "&:hover": {
                backgroundColor: "rgba(82, 118, 10, 1)",
                boxShadow: "none",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleInsert}
            sx={{
              bgcolor: "#CFEDCC",
              color: "#61B15A",
              ml: 2,
              "&:hover": {
                backgroundColor: "rgba(82, 118, 10, 1)",
                boxShadow: "none",
                color: "white",
              },
            }}
          >
            Insert
          </Button>
        </Box>
      </Box> */}
    </Box>
  );
};

export default Thumbnail;
