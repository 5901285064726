import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import WarehouseForm from "./AddWarehouseForm";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";
import WarehouseDetailCard from "./WarehouseDetailCard";

const Warehouse = () => {
  const [allCreatedWarehouseData, setAllCreatedWarehouseData] = useState(null);

  // handle get all ware house
  const getAllCreatedWarehosue = async () => {
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/delhivery/warehouse`,
      );
      console.log("respoonse ===>", response?.data?.data);
      setAllCreatedWarehouseData(response?.data);
    } catch (error) {
      console.log("error=>", error);
    }
  };

  console.log("allCreatedWarehouseData====>", allCreatedWarehouseData);

  useEffect(() => {
    getAllCreatedWarehosue();
  }, []);

  return (
    <>
      <Box>
        <WarehouseForm
          setAllCreatedWarehouseData={setAllCreatedWarehouseData}
        />
        <Stack mt={2} component={Paper} p={2}>
          <Typography variant="h6">All created warehouse</Typography>
          
          <Grid container>
            {allCreatedWarehouseData && allCreatedWarehouseData?.data.length > 0
              ? allCreatedWarehouseData?.data?.map((_wData, _wIndex) => {
                  return (
                    <Grid key={_wIndex} item xs={12}>
                      <WarehouseDetailCard warehouseData={_wData} />
                    </Grid>
                  );
                })
              : "Data not available"}
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default Warehouse;
