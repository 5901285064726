import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ContainedButton, fontColor } from "../GlobelStyles/commonStyle";
import Iconify from "../components/iconify";
import { login } from "../ReduxToolkit/Slices/authSlice";
import { hideLoader, showLoader } from "../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../ReduxToolkit/Slices/snackbarSlice";
import { getErrorMessage } from "../reusablecomponents/CustomError/errorMessages";
import { apebuzzBaseURL, apecenterBaseURL } from "../utils/url";
import api from "../utils/axios";
import { selectPanel } from "../ReduxToolkit/Slices/adminPanelSlice";

export default function LoginPage() {
  const navigate = useNavigate();
  // to dispatch the login data
  const auth = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  // to hide and show the password
  const [showPassword, setShowPassword] = useState(false);
  const label = {
    inputProps: { label: "Remember Me", "aria-label": "Checkbox demo" },
  };

  const handleSubmit = async (event) => {
    dispatch(showLoader());
    event.preventDefault();
    const regex = /^[(]?[0-9]{3}[)]?[+-]?[0-9]{3}[+-]?[0-9]{4,6}$/;
    const formData = new FormData(event.target);
    const useremail = formData.get("email");
    const userpassword = formData.get("password");
    const userData = { useremail, userpassword };
    // console.log("test", regex.test(useremail))
    try {
      const res = regex.test(useremail)
        ? await api.post(
            `${apecenterBaseURL}/api/v1/ape-control-center/subadmin/login`,
            {
              phone: Number(useremail) * 1,
              password: userpassword,
            },
          )
        : await api.post(
            `${apecenterBaseURL}/api/v1/ape-control-center/subadmin/login`,
            {
              email: useremail,
              password: userpassword,
            },
          );
      if (res?.data.success) {
        dispatch(hideLoader());
        dispatch(
          selectPanel(
            res?.data?.admin?.superAdmin
              ? "apebuzz" // For superadmin, select "apebuzz"
              : res?.data?.admin?.apps?.[0]?.name || "anonymous", // For non-superadmin, select the name from the first app, or default to "defaultPanel" if not available
          ),
        );
      
        dispatch(login({ user: res?.data.admin, token: res?.data?.token }));
        dispatch(
          showSnackbar({ message: "Sign Successfully!", severity: "success" }),
        );
        navigate("/dashboard/profile");
        event.target.reset();
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (auth) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <Grid
        container
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url("/assets/Background.png")`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          component={"form"}
          onSubmit={handleSubmit}
          item
          xs={11}
          md={6}
          lg={3.5}
          sm={8.5}
          sx={{ bgcolor: "white", borderRadius: "8px" }}
        >
          <Stack
            spacing={1}
            sx={{
              px: 3,
              py: 6,
            }}
          >
            <Stack spacing={3}>
              <Box>
                <Typography
                  sx={{
                    color: "rgba(51, 51, 51, 1)",
                    fontSize: "25px",
                    fontWeight: 400,
                  }}
                >
                  Sign in
                </Typography>
              </Box>
              <Stack spacing={1}>
                <Typography sx={fontColor}>Email or phone number</Typography>
                <TextField
                  type="text"
                  name="email"
                  fullWidth
                  id="outlined-required"
                  variant="outlined"
                  required
                />
              </Stack>
              <Stack spacing={1}>
                <Typography sx={fontColor}>Password</Typography>
                <TextField
                  type={showPassword ? "text" : "password"}
                  // type="password"
                  name="password"
                  // placeholder=". . . . . . . . . . ."
                  fullWidth
                  required
                  // browser default hide unhide feature is working
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? (
                            <RemoveRedEyeRoundedIcon />
                          ) : (
                            <VisibilityOffRoundedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction={"column"} width={"100%"}>
              <Typography
                sx={{ ...fontColor, cursor: "pointer", textAlign: "end" }}
                onClick={() => navigate("/forget-password")}
              >
                Forgot Password
              </Typography>
            </Stack>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "rgba(82, 118, 10, 1)",
                  boxShadow: "none",
                  color: "white",
                },
              }}
            >
              Sign in
            </Button>
            {/* <Box sx={{ display: "flex", alignItems: "center", ml: -1 }}>
              <Checkbox
                {...label}
                sx={{
                  color: "rgba(51, 51, 51, 1)",
                  "& .MuiSvgIcon-root": { fontSize: 20 },
                }}
              />
              <Typography ml={-1}>Remember Me</Typography>
            </Box> */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
