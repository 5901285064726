import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { apeitnowBaseURL } from "../../utils/url";
import api from "../../utils/axios";

const CustomEmail = () => {
  // States for the form fields
  const [subject, setSubject] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [mailBody, setMailBody] = useState("");

  // State for storing sent email data
  const [sentEmails, setSentEmails] = useState([]);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the email data
    const emailData = {
      subject,
      mailBody,
      cc: cc.split(",").map((email) => email.trim()),
      bcc: bcc.split(",").map((email) => email.trim()),
    };

    try {
      // Make an API call to send the email (you would need to replace with your actual API endpoint)
      const response = await api.post(
        `${apeitnowBaseURL}/api/v1/apeitnow/admin/email/send/to-all/customers`,
        emailData,
      );

      // If the email is sent successfully, fetch the updated list of sent emails
      if (response.data.success) {
        fetchSentEmails();
        // Reset the form fields
        setSubject("");
        setCc("");
        setBcc("");
        setMailBody("");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  // Function to fetch previously sent emails
  const fetchSentEmails = async () => {
    try {
      // Make an API call to get sent email data (replace with your actual API endpoint)
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/admin/email/get/all/sent-mails`,
      );
      if (response.data.success) {
        setSentEmails(response.data.sentEmailsRecords);
      }
    } catch (error) {
      console.error("Error fetching sent emails:", error);
    }
  };

  // Fetch sent emails when the component mounts
  useEffect(() => {
    fetchSentEmails();
  }, []);

  return (
    <Stack rowGap={2.5}>
      {/* Email Form */}
      <Box component={Paper} p={2}>
        <Typography variant="h4" fontWeight={"light"}>
          Send Email
        </Typography>
        <Divider />
        <Stack mt={2} onSubmit={handleSubmit} rowGap={2} component={"form"}>
          <div>
            {/* <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject" // Add ID to link with the label
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          /> */}
            <TextField
              name="subject"
              fullWidth
              value={subject}
              label="Subject"
              placeholder="Write subject for email"
              onChange={(e) => setSubject(e.target.value)}
              required
              type="text"
              id="subject"
            />
          </div>
          <div>
            {/* <label htmlFor="cc">CC (comma separated)</label>
          <input
            type="text"
            id="cc" // Add ID to link with the label
            value={cc}
            onChange={(e) => setCc(e.target.value)}
          /> */}
            <TextField
              fullWidth
              type="text"
              label="CC (comma separated)"
              id="cc" // Add ID to link with the label
              placeholder="Optional"
              value={cc}
              onChange={(e) => setCc(e.target.value)}
            />
          </div>
          <div>
            {/* <label htmlFor="bcc">BCC (comma separated)</label>
          <input
            type="text"
            id="bcc" // Add ID to link with the label
            value={bcc}
            onChange={(e) => setBcc(e.target.value)}
          /> */}
            <TextField
              fullWidth
              type="text"
              placeholder="Optional"
              label="BCC (comma separated)"
              id="bcc" // Add ID to link with the label
              value={bcc}
              onChange={(e) => setBcc(e.target.value)}
            />
          </div>
          <div>
            {/* <label htmlFor="mailBody">Mail Body</label>
          <textarea
            id="mailBody" // Add ID to link with the label
            value={mailBody}
            onChange={(e) => setMailBody(e.target.value)}
            required
          /> */}
            <TextField
              id="mailBody" // Add ID to link with the label
              value={mailBody}
              fullWidth
              multiline
              maxRows={4}
              minRows={4}
              placeholder="Write mail"
              onChange={(e) => setMailBody(e.target.value)}
              required
            />
          </div>
          {/* <button type="submit">Send Email</button> */}
          <Button variant="contained" type="submit" color="warning">
            Send Email
          </Button>
        </Stack>
      </Box>

      {/* Table for displaying sent emails */}
      <Box component={Paper} p={2}>
        <Typography variant="h4" fontWeight={"light"}>
          Sent Emails
        </Typography>
        {sentEmails.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Subject</TableCell>
                  <TableCell>Mail Body</TableCell>
                  <TableCell>Date Sent</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sentEmails.map((email, index) => (
                  <TableRow key={index}>
                    <TableCell>{email.subject}</TableCell>
                    <TableCell>{email.mailBody}</TableCell>
                    <TableCell>
                      {new Date(email.createdAt).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <p>No emails have been sent yet.</p>
        )}
      </Box>
    </Stack>
  );
};

export default CustomEmail;
