import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import TransactionsTable from "./TransacionsTable";
import Iconify from "../../components/iconify";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";

// Dummy Payment Records Data
const paymentsData = [
  {
    paymentId: "pm_1234567890abcdef1",
    orderId: "34567890abcdef65656989", // Generate a new ObjectId for the order
    paymentDetail: {
      payment_id: "pm_1234567890abcdef1",
      order_id: "order_1234567890abcdef1",
      amount: 1000,
      currency: "INR",
      status: "Credit", // Payment successful
      payment_mode: "credit_card",
      buyer_name: "John Doe",
      email: "johndoe@example.com",
      phone: "+919876543210",
      timestamp: "2024-11-30T12:00:00Z",
      transaction_reference: "txn_abcdef1234561",
      payment_gateway: "Instamojo",
      mode: "online",
      redirect_url: "https://www.example.com/payment-success",
      discount: 100,
      tax: 50,
    },
    createdAt: "2024-11-30T14:00:00Z",
  },
  {
    paymentId: "pm_abcdef1234567890",
    orderId: "34567890abcdef65656989",
    paymentDetail: {
      payment_id: "pm_abcdef1234567890",
      order_id: "order_abcdef1234567890",
      amount: 2000,
      currency: "INR",
      status: "Failed", // Payment failed
      payment_mode: "debit_card",
      buyer_name: "Alice Smith",
      email: "alicesmith@example.com",
      phone: "+919876543211",
      timestamp: "2024-11-30T13:00:00Z",
      transaction_reference: "txn_abcdef1234562",
      payment_gateway: "Instamojo",
      mode: "online",
      redirect_url: "https://www.example.com/payment-failed",
      discount: 200,
      tax: 100,
    },
    createdAt: "2024-11-30T14:00:00Z",
  },
  {
    paymentId: "pm_abcdef9876543210",
    orderId: "34567890abcdef65656989",
    paymentDetail: {
      payment_id: "pm_abcdef9876543210",
      order_id: "order_abcdef9876543210",
      amount: 1500,
      currency: "INR",
      status: "Pending", // Payment is pending
      payment_mode: "net_banking",
      buyer_name: "Rajesh Kumar",
      email: "rajesh@example.com",
      phone: "+919876543212",
      timestamp: "2024-11-30T14:00:00Z",
      transaction_reference: "txn_abcdef1234563",
      payment_gateway: "Instamojo",
      mode: "online",
      redirect_url: "https://www.example.com/payment-pending",
      discount: 150,
      tax: 75,
    },
    createdAt: "2024-11-30T14:00:00Z",
  },
  {
    paymentId: "pm_9876543210abcdef",
    orderId: "34567890abcdef65656989",
    paymentDetail: {
      payment_id: "pm_9876543210abcdef",
      order_id: "order_9876543210abcdef",
      amount: 500,
      currency: "INR",
      status: "Refunded", // Payment refunded
      payment_mode: "upi",
      buyer_name: "Sita Rani",
      email: "sitarani@example.com",
      phone: "+919876543213",
      timestamp: "2024-11-30T15:00:00Z",
      transaction_reference: "txn_abcdef1234564",
      payment_gateway: "Instamojo",
      mode: "online",
      redirect_url: "https://www.example.com/payment-refunded",
      discount: 50,
      tax: 25,
    },
    createdAt: "2024-11-30T14:00:00Z",
  },
  {
    paymentId: "pm_1234abcd5678efgh",
    orderId: "34567890abcdef65656989",
    paymentDetail: {
      payment_id: "pm_1234abcd5678efgh",
      order_id: "order_1234abcd5678efgh",
      amount: 2500,
      currency: "INR",
      status: "Credit", // Payment successful
      payment_mode: "wallet",
      buyer_name: "Sunita Sharma",
      email: "sunitasharma@example.com",
      phone: "+919876543214",
      timestamp: "2024-11-30T16:00:00Z",
      transaction_reference: "txn_abcdef1234565",
      payment_gateway: "Instamojo",
      mode: "online",
      redirect_url: "https://www.example.com/payment-success",
      discount: 250,
      tax: 125,
    },
    createdAt: "2024-11-30T14:00:00Z",
  },  
];

const TransactionManagement = () => {
  const [allTransactions, setallTransactions] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
    totalRecords: 10,
  });
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Function to get all customers and update pagination data
  const getAllTransactions = useCallback(async () => {
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/admin/get/all/customers?limit=${paginationData.limit}&page=${paginationData.page}`,
      );
      console.log("response=>", response.data);
      setallTransactions(response.data.data);
      setPaginationData((prevData) => ({
        ...prevData,
        page: response.data.page || prevData.page,
        limit: response.data.limit || prevData.limit,
        totalPages: response.data.totalPages || prevData.totalPages,
        totalRecords: response.data.totalUsers || prevData.totalRecords,
      }));
    } catch (error) {
      console.log("error=>", error);
    }
  }, [paginationData.page, paginationData.limit]);

  useEffect(() => {
    getAllTransactions(); // Call API whenever page or limit changes
  }, [getAllTransactions]);

  console.log("records-->", allTransactions);

  // Transform the data for the table
  const rows =
    allTransactions && allTransactions.length > 0
      ? allTransactions.map((user) => ({
          account: user.account,
          email: user.email,
          totalOrders: user.orderCount,
          phone: user.phone,
          accountCreated: user.createdAt,
          _id: user._id,
        }))
      : [];

      // const filteredRows = useMemo(() => {
      //   return rows?.filter(
      //     (row) => {
      //       return (
      //         row.account.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      //         row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      //         row.phone.toLowerCase().includes(searchTerm.toLowerCase())
      //       );
      //     },
      //     [rows, searchTerm, loading],
      //   );
      // });
  return (
    <Box
      sx={{
        padding: 2,
        overflow: "auto",
        height: "100%",
        "::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: "2px",
          borderRadius: "10px",
          height: "2px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#61B15A",
          border: "none",
          borderRadius: "10px",
        },
      }}
    >
      {/* Heading and Filter Button in Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Transactions</Typography>
        <Button
          variant="contained"
          color="inherit"
          size="small"
          startIcon={<Iconify icon="lets-icons:export-light" />}
          sx={{ fontWeight: "light", bgcolor: "#CFEDCC", color: "#53760A" }}
        >
          Export
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <TextField
          name="search"
          placeholder="Search customer. . ."
          variant="outlined"
          sx={{
            outline: "none",
            "&:focus": {
              outline: "none",
              border: "none",
            },
          }}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          color="inherit"
          size="small"
          startIcon={<Iconify icon="bx:slider" />}
          sx={{ fontWeight: "light", bgcolor: "#CFEDCC", color: "#53760A" }}
        >
          Filter
        </Button>
      </Box>
      <TransactionsTable
        rows={paymentsData}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
      />
    </Box>
  );
};

export default TransactionManagement;
