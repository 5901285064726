import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  CircularProgress,
  Grid,
  Stack,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Slide,
  Avatar,
  Chip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import api from "../../../utils/axios";
import { apebuzzBaseURL } from "../../../utils/url";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";

// Transition for dialog
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SingleBusinessRequestDetail = () => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [rejectionReason, setRejectionReason] = useState("");
  const [requestData, setRequestData] = useState(null);
  const [error, setError] = useState(null);

  const [openApproveDialogue, setOpenApproveDialogue] = useState(false);
  const [openRejectDialogue, setOpenRejectDialogue] = useState(false);

  // Fetching business request data
  const fetchBusinessRequestData = async () => {
    try {
      const response = await api.get(
        `${apebuzzBaseURL}/api/admin/businessOnboard/business/${requestId}`,
      );
      setRequestData(response?.data?.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to load business request data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBusinessRequestData();
  }, [requestId]);

  // Approve request handler
  const handleApproveRequest = async () => {
    setLoading(true);
    try {
      await api.put(
        `${apebuzzBaseURL}/api/admin/businessOnboard/business/${requestId}`,
        {
          approvalStatus: "approved",
        },
      );
      setRequestData((prevData) => ({
        ...prevData,
        approvalStatus: "approved",
      }));
      setOpenApproveDialogue(false); // Close dialog after successful approval
    } catch (error) {
      setError("Failed to approve the request");
    } finally {
      setLoading(false);
    }
  };

  // Reject request handler
  const handleRejectRequest = async (e) => {
    e.preventDefault();
    if (!rejectionReason) {
      dispatch(
        showSnackbar({
          message: "Please provide a reason for rejection",
          severity: "error",
        }),
      );
      return;
    }
    setLoading(true);
    try {
      await api.put(
        `${apebuzzBaseURL}/api/admin/businessOnboard/business/${requestId}`,
        {
          approvalStatus: "rejected",
          reason: rejectionReason,
        },
      );
      setRequestData((prevData) => ({
        ...prevData,
        approvalStatus: "rejected",
        reason: rejectionReason,
      }));
      setOpenRejectDialogue(false); // Close dialog after successful rejection
    } catch (error) {
      setError("Failed to reject the request");
    } finally {
      setLoading(false);
    }
  };

  // Handle open/close of approval dialog
  const handleApproval = () => setOpenApproveDialogue(true);
  const handleReject = () => setOpenRejectDialogue(true);

  // Render loading or error states
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={50} color="primary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate(-1)}
          sx={{ mb: 2 }}
        >
          Back
        </Button>

        {/* Business Request Details */}
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Business Request Details
          </Typography>
          <Divider />
          <Grid container mt={1} spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Business Name:</strong>{" "}
                {requestData.businessName || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Document Number:</strong>{" "}
                {requestData.businessDocument?.documentNumber || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Document Type:</strong>{" "}
                {requestData.businessDocument?.documentType || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>PAN Number:</strong> {requestData.panNumber || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>GST Number:</strong> {requestData.gstNumber || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Request Date:</strong>{" "}
                {new Date(requestData.createdAt).toLocaleString("en-US", {
                  dateStyle: "medium",
                }) || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Business Email:</strong>{" "}
                {requestData.businessEmail || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" mb={1}>
                <strong>Uploaded Document:</strong>
              </Typography>
              {requestData.businessDocument?.document ? (
                <Box sx={{ maxWidth: "250px", maxHeight: "250px", mb: 2 }}>
                  <img
                    src={requestData.businessDocument.document?.docUrl}
                    alt="Uploaded Document"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              ) : (
                <Typography>No document uploaded</Typography>
              )}
            </Grid>
          </Grid>
        </Paper>

        {/* User Information */}
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            User Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>User Name:</strong> {requestData.user?.name || "N/A"}
              </Typography>
              <Typography variant="body1">
                <strong>Phone Number:</strong>{" "}
                {requestData.user?.phone || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Profile Picture:</strong>
              </Typography>
              <Avatar
                sx={{ width: 56, height: 56 }}
                alt={requestData.user?.name}
                src={requestData.user?.profilePicture?.imageUrl || ""}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Approval Status */}
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Approval Status
          </Typography>
          <Typography variant="body1" mb={2}>
            <strong>Status:</strong>{" "}
            <Chip
              label={requestData.approvalStatus || "Pending"}
              size="small"
              sx={{ textTransform: "capitalize" }}
              color={
                requestData.approvalStatus.toLowerCase() === "rejected"
                  ? "error"
                  : requestData.approvalStatus.toLowerCase() === "approved"
                  ? "success"
                  : "warning"
              }
            />
          </Typography>
          {requestData.approvalStatus.toLowerCase() === "pending" && (
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="success"
                onClick={handleApproval}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="primary" />
                ) : (
                  "Accept"
                )}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleReject}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="primary" />
                ) : (
                  "Reject"
                )}
              </Button>
            </Stack>
          )}
          {requestData.approvalStatus.toLowerCase() === "rejected" && (
            <Stack direction="row" spacing={2}>
              <Typography variant="body1">
                <strong>Rejection Reason:</strong> {requestData.reason || "N/A"}
              </Typography>
            </Stack>
          )}
        </Paper>
      </Box>

      {/* Approve Dialog */}
      <Dialog
        open={openApproveDialogue}
        onClose={() => setOpenApproveDialogue(false)}
      >
        <DialogTitle>
          Are you sure you want to approve this request?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Approving the business request will complete the onboarding process.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="success"
            onClick={handleApproveRequest}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => setOpenApproveDialogue(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reject Dialog */}
      <Dialog
        open={openRejectDialogue}
        onClose={() => setOpenRejectDialogue(false)}
      >
        <DialogTitle>Are you sure you want to reject this request?</DialogTitle>
        <form onSubmit={handleRejectRequest}>
          <DialogContent>
            <DialogContentText>
              Rejecting the request will send it back for re-submission. A
              reason is mandatory.
            </DialogContentText>
            <TextField
              variant="outlined"
              required
              multiline
              rows={2}
              fullWidth
              label="Reason for rejection"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="success" type="submit">
              Reject
            </Button>
            <Button
              variant="outlined"
              color="warning"
              onClick={() => setOpenRejectDialogue(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default SingleBusinessRequestDetail;
