// Function to check if any of the provided slugs exist in the role slugs
export function hasPermissions(roles, requiredSlugs) {
  // Iterate over each role to check if any of the required slugs are found in the role's slugs
  return roles?.some((role) =>
    // Check if at least one required slug is in the role's slugs
    requiredSlugs?.some((slug) => role?.slugs?.includes(slug)),
  );
}

// Function to extract routes that the user has permission for
export function getAllowedRoutes(navConfig, roles) {
  // Filter the routes based on their slug array and the user's permissions
  const allowedRoutes = navConfig.filter(
    (route) =>
      // If the route has a slug and the user has permission for any of those slugs
      route.slug && route.slug.length > 0 && hasPermissions(roles, route.slug),
  );

  return allowedRoutes;
}
