import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  addVideo,
  isVideoInserted,
  setAllSelectedVideos,
  setVideo,
} from "../../../ReduxToolkit/Slices/imageSlice";
import { hideLoader } from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import { uploadFiles } from "../actions/api";
import api from "../../../utils/axios";
import { apeitnowBaseURL } from "../../../utils/url";

const ProductVideoUpload = ({
  accept,
  label,
  videos,
  setProductVideo,
  tab,
  maxFileSizeInBytes,
  updateFilesCb,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [select, setSelect] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const ALLVideos = useSelector((state) => state.Images.video);

  const VideoInserted = useSelector(isVideoInserted);
  const handleInsert = () => {
    if (VideoInserted) {
      dispatch(
        showSnackbar({
          message: `Product video has inserted successfully !`,
          severity: "success",
        }),
      );

      handleClose();
    }
  };
  const allVideos = async () => {
    // dispatch(showLoader());
    try {
      const res = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/site-uploads/get/uploads?purpose=video`,
      );
      dispatch(setVideo(res?.data?.media || []));
      console.log("allVideos res", res?.data?.media);

      // if (res?.data.status) {
      //   dispatch(hideLoader());
      // }
    } catch (error) {
      console.log("error", error);
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    if (!VideoInserted) {
      allVideos();
    }
  }, []);
  // Handle video upload
  const handleVideoUpload = async (event) => {
    const file = event.target.files[0];

    // Check if file exists and if it exceeds the max file size
    if (file) {
      if (file.size > maxFileSizeInBytes) {
        setErrorMessage(
          `File size should not exceed ${
            maxFileSizeInBytes / (1024 * 1024)
          } MB`,
        );
        setSelectedVideos([]);
        // setProductVideo(null);
      } else {
        setErrorMessage(""); // Clear any previous error messages
        const fileData = new FormData();
        fileData.append(`media`, file);
        fileData.append(`purpose`, "video");
        const uploadResponse = await uploadFiles(fileData);
        console.log("upload video response", uploadResponse);
        dispatch(addVideo(uploadResponse?.uploads));
        // setSelectedVideo(URL.createObjectURL(file));
        // Store the video URL for preview
        // setProductVideo(file); // Store the actual file in parent state (if necessary)
      }
    }
  };

  const handleVideoSelect = (file, index) => {
    // Check if the image is already selected by its index
    const isSelected = selectedVideos.some((image) => image.index === index);

    if (isSelected) {
      // If the image is selected, remove it from the array (deselect)
      setSelectedVideos(
        selectedVideos.filter((image) => image.index !== index),
      );
    } else {
      // If the image is not selected, add it to the array (select)
      setSelectedVideos([...selectedVideos, { file, index }]);
    }
  };
  useEffect(() => {
    dispatch(setAllSelectedVideos(selectedVideos));
    // updateFilesCb(selectedImages); // Update with the most recent selected images
  }, [selectedVideos]);
  console.log("selectedVideos", selectedVideos);
  return (
    <>
      {" "}
      <Box>
        {/* Label for file upload */}
        <Typography variant="h6">{label}</Typography>

        {/* File input button */}
        <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
          Upload Video
          <input
            type="file"
            accept={accept}
            onChange={handleVideoUpload}
            hidden
          />
        </Button>

        {/* Display error message if file size exceeds limit */}
        {errorMessage && (
          <Typography color="error" sx={{ marginTop: 2 }}>
            {errorMessage}
          </Typography>
        )}
        <Box sx={{ mt: 0.5, width: "100%" }}>
          {/* <Typography>To Upload</Typography> */}
          <Grid container spacing={2}>
            {ALLVideos?.length === 0
              ? "No videos found"
              : ALLVideos?.map((file, index) => (
                  <Grid item key={file.media.name + index}>
                    <Paper
                      sx={{
                        padding: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 1,
                        boxShadow: 1,
                        position: "relative",
                      }}
                    >
                      <video
                        width="400"
                        controls
                        style={{
                          borderRadius: "10px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                          // objectFit: "contain",
                        }}
                      >
                        <source src={file.media.url} type="video/mp4" />
                      </video>
                      <Box sx={{ position: "absolute", top: 0, right: -20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              // Checkbox is checked if the image's index is in selectedVideos array
                              checked={selectedVideos.some(
                                (image) => image.index === index,
                              )}
                              onChange={() => handleVideoSelect(file, index)}
                              color="primary"
                            />
                          }
                          label=""
                        />
                      </Box>
                    </Paper>
                  </Grid>
                ))}
          </Grid>
        </Box>
        {/* Display uploaded video */}
        {/* {selectedVideo && !errorMessage && (
          <Box sx={{ marginTop: 3, position: "relative" }}>
            <video
              width="400"
              controls
              style={{
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                // objectFit: "contain",
              }}
            >
              <source src={selectedVideo} type="video/mp4" />
            </video>
            <Box sx={{ position: "absolute", top: 0, left: 370 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={select}
                    onChange={handleSelectVideo}
                    sx={{
                      color: "#fff",
                    }}
                  />
                }
                label=""
              />
            </Box>
          </Box>
        )} */}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              bgcolor: "#CFEDCC",
              color: "#61B15A",
              "&:hover": {
                backgroundColor: "rgba(82, 118, 10, 1)",
                boxShadow: "none",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleInsert}
            sx={{
              bgcolor: "#CFEDCC",
              color: "#61B15A",
              ml: 2,
              "&:hover": {
                backgroundColor: "rgba(82, 118, 10, 1)",
                boxShadow: "none",
                color: "white",
              },
            }}
          >
            Insert
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ProductVideoUpload;
