import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  CardMedia,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../../ReduxToolkit/Slices/snackbarSlice";
import DeleteModal from "../../../../reusablecomponents/DeleteModal/DeleteModal";
import UploadIcon from "../../../../Assets/ph_music-notes-plus.png";
import AudioPlayer from "./AudioPlayer";
import { getErrorMessage } from "../../../../reusablecomponents/CustomError/errorMessages";
import { apebuzzBaseURL } from "../../../../utils/url";
import api from "../../../../utils/axios";

const SoundTone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sound, setsound] = useState([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [title, setTitle] = useState("");
  const [soundTones, setsoundTones] = useState([]);
  const [fileSize, setFileSize] = useState(null);
  const toneRef = useRef();
  const [DeletedId, setDeletedId] = useState([]);
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  console.log("sound", sound);
  // ********************delete tone function from state ********************
  const handleDeletesound = (index) => {
    setsound((prevSound) => [
      ...prevSound.slice(0, index),
      ...prevSound.slice(index + 1),
    ]);
  };
  // ********************get all tone function********************
  const getAllsoundTone = async (e) => {
    dispatch(showLoader());

    try {
      const res = await api.get(
        `${apebuzzBaseURL}/api/auth/adminAuth/get/sound/tones`,
      );
      console.log("get all tone", res);
      setsoundTones(res?.data?.allSoundTones);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      if (error.response) {
        console.error("Response error:", error.response.data);
      } else if (error.request) {
        console.error("Request error:", error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  };
  useEffect(() => {
    getAllsoundTone();
  }, []);
  const createFormData = async () => {
    const formData = new FormData();
    console.log("sound", sound);
    if (sound && sound.length > 0) {
      sound.forEach((tone) => {
        console.log("file", tone);
        formData.append("file", tone);
      });
    }
    formData.append("toneName", title);

    return formData;
  };
  // ********************Add tone function********************
  const addsoundTone = async (e) => {
    e.preventDefault();
    dispatch(showLoader());

    console.log("sound", sound);
    if (sound.length === 0) {
      dispatch(
        showSnackbar({ message: "Please upload tone", severity: "error" }),
      );
      dispatch(hideLoader());

      return;
    }
    try {
      const getFormData = await createFormData();
      const data = await api.post(
        `${apebuzzBaseURL}/api/auth/adminAuth/add/sound/tone`,
        getFormData,
      );
      console.log("response", data);
      setTitle("");
      dispatch(hideLoader());
      getAllsoundTone();
      setsound([]);
      dispatch(
        showSnackbar({
          message: "Sound  tone added successfully!",
          severity: "success",
        }),
      );
      // dispatch(hideLoader());
    } catch (error) {
      console.log("error", error);
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  // ********************select tone function********************
  const handleFileChange = (event) => {
    const files = event.target.files;
    const soundArray = [];
    const maxFileSizeMB = 0.5;
    const maxsound = 1;
    console.log("files", files);
    if (files.length > 1) {
      dispatch(
        showSnackbar({
          message: "Only one sound tone can be upload",
          severity: "error",
        }),
      );
      return;
    }
    if (sound.length > 0) {
      dispatch(
        showSnackbar({
          message: "Only one sound tone can be upload",
          severity: "error",
        }),
      );
      return;
    }
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      const fileType = file.type.split("/")[0];
      const sizeInMB = file.size / 1024 / 1024;
      if (sizeInMB > maxFileSizeMB) {
        dispatch(
          showSnackbar({
            message: "File size should be less than 500 KB",
            severity: "error",
          }),
        );
        break;
      }
      const acceptedTypes = ["audio"];
      if (acceptedTypes.includes(fileType)) {
        if (soundArray.length < maxsound) {
          soundArray.push(file);
        } else {
          dispatch(
            showSnackbar({
              message: "Only 1 soundTone can be uploaded",
              severity: "error",
            }),
          );
          break;
        }
      } else {
        dispatch(
          showSnackbar({
            message: "Only Audios are allowed",
            severity: "error",
          }),
        );
        break;
      }
    }
    toneRef.current.value = null;
    setsound((prevsound) => [...prevsound, ...soundArray]);
    event.target.value = null;
  };
  const OnClickHandleFileCHange = () => {
    document.getElementById("audio-upload").click();
  };
  return (
    <>
      <Grid container columnGap={2} rowGap={3}>
        <Grid
          item
          xs={12}
          component={Paper}
          elevation={2}
          md={7}
          sm={10}
          sx={{
            display: "flex",
            p: 2,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              width: 250,
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 500,
                color: "rgba(110, 107, 123, 1)",
              }}
            >
              Sound Tone
            </Typography>
          </Box>
          <Stack
            component={"form"}
            onSubmit={addsoundTone}
            spacing={3}
            pr={5}
            sx={{ width: { xs: "100%", sm: "60%" } }}
          >
            <Box
              component={"div"}
              xs={12}
              md={4}
              onClick={(e) => {
                OnClickHandleFileCHange();
              }}
              sx={{
                cursor: "pointer",
                bgcolor: "rgba(243, 243, 243, 1)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                minHeight: 100,
                maxHeight: 200,
                border: "2px dashed gray",
                borderRadius: "10px",
                py: 1.2,
              }}
            >
              <input
                accept="audio/mpeg, audio/ogg, audio/wav, audio/aac"
                id="audio-upload"
                type="file"
                style={{ display: "none" }}
                ref={toneRef}
                onChange={handleFileChange}
              />
              <img
                src={UploadIcon}
                alt="upload icon"
                style={{ width: "25px", height: "25px" }}
              />
              <Typography fontSize="15px">Choose Sound Tone</Typography>
              <Typography fontSize="9px">
                Max Size: 500 KB / sound tone
              </Typography>
            </Box>
            {sound.map((sounds, index) => (
              <Box Box key={index} width={"100%"}>
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <IconButton
                    TouchRippleProps={"off"}
                    sx={{
                      position: "absolute",
                      top: 4,
                      right: { xs: -12, sm: 5 },
                      cursor: "pointer",
                      zIndex: 100,
                    }}
                    onClick={() => handleDeletesound(index)}
                    aria-label="delete"
                  >
                    <DeleteIcon
                      color="#000"
                      sx={{ height: "26px", width: "26px" }}
                    />
                  </IconButton>
                  <audio
                    controls
                    style={{
                      width: "100%",
                      "::-webkit-media-controls-volume-slider": {
                        backgroundColor: "red !important",
                      },
                    }}
                  >
                    <source
                      src={URL.createObjectURL(sounds)}
                      type="audio/mpeg"
                    />
                    <track kind="captions" srcLang="en" />
                  </audio>
                </Box>
              </Box>
            ))}
            <Box>
              <TextField
                fullWidth
                placeholder="Write sound name"
                id="tone"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                variant="outlined"
                required
                label="Tone Name"
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "start" }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  width: "150px",
                  "&:hover": {
                    backgroundColor: "rgba(82, 118, 10, 1)",
                    boxShadow: "none",
                    color: "white",
                  },
                }}
              >
                save
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Box py={4}>
        <Typography
          sx={{
            color: "rgba(110, 107, 123, 1)",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          All Tone
        </Typography>
      </Box>
      <Grid container>
        {soundTones.length === 0
          ? "No data Found"
          : soundTones?.map((data, index) => {
              return (
                <AudioPlayer
                  key={index}
                  data={data}
                  index={data?._id}
                  __id={`index${index}`}
                  currentlyPlaying={currentlyPlaying}
                  setCurrentlyPlaying={setCurrentlyPlaying}
                  getAllsoundTone={getAllsoundTone}
                />
              );
            })}
      </Grid>
    </>
  );
};

export default SoundTone;
