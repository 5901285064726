import React, { useState } from "react";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Thumbnail from "./ImageComponents/Thumbnail";
import ProductImage from "./ImageComponents/ProductImage";
import BannerImage from "./ImageComponents/BannerImage";
import ProductCategoryImages from "./ImageComponents/ProductCategoryImages";
import ProductVideos from "./ImageComponents/ProductVideos";
import ModalComponent from "../../reusablecomponents/ModalComponent/ModalComponent";
import VarientGalleryImages from "./ImageComponents/VarientGalleryImages";

const GlobelImageModal = ({
  open,
  handleClose,
  updateFilesCb,
  resetCb,
  updateDisplayProductImage,
  updateProductVideo,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tab = query?.get("tab") || "0";
  console.log("Open", open);
  const [value, setValue] = useState(open.tab);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ModalComponent open={open.open}>
      <Box mt={1} p={2}>
        <Box
          sx={{
            width: {
              md: 850,
              sm: 500,
              xs: 300,
            },
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            // indicatorColor="rgba(97, 177, 90) !important"
            aria-label="secondary tabs example"
            sx={{
              bgcolor: "transparent",
              "& .MuiTabs-indicator": {
                backgroundColor: "rgba(97, 177, 90)",
              },
            }}
          >
            {open.tab !== "Videos" && (
              <Tab
                value="Product"
                label="Product"
                sx={{
                  bgcolor: "transparent",
                  color: "primary",
                  "&.Mui-selected": {
                    bgcolor: "transparent",
                    color: "rgba(97, 177, 90, 1) !important",
                  },
                }}
              />
            )}
            {open.tab !== "Videos" && (
              <Tab
                value="Banner"
                label="Banner"
                sx={{
                  bgcolor: "transparent",
                  color: "primary",
                  "&.Mui-selected": {
                    bgcolor: "transparent",
                    color: "rgba(97, 177, 90, 1) !important",
                  },
                }}
              />
            )}

            {/* <Tab
              value="VarientGallery"
              label="Varient Gallery"
              sx={{
                bgcolor: "transparent",
                color: "primary",
                "&.Mui-selected": {
                  bgcolor: "transparent",
                  color: "rgba(97, 177, 90, 1) !important",
                },
              }}
            /> */}
            {/* <Tab
              value="Banner"
              label="Banner"
              sx={{
                bgcolor: "transparent",
                color: "primary",
                "&.Mui-selected": {
                  bgcolor: "transparent",
                  color: "rgba(97, 177, 90, 1) !important",
                },
              }}
            /> */}
            {/* <Tab
              value="Category"
              label="Category"
              sx={{
                bgcolor: "transparent",
                color: "primary",
                "&.Mui-selected": {
                  bgcolor: "transparent",
                  color: "rgba(97, 177, 90, 1) !important",
                },
              }}
            /> */}
            {open.tab === "Videos" && (
              <Tab
                value="Videos"
                label="Videos"
                sx={{
                  bgcolor: "transparent",
                  color: "primary",
                  "&.Mui-selected": {
                    bgcolor: "transparent",
                    color: "rgba(97, 177, 90, 1) !important",
                  },
                }}
              />
            )}
          </Tabs>
        </Box>
        <Stack>
          {value === "Banner" && (
            <Thumbnail
              updateFilesCb={updateFilesCb}
              resetCb={resetCb}
              tab={open.tab}
              openedWith={open.openedWith}
              handleClose={handleClose}
            />
          )}
          {value === "Product" && (
            <ProductImage
              resetCb={resetCb}
              tab={open.tab}
              openedWith={open.openedWith}
              handleClose={handleClose}
              updateDisplayProductImage={updateDisplayProductImage}
            />
          )}
          {/* {value === "VarientGallery" && (
            <VarientGalleryImages
              resetCb={resetCb}
              tab={open.tab}
              handleClose={handleClose}
              updateDisplayProductImage={updateDisplayProductImage}
            />
          )} */}
          {/* {value === "Banner" && <BannerImage />} */}
          {/* {value === "Category" && <ProductCategoryImages />} */}
          {value === "Videos" && (
            <ProductVideos
              tab={open.tab}
              openedWith={open.openedWith}
              handleClose={handleClose}
              updateProductVideo={updateProductVideo}
              resetCb={resetCb}
            />
          )}
        </Stack>
      </Box>
    </ModalComponent>
  );
};

export default GlobelImageModal;
