import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  Button,
  InputLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
  Box,
} from "@mui/material";
import axios from "axios";
import { apiUrl } from "../../layouts/apeitnowdashboard/actions/api";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";

const AddCoupon = () => {
  const [formValues, setFormValues] = useState({
    couponName: "",
    minPurchase: "",
    maxPurchase: "",
    discountType: "percent",
    discountValue: "",
    specificUsers: [],
    excludedProducts: [],
    redemptionType: "one-time",
    validTill: null,
    maxRedemption: "",
    couponCode: "",
  });
  const [data, setData] = useState({
    users: [],
    categories: [],
    products: [],
  });
  const [selectionType, setSelectionType] = useState("");
  const [excludedProducts, setExcludedProducts] = useState([]);
  const [includedProducts, setIncludedProducts] = useState([]);
  const [includedCategories, setIncludedCategories] = useState([]);
  const [specificUsers, setSpecificUsers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const getAllProducts = async () => {
    try {
      const res = await api.get(
        selectedCategory === ""
          ? `${apiUrl}/api/v1/apeitnow/merchandise/all/products`
          : `${apiUrl}/api/v1/apeitnow/merchandise/all/products?category=${selectedCategory}`,
      );
      setData((prev) => {
        return { ...prev, products: res.data.products };
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [selectionType]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersRes, categoriesRes, productsRes] = await Promise.all([
          api.get(`${apeitnowBaseURL}/api/v1/apeitnow/user/get/all`),
          api.get(`${apiUrl}/api/v1/apeitnow/categories/all`),
        ]);

        // Set the fetched data to state
        setData({
          users: usersRes.data.data, // data comes from axios' response.data
          categories: categoriesRes?.data?.data,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const memoizedData = useMemo(() => {
    return {
      users: data.users,
      categories: data.categories,
      products: data.products,
    };
  }, [data]);
  // console.log("memoizedData", memoizedData);
  // console.log("product length", memoizedData?.products?.length);
  // console.log("selectedCategory", selectedCategory);
  // console.log("specificUsers", specificUsers);
  // console.log("includedProducts", includedProducts);
  // Minimal handleChange function using destructuring for name and value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRadioClick = (event) => {
    const value = event.target.value;

    if (selectionType === value) {
      // If the radio button is clicked twice, deselect it
      setSelectionType("");
    } else {
      // Otherwise, set the clicked value
      setSelectionType(value);
    }
  };
  const handleCategoryChange = (event, data) => {
    // console.log("data", data.props.value._id);
    // console.log("event", event.target.value);
    setSelectedCategory(event.target.value);
    setIncludedCategories([data.props.value._id]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const SelectedProducts = includedProducts || [];
      const ExProducts = excludedProducts || [];
      const InCategories = includedCategories || [];
      const SelectedUsers = specificUsers || [];
      const res = await api.post(
        `${apeitnowBaseURL}/api/v1/apeitnow/coupons/add/coupon`,
        {
          couponName: formValues.couponName,
          minPurchaseCap: formValues.minPurchase,
          maxDiscountCap: formValues.maxPurchase,
          discount: {
            type: formValues.discountType,
            value: Number(formValues.discountValue),
          },
          isGeneral: selectionType === "",
          redemptionType: formValues.redemptionType,
          maxRedemptions: formValues.maxRedemption,
          validTill: formValues.validTill,
          includedProducts: SelectedProducts,
          excludedProducts: ExProducts,
          includedCategories: InCategories,
          specificUsers: SelectedUsers,
          couponCode: formValues.couponCode,
        },
      );
      setIncludedProducts([]);
      setExcludedProducts([]);
      setIncludedCategories([]);
      setSpecificUsers([]);
      setFormValues({
        couponName: "",
        minPurchase: "",
        maxPurchase: "",
        discountType: "percent",
        discountValue: "",
        specificUsers: [],
        excludedProducts: [],
        redemptionType: "one-time",
        validTill: null,
        maxRedemption: "",
        couponCode: "",
      });
      console.log("response coupon", res);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {/* Coupon type */}
        <Grid item xs={12}>
          <FormLabel component="legend">Select By</FormLabel>
          <RadioGroup row value={selectionType}>
            <FormControlLabel
              value="product"
              control={<Radio onClick={handleRadioClick} />}
              label="Product"
            />
            <FormControlLabel
              value="category"
              control={<Radio onClick={handleRadioClick} />}
              label="Category"
            />
          </RadioGroup>
        </Grid>
        {/* Coupon Name */}

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Coupon Name"
            variant="outlined"
            required
            name="couponName"
            value={formValues.couponName}
            onChange={handleChange}
          />
        </Grid>

        {/* Min Purchase */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Min Purchase Cap"
            variant="outlined"
            required
            name="minPurchase"
            value={formValues.minPurchase}
            onChange={handleChange}
          />
        </Grid>

        {/* Max Purchase */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Max Purchase Cap"
            variant="outlined"
            required
            name="maxPurchase"
            value={formValues.maxPurchase}
            onChange={handleChange}
          />
        </Grid>

        {/* Discount Type */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="discount-type-label">Discount Type</InputLabel>
            <Select
              labelId="discount-type-label"
              name="discountType"
              value={formValues.discountType}
              onChange={handleChange}
              label="Discount Type"
              required
            >
              <MenuItem value="percent">Percent</MenuItem>
              <MenuItem value="amount">Amount</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Discount Value */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Discount Value"
            variant="outlined"
            required
            name="discountValue"
            value={formValues.discountValue}
            onChange={handleChange}
          />
        </Grid>
        {/* Redemption Type */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="redemption-type-label">Redemption Type</InputLabel>
            <Select
              labelId="redemption-type-label"
              name="redemptionType"
              value={formValues.redemptionType}
              onChange={handleChange}
              label="Redemption Type"
              required
            >
              <MenuItem value="one-time">One Time</MenuItem>
              <MenuItem value="multiple-times">Multiple Times</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Valid Till */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Valid Till"
            type="date"
            variant="outlined"
            required
            name="validTill"
            value={formValues.validTill}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Max Redemption */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Max Redemption"
            variant="outlined"
            required
            name="maxRedemption"
            value={formValues.maxRedemption}
            onChange={handleChange}
          />
        </Grid>

        {/* Coupon Code */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Coupon Code (for special occasions)"
            variant="outlined"
            name="couponCode"
            value={formValues.couponCode}
            onChange={handleChange}
          />
        </Grid>
        {/* Specific Users */}
        {(selectionType === "category" || selectionType === "product") &&
          memoizedData?.users?.length > 0 && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                options={memoizedData.users}
                getOptionLabel={(option) => option?.account?.name}
                onChange={(event, newValue) => {
                  const selectedUsersIds = newValue.map((user) => user._id);
                  setSpecificUsers(selectedUsersIds);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Specific Users"
                  />
                )}
              />
            </Grid>
          )}

        {/* Category basee Products */}
        <Grid item xs={12} sm={6}>
          {selectionType === "category" && (
            <FormControl fullWidth>
              <FormLabel>Select Category</FormLabel>
              <Select
                value={selectedCategory}
                onChange={(event, selectedCategory) =>
                  handleCategoryChange(event, selectedCategory)
                }
              >
                {memoizedData.categories.map((category) => (
                  <MenuItem key={category._id} value={category}>
                    {category.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {selectionType === "category" &&
            memoizedData?.products?.length > 0 && (
              <Autocomplete
                multiple
                options={memoizedData.products}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  const selectedProductIds = newValue.map(
                    (product) => product._id,
                  );
                  setExcludedProducts(selectedProductIds);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Exclude Products"
                    placeholder="Exclude products from this category"
                  />
                )}
              />
            )}
        </Grid>
        {selectionType === "product" && memoizedData?.products?.length > 0 && (
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              options={memoizedData.products}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                const selectedProductIds = newValue.map(
                  (product) => product._id,
                );
                setIncludedProducts(selectedProductIds);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Include Products"
                  placeholder="Include products"
                />
              )}
            />
          </Grid>
        )}

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Create Coupon
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddCoupon;
