import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  CloudUpload as UploadIcon,
  Delete as TrashIcon,
} from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../components/FileUpload";
import ProductTypeInput from "../components/ProductTypeInput";
import PanelContainer from "../components/container/PanelContainer";
import TextEditor from "../components/TextEditor";
import { addProduct } from "../actions/api";
import { getErrorMessage } from "../../../reusablecomponents/CustomError/errorMessages";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import { clearImages } from "../../../ReduxToolkit/Slices/imageSlice";

import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import PriceSelection from "../components/ProductPriceInput";

import { apeitnowBaseURL } from "../../../utils/url";

import GlobelImageModal from "../../../components/modal/GlobelImageModal";
import ImageContainer from "../components/ImageContainer";
import ProductImageContainer from "../components/ProductImageContainer";
import ProductVideoContainer from "../components/ProductVideoContainer";
import MetaImageUpload from "../components/MetaImageUpload";
import api from "../../../utils/axios";

const SwitchInput = ({ id, label, name }) => (
  <Stack>
    <Typography component="label" htmlFor={id}>
      {label}
    </Typography>
    <FormControlLabel control={<Switch id={id} name={name} />} label="Status" />
  </Stack>
);

const TextFieldInput = ({
  id,
  label,
  name,
  value,
  required,
  onChange,
  multiline,
  rows,
  type,
}) => (
  <Stack>
    <Typography component="label" htmlFor={id}>
      {label}
      {required ? " *" : ""}
    </Typography>
    {multiline ? (
      <TextField
        id={id}
        variant="outlined"
        multiline
        rows={rows}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        fullWidth
        sx={{ mb: 3 }}
      />
    ) : (
      <TextField
        id={id}
        // label={"Name"}
        variant="outlined"
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        fullWidth
        sx={{ mb: 3 }}
      />
    )}
  </Stack>
);

// Example data
const data = {
  attribute: [{ name: "Size" }, { name: "Material" }],
};

const productPricingData = {
  product: {
    price: "",
    discount: "",
    discountType: "amount" || "percentage",
    salePrice: "",
    discountPercent: "",
  },
};

const Product = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [prequisiteData, setPrequisiteData] = useState(null);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState({
    open: false,
    tab: "",
    openedWith: "",
  });
  const ProductBannerImage = useSelector(
    (state) => state.Images.thumbnailImages,
  );
  const AllProductImages = useSelector((state) => state.Images.productImages);
  const SingleProductVideo = useSelector(
    (state) => state.Images.selectedVideos,
  );
  const AllVarientGallery = useSelector(
    (state) => state.Images.allVarientImages,
  );

  // console.log("AllProductImages", AllProductImages.map((item) => item.file.media));
  const fetchProductRequisites = async () => {
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/merchandise/product/prerquisites`,
      );
      console.log("ProductRequisites", response.data);

      setPrequisiteData(response.data);
    } catch (err) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    fetchProductRequisites();
  }, []);
  const colorOption =
    prequisiteData?.color?.map((color) => ({
      label: color.colorName,
      value: color.colorValue,
    })) || [];

  // Attribute item options
  const attrItemOption = (index) => {
    // Ensure the index is within bounds and the attribute exists
    if (
      prequisiteData?.attribute &&
      index >= 0 &&
      index < prequisiteData?.attribute.length
    ) {
      const _attr = prequisiteData?.attribute[index];
      // Map the values to the desired format
      // console.log("_attr", _attr);

      const tempOptions = _attr?.values?.map((element) => ({
        label:
          element.name.charAt(0).toUpperCase() +
          element.name.slice(1).toLowerCase(), // Capitalize first letter, lowercase the rest
        value: element.value.toLowerCase(), // Ensure value is in lowercase
      }));
      return tempOptions;
    }
    // Return an empty array if the index is out of bounds or attribute is missing
    return [];
  };

  // const [selectedType, setSelectedType] = useState("");
  const [productData, setProductData] = useState({
    name: "",
    unit: "",
    unitValue: "",
  });

  const productType = useRef(null);
  const [displayImage, setDisplayImage] = useState("");
  const [displayProductImage, setDisplayProductImage] = useState([]);
  const [productVideo, setProductVideo] = useState(null);
  const [galleryImage, setGalleryImage] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);
  const [varientGallery, setVarientGalery] = useState([]);
  const [subcategoryOption, setSubcategoryOption] = useState([]);
  const [childCategoryOption, setChildcategoryOption] = useState([]);
  const [resetImageInput, setResetImageInput] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [description, setDescription] = useState("");
  const updateBannerImage = (files) => setBannerImage(files);
  const updateDisplayImage = (files) => setDisplayImage(files);
  const updateDisplayProductImage = (files) => setDisplayProductImage(files);
  const updateProductVideo = (files) => setProductVideo(files);
  const updateGalleryImage = (files) => setGalleryImage(files);
  const [seoTitle, setSeoTitle] = React.useState("");
  const [seoDesc, setSeoDesc] = React.useState("");
  const [seoImage, setSeoImage] = React.useState(null);
  // Local state for subcategory and child category selection
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubcategory] = useState("");
  const [selectedChildCategory, setSelectedChildcategory] = useState("");

  // update brand data on evey brad selction
  function updateBrand(e) {
    setSelectedBrand(e.target.value);
  }
  // update categroy at each selection of category
  function updateCategory(e) {
    setSelectedCategory(e.target.value);

    setSelectedSubcategory("");
    setSelectedChildcategory("");

    const category = prequisiteData.category.find(
      (x) => x._id === e.target.value,
    );
    if (category) {
      const subcategory = [];
      const childCategory = [];
      category?.subCategories?.forEach((sub) => {
        subcategory?.push({ name: sub.subCategoryName, slug: sub._id });
        sub.children?.forEach((child) => {
          childCategory?.push({ name: child.childName, slug: child._id });
        });
      });
      setSubcategoryOption(subcategory);
      setChildcategoryOption(childCategory);
    }
  }

  const [selectedType, setSelectedType] = useState("simple");
  const [selectedColor, setSelectedColor] = useState([]);

  const [selectedAttr, setSelectedAttr] = useState(-1);

  const [attrItemList, setAttrItemList] = useState([]);

  const [variantInputList, setVariantInputList] = useState([]);
  // handle change to hold production information
  const handleProductionInformationChange = (event) => {
    setProductData({ ...productData, [event.target.name]: event.target.value });
  };
  // handle open modal
  const handleOpenModal = (status, tabName, openedWith) => {
    setOpenModal({ open: status, tab: tabName, openedWith });
  };
  
  const handleClose = () => {
    setOpenModal({ open: false, tab: "", openedWith: "" });
  };
  // handleupdate short description
  const handleShortDescription = (e) => {
    setShortDescription(e.target.value);
  };

  // handle input hangefor
  const handleInputChange = (evt, index) => {
    // console.log("file", file);
    // setVariantInputList(file);
    const { name, value } = evt.target;
    setVariantInputList((prev) => {
      const updatedList = [...prev];
      updatedList[index] = { ...updatedList[index], [name]: value };
      return updatedList;
    });
  };

  // handle change to set selected value
  const handleSelectChange = (event) => {
    setSelectedType(event.target.value);
  };

  // handle chage for attribyre
  const changeAttr = (value) => {
    setSelectedAttr(parseInt(value, 10));
    setAttrItemList([]);
  };

  const handleTitleChange = (event) => {
    setSeoTitle(event.target.value);
  };

  const handleDescChange = (event) => {
    setSeoDesc(event.target.value);
  };

  const handleImageUpload = (newImage) => {
    setSeoImage(newImage);
  };
  // console.log("displayImage", displayImage);
  useEffect(() => {
    const arrList = [];
    if (selectedColor?.length || attrItemList.length) {
      selectedColor.forEach((color) => {
        attrItemList?.forEach((attr) => {
          const combination = {
            color: color?.label || null,
            attr: attr?.label || null,
            price: "",
            sku: "",
            qty: "",
            imageIndex: 0,
          };
          arrList.push(combination);
        });
      });

      if (!attrItemList.length) {
        selectedColor?.forEach((color) => {
          arrList.push({
            color: color.label,
            attr: null,
            price: "",
            sku: "",
            qty: "",
            imageIndex: 0,
          });
        });
      }

      if (!selectedColor.length) {
        attrItemList?.forEach((attr) => {
          arrList.push({
            color: null,
            attr: attr.label,
            price: "",
            sku: "",
            qty: "",
            imageIndex: 0,
          });
        });
      }
    }
    setVariantInputList(arrList);
    return () => {
      setVariantInputList([]);
    };
  }, [selectedColor, attrItemList]);

  const formHandler = async (e) => {
    e.preventDefault();
    dispatch(showLoader());
    // console.log("varientGallery", varientGallery);
    // if (displayImage.length === 0 || galleryImage.length === 0) {
    //   alert("Please fill in all the required fields!");
    //   return; // Early return to stop further execution if the validation fails
    // }
    const formDataEntries = {};
    const form = document.querySelector("#product_form");
    const formData = new FormData(form);

    // console.log("formDAta==>", formData);
    // console.log("banner before", bannerImage);

    // Convert arrays/objects to JSON strings for appending to FormData
    const displayImg = JSON.stringify(
      ProductBannerImage.map((item) => item.file.media),
    );
    const seo = JSON.stringify({
      title: seoTitle.trim(),
      description: seoDesc.trim(),
      image: seoImage,
    });
    const newVarientData = variantInputList.map((item) => ({
      ...item, // spread the existing object properties
      gallery: AllVarientGallery.map((item) => item.file.media), // add arrayTwo as a property
    }));
    const newProductImageData = AllProductImages.map(
      (item) => item.file.media,
    ).map((item) => ({
      ...item, // spread the existing object properties
      videos: SingleProductVideo.map((item) => item.file.media), // add arrayTwo as a property
    }));
    const galleryImg = JSON.stringify(newProductImageData);

    console.log("newVarientData", newVarientData);
    console.log("newProductImageData", newProductImageData);
    // Append data to FormData
    const productImage = JSON.stringify(newProductImageData.slice(0, 1));
    const imageThumbnails = JSON.stringify(newProductImageData.slice(1));
    formData.append("displayImage", productImage);
    formData.append("galleryImages", imageThumbnails);
    formData.append("bannerImages", displayImg);
    // formData.append("videos", videos);
    // formData.append("productVideo", productVideo);
    formData.append("type", selectedType);
    formData.append("category", JSON.stringify([selectedCategory]));
    formData.append("subcategory", JSON.stringify([selectedSubCategory]));
    formData.append("childCategory", JSON.stringify([selectedChildCategory]));
    formData.append("brand", selectedBrand);
    formData.append("color", JSON.stringify(selectedColor));
    formData.append("attribute", JSON.stringify(attrItemList));
    formData.append("selectedAttribute", String(selectedAttr));
    formData.append("variant", JSON.stringify(newVarientData));
    formData.append("seo", seo);
    formData.append("description", description);
    formData.append("coordinates", JSON.stringify([41.40338, 2.17403]));
    formData.forEach((value, key) => {
      formDataEntries[key] = value;
    });
    console.table(formDataEntries);
    try {
      const res = await addProduct(formData);
      console.log("production creation response", res);
      navigate("/dashboard/product/all-product", { replace: true });
      dispatch(hideLoader());

      if (res.success) {
        dispatch(
          showSnackbar({
            message: `Product Added Successfully !`,
            severity: "success",
          }),
        );
        dispatch(
          clearImages({
            thumbnail: [],
            thumbnailImages: [],
            productImages: [],
            categoryImage: [],
            video: [],
            selectedVideos: [],
            all: [],
          }),
        );
        // Reset form and related states
        form.reset();
        setSelectedCategory("");
        setSelectedSubcategory("");
        setSelectedChildcategory("");
        setVariantInputList([]);
        setSelectedType("simple");
        setSelectedColor([]);
        setSelectedAttr(0);
        setResetImageInput("reset");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: `Something went wrong, please try again.`,
          severity: "error",
        }),
      );
    }
  };
  const handleVarientgallery = (event) => {
    const selectedFile = event.target.files[0];
    setVarientGalery(selectedFile);
  };
  return (
    <>
      <PanelContainer>
        <Box
          id="product_form"
          component={"form"}
          encType="multipart/form-data"
          onSubmit={formHandler}
        >
          <Grid
            container
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"start"}
          >
            <Grid item xs={12} md={7.5}>
              {ProductType(selectedType, handleSelectChange)}
              {ProductInformation(
                handleProductionInformationChange,
                productData,
              )}
              {ProductDescription(
                shortDescription,
                handleShortDescription,
                description,
                setDescription,
              )}
              <Card sx={{ mb: 5, boxShadow: 3 }}>
                <CardHeader
                  title={
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      Media
                    </Typography>
                  }
                />
                <Divider />
                <CardContent>
                  <Stack rowGap={4}>
                    {/* this section is for product images  */}
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        color: "#4D5464",
                      }}
                    >
                      {`Product images(465px x 441px) *`}
                    </Typography>
                    {AllProductImages.length !== 0 && (
                      <ProductImageContainer
                        img={AllProductImages}
                        setDisplayProductImage={setDisplayProductImage}
                        tab={openModal.tab}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() =>
                          handleOpenModal(true, "Product", "Product")
                        }
                        startIcon={<UploadIcon />}
                        sx={{
                          bgcolor: "#CFEDCC",
                          color: "#61B15A",
                          margin: 1,
                          "&:hover": {
                            backgroundColor: "rgba(82, 118, 10, 1)",
                            boxShadow: "none",
                            color: "white",
                          },
                        }}
                      >
                        Add Image
                      </Button>
                    </Box>

                    {/* this section will use for the product banner images instead of thumbail image */}
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        color: "#4D5464",
                      }}
                    >
                      {`Product Banner Images *`}
                    </Typography>
                    {ProductBannerImage.length !== 0 && (
                      <ImageContainer
                        img={ProductBannerImage}
                        setDisplayImage={setDisplayImage}
                        tab={openModal.tab}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() =>
                          handleOpenModal(true, "Banner", "Banner")
                        }
                        startIcon={<UploadIcon />}
                        sx={{
                          bgcolor: "#CFEDCC",
                          color: "#61B15A",
                          margin: 1,
                          "&:hover": {
                            backgroundColor: "rgba(82, 118, 10, 1)",
                            boxShadow: "none",
                            color: "white",
                          },
                        }}
                      >
                        Add Image
                      </Button>
                    </Box>
                    {/* this section is for the product videos */}
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        color: "#4D5464",
                      }}
                    >
                      {`Video`}
                    </Typography>
                    {SingleProductVideo !== null && (
                      <ProductVideoContainer
                        videos={SingleProductVideo}
                        setProductVideo={setProductVideo}
                        tab={openModal.tab}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() =>
                          handleOpenModal(true, "Videos", "Videos")
                        }
                        startIcon={<UploadIcon />}
                        sx={{
                          bgcolor: "#CFEDCC",
                          color: "#61B15A",
                          margin: 1,
                          "&:hover": {
                            backgroundColor: "rgba(82, 118, 10, 1)",
                            boxShadow: "none",
                            color: "white",
                          },
                        }}
                      >
                        Add Video
                      </Button>
                    </Box>
                    {/* <FileUpload
                      accept=".jpg,.png,.jpeg"
                      label={"Thumbnail Image (300px x 300px)"}
                      maxFileSizeInBytes={20971520}
                      updateFilesCb={updateDisplayImage}
                      resetCb={resetImageInput}
                      handleOpenModal={handleOpenModal}
                    /> */}

                    {/* <FileUpload
                      accept=".jpg,.png,.jpeg"
                      label={"Gallery Images (500px x 500px)"}
                      multiple
                      maxFileSizeInBytes={20971520}
                      updateFilesCb={updateGalleryImage}
                      resetCb={resetImageInput}
                    /> */}
                    {/* <FileUpload
                      accept=".jpg,.png,.jpeg"
                      label={"Banner Images"}
                      multiple
                      maxFileSizeInBytes={20971520}
                      updateFilesCb={updateBannerImage}
                      resetCb={resetImageInput}
                    /> */}
                  </Stack>
                </CardContent>
              </Card>

              <ProductTypeInput
                selectedType={selectedType}
                colorOption={colorOption}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                data={prequisiteData}
                selectedAttr={selectedAttr}
                changeAttr={changeAttr}
                attrItemOption={attrItemOption}
                attrItemList={attrItemList}
                setAttrItemList={setAttrItemList}
                variantInputList={variantInputList}
                handleInputChange={handleInputChange}
                handleVarientgallery={handleVarientgallery}
                varientGallery={varientGallery}
                setVarientGalery={setVarientGalery}
                galleryImage={
                  AllProductImages && AllProductImages.length > 0
                    ? AllProductImages
                    : []
                } // Mock gallery images if any
              />
              {SeoInput(
                seoTitle,
                handleTitleChange,
                seoDesc,
                handleDescChange,
                handleImageUpload,
                seoImage,
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <PriceSelection data={productPricingData} />
              {CategorySelection(
                selectedSubCategory,
                selectedChildCategory,
                updateCategory,
                prequisiteData,
                setSelectedSubcategory,
                setSelectedChildcategory,
                subcategoryOption,
                childCategoryOption,
                updateBrand,
              )}
              {VatTaxSelection()}
            </Grid>
          </Grid>
          <Stack mt={2}>
            <Button variant="contained" color="primary" type="submit">
              Add Product
            </Button>
          </Stack>
        </Box>
      </PanelContainer>
      {openModal.open && (
        <GlobelImageModal
          open={openModal}
          handleClose={handleClose}

          // updateFilesCb={updateDisplayImage}
          // resetCb={resetImageInput}
          // updateDisplayProductImage={updateDisplayProductImage}
          // updateProductVideo={updateProductVideo}
        />
      )}
    </>
  );
};

// Subcomponent for Select input
const SelectInput = ({ id, label, options, onChange, value }) => (
  <Box>
    <FormControl fullWidth size="small">
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={onChange}
        required
      >
        <MenuItem value="" disabled>
          {label}
        </MenuItem>
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

// create product type ui
function ProductType(selectedType, handleSelectChange) {
  return (
    <Card sx={{ mb: 5, boxShadow: 3 }}>
      <CardHeader
        title={
          <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
            {/* {t */}
            Product Type
            {/* } */}
          </Typography>
        }
        //
      />
      <Divider />
      <CardContent>
        <Grid container rowGap={3}>
          {/* <Grid item md={4} sm={6} xs={12}>
            <SwitchInput
              id="inp-110"
              // label={t("new_product")}
              label="new product"
              name="new_product"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <SwitchInput
              id="inp-11"
              // label={t("trending_product")}
              label="trending product"
              name="trending"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <SwitchInput
              id="inp-111"
              // label={t("best_selling_product")}
              label="best selling"
              name="best_selling"
            />
          </Grid> */}
          <Grid item xs={12}>
            <SelectInput
              id="inp-type"
              // label={t("Product Type") + "*"}
              // label="product type"

              value={selectedType}
              onChange={handleSelectChange}
              options={[
                { value: "simple", label: "Simple Product" },
                // { value: "variable", label: "Variable Product" },
              ]}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

// Subcomponent for handling product information
function ProductInformation(handleChange, productData) {
  // Handle input change for product information
  return (
    <Card sx={{ mb: 5, boxShadow: 3 }}>
      <CardHeader
        title={
          <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
            General Information
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <TextFieldInput
          id="name"
          name="name"
          label="Name"
          value={productData.name}
          onChange={handleChange}
          required
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <TextFieldInput
            id="unit"
            label={"Unit"}
            name="unit"
            type={"number"}
            value={productData.unit}
            onChange={handleChange}
            required
          />

          <TextFieldInput
            id="unitValue"
            label={"Unit Value"}
            name="unitValue"
            type={"number"}
            value={productData.unitValue}
            onChange={handleChange}
            required
          />
        </Box>
      </CardContent>
    </Card>
  );
}

// Productio description
function ProductDescription(
  shortDescription,
  onShortDescriptionChange,
  description,
  setDescription,
) {
  return (
    <Card sx={{ mb: 5, boxShadow: 3 }}>
      <CardHeader
        title={
          <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
            Product Description
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextFieldInput
              id="shortDescription"
              name="shortDescription"
              label="Short Description"
              value={shortDescription}
              onChange={onShortDescriptionChange}
              required
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Description
              </Typography>
              <TextEditor
                previousValue={description}
                updatedValue={setDescription}
                height={300}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

// seo optimization
function SeoInput(
  seoTitle,
  handleTitleChange,
  seoDesc,
  handleDescChange,
  handleImageUpload,
  seoImage,
) {
  return (
    <>
      <Card sx={{ mb: 5, boxShadow: 3 }}>
        <CardHeader
          title={
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              {/* {t */}
              SEO Meta Tags
              {/* } */}
            </Typography>
          }
        />
        <Divider />

        <CardContent>
          <FormGroup>
            <FormControl fullWidth>
              <FormLabel htmlFor="seo-title">{"Meta Title"}</FormLabel>
              <TextField
                id="seo-title"
                value={seoTitle}
                onChange={handleTitleChange}
                placeholder={"Enter Meta Title"}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel htmlFor="seo-description">
                {"Meta Description"}
              </FormLabel>
              <TextField
                id="seo-description"
                value={seoDesc}
                onChange={handleDescChange}
                placeholder={"Enter Meta Description"}
                multiline
                rows={4}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel htmlFor="seo-image">{"Meta Image"}</FormLabel>
              <MetaImageUpload
                accept=".jpg,.png,.jpeg"
                label={"Upload image"}
                maxFileSizeInBytes={2000000}
                updateFilesCb={handleImageUpload}
                preSelectedFiles={seoImage ? [seoImage] : []} // Handle pre-selected image
              />
              {/* {seoImage && (
                <InputAdornment position="end">
                  <IconButton href={seoImage} target="_blank">
                    jj
                  </IconButton>
                </InputAdornment>
              )} */}
            </FormControl>
          </FormGroup>
        </CardContent>
      </Card>
    </>
  );
}

// // pricing
// function PriceSelection(data) {
//   return (
//     <Card sx={{ mb: 5, boxShadow: 3 }}>
//       <CardHeader
//         title={"Price Information"}
//         titleTypographyProps={{ variant: "h6", fontWeight: "bold" }}
//       />
//       <Divider />
//       <CardContent>
//         <Box sx={{ mb: 2 }}>
//           <FormControl fullWidth>
//             <FormLabel htmlFor="inp-4">
//               {"Price"}
//               {"*"}
//             </FormLabel>

//             <TextField
//               fullWidth
//               variant="outlined"
//               // label={"price"}
//               type="number"
//                size="small"
//               // step="0.01"
//               id="inp-4"
//               name="main_price"
//               required
//               InputProps={{
//                 inputProps: { min: 0 },
//                 onWheel: (e) => e.target.blur(),
//               }}
//               defaultValue={data.product.price}
//             />
//           </FormControl>
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <FormControl fullWidth>
//             <FormLabel htmlFor="inp-4">
//               {"Sale Price"}
//               {"*"}
//             </FormLabel>

//             <TextField
//               fullWidth
//               variant="outlined"
//               // label={"price"}
//               type="number"
//                size="small"
//               // step="0.01"
//               id="inp-4"
//               name="sale_price"
//               required
//               InputProps={{
//                 inputProps: { min: 0 },
//                 onWheel: (e) => e.target.blur(),
//               }}
//               defaultValue={data.product.salePrice}
//             />
//           </FormControl>
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <FormControl fullWidth>
//             <FormLabel htmlFor="inp-4">
//               {"Discount Amount"}
//               {"*"}
//             </FormLabel>

//             <TextField
//               fullWidth
//               variant="outlined"
//               // label={"price"}
//               type="number"
//               size="small"
//               // step="0.01"
//               id="inp-4"
//               name="discount"
//               required
//               InputProps={{
//                 inputProps: { min: 0 },
//                 onWheel: (e) => e.target.blur(),
//               }}
//               defaultValue={data.product.discount}
//             />
//           </FormControl>
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <FormControl fullWidth>
//             <FormLabel htmlFor="inp-5">
//               {"Discount in Percentage"}
//             </FormLabel>
//             <TextField
//               fullWidth
//               variant="outlined"
//               type="number"
//                size="small"
//               // step="0.1"
//               id="inp-5"
//               disabled
//               name="discountPercentage"
//               required
//               placeholder="0%"
//               helperText="* This field is editable will take value autmatically"
//               InputProps={{
//                 inputProps: { min: 0, max: 100 },
//                 onWheel: (e) => e.target.blur(),
//               }}
//             />
//           </FormControl>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// }

// category selection
function CategorySelection(
  selectedSubCategory,
  selectedChildCategory,
  updateCategory,
  data,
  setSelectedSubcategory,
  setSelectedChildcategory,
  subcategoryOption,
  childCategoryOption,
  updateBrand,
) {
  return (
    <Card sx={{ mb: 5, boxShadow: 3 }}>
      <CardHeader
        title={"Product Category"}
        titleTypographyProps={{ variant: "h6", fontWeight: "bold" }}
      />
      <Divider />
      <CardContent>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth required>
            <FormLabel htmlFor="category">{"categories"}</FormLabel>

            <Select
              size="small"
              id="category"
              onChange={updateCategory}
              defaultValue=""
              // key={data?.product?.categories[0]}
              required
            >
              <MenuItem value="">
                <em>Select Category</em>
              </MenuItem>
              {data?.category?.map((x, i) => (
                <MenuItem key={i} value={x?._id} color="red">
                  {x.categoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            {/* <InputLabel>{"Subcategories"}</InputLabel> */}
            <FormLabel htmlFor="subcategories">{"Subcategories"}</FormLabel>

            <Select
              size="small"
              id="subcategories"
              // label={"Subcategories"}
              value={selectedSubCategory}
              onChange={(e) => setSelectedSubcategory(e.target.value)}
            >
              <MenuItem value="">
                <em>Select Sub Category</em>
              </MenuItem>
              {subcategoryOption?.map((x, i) => (
                <MenuItem key={i} value={x.slug}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="childCategory">{"Child Categories"}</FormLabel>

            <Select
              size="small"
              id="childCategory"
              value={selectedChildCategory}
              onChange={(e) => setSelectedChildcategory(e.target.value)}
            >
              <MenuItem value="">
                <em>Select Child Category</em>
              </MenuItem>
              {childCategoryOption?.map((x, i) => (
                <MenuItem key={i} value={x.slug}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box> */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth required>
            {/* <InputLabel>{"Brands"}</InputLabel> */}
            <FormLabel htmlFor="brands">{"Brands"}</FormLabel>

            <Select
              id="brands"
              size="small"
              onChange={updateBrand}
              defaultValue={""}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {data?.brand?.map((x) => (
                <MenuItem value={x._id} key={x._id}>
                  {x.brandName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
}

// taxation
function VatTaxSelection() {
  return (
    <Card sx={{ mb: 5, boxShadow: 3 }}>
      <CardHeader
        title={"Tax Information"}
        titleTypographyProps={{ variant: "h6", fontWeight: "bold" }}
      />
      <Divider />
      <CardContent>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth required>
            <FormLabel htmlFor="vat">{"SGST in Percentage"}</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              // label={"Vat in Percentage"}
              type="number"
              id="inp-47"
              name="sgst"
              placeholder="0%"
              required
              InputProps={{
                inputProps: { min: 0, max: 100 },
                onWheel: (e) => e.target.blur(),
              }}
            />
          </FormControl>
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth required>
            <FormLabel htmlFor="tax">{"CGST in Percentage"}</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              // label={"Tax in Percentage"}
              type="number"
              id="inp-48"
              name="cgst"
              placeholder="0%"
              required
              InputProps={{
                inputProps: { min: 0, max: 100 },
                onWheel: (e) => e.target.blur(),
              }}
            />
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
}
export default Product;
