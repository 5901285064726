import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../../ReduxToolkit/Slices/snackbarSlice";
import { ReactComponent as NotificationIcon } from "../../../../Assets/basil_edit-outline.svg";
import editIcon from "../../../../Assets/updateIcon.svg";
import TrashIcon from "../../../../Assets/solar_trash-bin-2-bold-duotone.svg";
import DeleteModal from "../../../../reusablecomponents/DeleteModal/DeleteModal";
import UpdateQuote from "./UpdateQuote";
import { getErrorMessage } from "../../../../reusablecomponents/CustomError/errorMessages";
import { apebuzzBaseURL } from "../../../../utils/url";
import api from "../../../../utils/axios";

const AllQuote = ({ quote, getAllQuotes }) => {
  const dispatch = useDispatch();
  const [randomquote, setRandomquote] = useState(null);
  const [singleQuote, setSingleQuote] = useState({
    id: "",
    singledata: null,
    open: false,
  });
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });

  const getSingleQuotes = async () => {
    dispatch(showLoader());
    try {
      const res = await api.get(
        `${apebuzzBaseURL}/api/auth/adminAuth/get/selected/active/quote`,
      );
      setRandomquote(res?.data.quote.quote);
      if (res?.data.success) {
        dispatch(hideLoader());
      }
    } catch (error) {
      console.log("App Ambasder error", error);
      // dispatch(
      //   showSnackbar({
      //     message: `${error?.response.data.message} !`,
      //     severity: "error",
      //   }),
      // );
      setRandomquote(null);
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getSingleQuotes();
  }, []);
  const handleClose = () => {
    setDialog({
      open: false,
      title: "",
      buttonText: "",
      data: {
        id: null,
      },
    });
    setSingleQuote({
      id: "",
      singledata: null,
      open: false,
    });
  };
  const handleDelete = async () => {
    dispatch(showLoader());
    try {
      const res = await api.delete(
        `${apebuzzBaseURL}/api/auth/adminAuth/delete/quote?quoteId=${dialog.data.id}`,
      );
      getAllQuotes();
      if (res?.data.success) {
        dispatch(hideLoader());
        getSingleQuotes();
        dispatch(
          showSnackbar({
            message: `Quote deleted successfully !`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      console.log("App Ambasder error", error);
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  const ActivateQuote = async () => {
    dispatch(showLoader());
    try {
      const res = await api.post(
        `${apebuzzBaseURL}/api/auth/adminAuth/select/and/add/quote?quoteId=${dialog.data.id}`,
      );
      getAllQuotes();
      getSingleQuotes();

      if (res?.data.success) {
        dispatch(hideLoader());
        dispatch(
          showSnackbar({
            message: `Quote activated successfully !`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      console.log("App Ambasder error", error);
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };

  return (
    <Grid container direction={"column"} mt={1.5}>
      <Grid item xs={11.8} py={0.5} pr={1.2} direction={"column"}>
        <Box sx={{ display: "flex", mt: 2 }}>
          <Typography
            sx={{ fontSize: "22px", color: "rgba(162, 159, 169, 1)" }}
          >
            Activated Quote
          </Typography>
        </Box>
        <Box
          sx={{
            px: 2,
            py: 3,
            borderRadius: "5px",
            border: "1px solid rgba(204, 204, 204, 1)",
            width: "100%",
            mt: 2,
          }}
        >
          <Typography>{randomquote}</Typography>
        </Box>
      </Grid>
      <Typography
        sx={{ fontSize: "22px", color: "rgba(162, 159, 169, 1)", py: 2 }}
      >
        All Quotes
      </Typography>
      {quote === null
        ? "No Records found"
        : quote?.map((data, index) => (
            <Grid item key={index} xs={11.8} py={0.5} pr={1.2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 1.6,
                    borderRadius: "5px",
                    border: "1px solid rgba(204, 204, 204, 1)",
                    width: "68%",
                  }}
                >
                  <Typography>{data.quote}</Typography>
                </Box>
                <Box
                  sx={{
                    p: 1.5,
                    borderRadius: "5px",
                    ml: 2,
                    backgroundColor: "rgba(239, 245, 238, 1)",
                    width: "30%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setDialog({
                        open: true,
                        title: "quote",
                        buttonText: "",
                        data: {
                          id: data._id,
                        },
                      })
                    }
                  >
                    <img
                      alt="delete"
                      style={{ height: "30px", width: "30px" }}
                      src={TrashIcon}
                    />
                    <Typography
                      sx={{
                        color: "rgba(111, 108, 144, 1)",
                        fontSize: "15px",
                      }}
                    >
                      Delete
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setSingleQuote({
                        id: data._id,
                        singledata: data,
                        open: true,
                      })
                    }
                  >
                    <img
                      alt="edit"
                      style={{ height: "30px", width: "30px" }}
                      src={editIcon}
                    />
                    <Typography
                      sx={{
                        color: "rgba(111, 108, 144, 1)",
                        fontSize: "15px",
                      }}
                    >
                      Update
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setDialog({
                        open: true,
                        title: "Are you sure to activate this quote ?",
                        buttonText: "Activate",
                        data: {
                          id: data._id,
                        },
                      })
                    }
                  >
                    {/* <img
                      alt="activate"
                      style={{
                        height: "30px",
                        width: "30px",
                        fill: data.isActive ? "orange" : "",
                      }}
                      src={NotificationIcon}
                    /> */}
                    <NotificationIcon
                      style={{
                        fill: data.isActive ? "orange" : "transparent",
                      }}
                      width={30}
                      height={30}
                    />
                    <Typography
                      sx={{
                        color: "rgba(111, 108, 144, 1)",
                        fontSize: "15px",
                      }}
                    >
                      {data.isActive ? "Deactivate" : "Activate"}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          ))}
      {dialog.open && (
        <DeleteModal
          open={dialog.open}
          handleClose={handleClose}
          dialog={dialog}
          deleteFunc={() => handleDelete()}
        />
      )}
      {dialog.open && dialog.buttonText !== "" && (
        <DeleteModal
          open={dialog.open}
          handleClose={handleClose}
          dialog={dialog}
          deleteFunc={() => ActivateQuote()}
        />
      )}
      {singleQuote.open && singleQuote.singledata !== null && (
        <UpdateQuote
          handleClose={handleClose}
          data={singleQuote}
          getAllQuotes={getAllQuotes}
        />
      )}
    </Grid>
  );
};
AllQuote.propTypes = {
  quote: PropTypes.any,
  getAllQuotes: PropTypes.func,
};
export default AllQuote;
