import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Tooltip, // Import TextField from Material-UI
} from "@mui/material";

const CouponTable = ({ coupons }) => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Created Coupons
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Terms</TableCell>
              <TableCell>Expires At</TableCell>
              <TableCell>Price (in points)</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Coupon Code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No coupons created yet.
                </TableCell>
              </TableRow>
            ) : (
              coupons?.map((coupon, index) => (
                <TableRow
                  sx={{
                    bgcolor:
                      coupon?.category.toLowerCase() === "bronze"
                        ? "#F4A261" // Light Bronze color
                        : coupon?.category.toLowerCase() === "silver"
                        ? "#D3D3D3" // Light Silver color
                        : coupon?.category.toLowerCase() === "gold"
                        ? "#FFEB3B" // Light Gold color
                        : "#FFFFFF", // Default color (white) if category is not found
                  }}
                  key={index}
                >
                  <TableCell>{coupon?.category}</TableCell>
                  <TableCell>{coupon?.type}</TableCell>
                  <TableCell>{coupon?.couponDescription}</TableCell>
                  {/* Add TextField with multiline for Terms and Conditions */}
                  <TableCell>
                    <Tooltip title={`${coupon?.termsAndConditions}`}>
                      <TextField
                        value={coupon?.termsAndConditions || ""}
                        multiline
                        size="small"
                        sx={{ fontSize: "10px" }}
                        rows={2} // Sets the default number of rows (height)
                        //   maxRows={2} // Limits the expansion to 5 rows
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          readOnly: true, // If you want to make it read-only
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell>{new Date(coupon?.expiresAt).toLocaleString("en-US")}</TableCell>
                  <TableCell>{coupon?.pointsPrice}</TableCell>
                  <TableCell>
                    {coupon?.type === "money" ? coupon.amount : "-"}
                  </TableCell>
                  <TableCell>{coupon?.code ? coupon?.code : "-"}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CouponTable;
