// component
import { Box } from "@mui/material";
import SvgColor from "../../../components/svg-color";
import Iconify from "../../../components/iconify/Iconify";

export const newIcon = (iconName) => (
  <Box
    className="box"
    sx={{
      width: 25,
      height: 25,
      borderRadius: "0.25rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#BDC4CB",
      color: "currentcolor",
    }}
  >
    <Iconify icon={iconName} />
  </Box>
);

export const icon = (name) => (
  // <img
  //   style={{ width: 25, height: 25 }}
  //   src={`/assets/icons/navbar/${name}.svg`}
  //   alt="sidebarIcon"
  // />

  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{
      width: 1,
      height: 1,
      bgcolor: "rgba(207, 237, 204, 1)",
      color: "rgba(97, 177, 90, 1)", // Set icon color
    }}
  />
);

export const navConfig = [
  {
    type: "route",
    title: "dashboard",
    path: "/dashboard/home",
    slug:["apebuzz-dashboard"], // permisison slug
    icon: newIcon("fluent:arrow-trending-lines-24-filled"),
  },
  {
    type: "route",
    title: "user management",
    path: "/dashboard/user-management",
    icon: newIcon("ph:user-fill"),
    slug:["apebuzz-user_management"], // permisison slug
  },
  {
    type: "collapse",
    title: "App Customization",
    icon: newIcon("gridicons:customize"),
    path: "/dashboard/app-customization/app-ambassador",
    slug:["apebuzz-app_customization"], // permisison slug
    subRoutes: [
      {
        type: "route",
        name: "app ambassador",
        key: "app-ambassador",
        path: "/dashboard/app-customization/app-ambassador",
      },
      {
        type: "route",
        name: "manage avatar",
        key: "manage-avatar",
        path: "/dashboard/app-customization/manage-avatar",
      },
      {
        type: "route",
        name: "manage badge",
        key: "manage-badge",
        path: "/dashboard/app-customization/manage-badge",
      },
      {
        type: "route",
        name: "sound tone",
        key: "sound-tone",
        path: "/dashboard/app-customization/sound-tone",
      },
      {
        type: "route",
        name: "manage stickers",
        key: "manage-stickers",
        path: "/dashboard/app-customization/manage-stickers",
      },
      {
        type: "route",
        name: "manage animations",
        key: "manage-animations",
        path: "/dashboard/app-customization/manage-animations",
      },
      // ManageAnimations
    ],
  },
  {
    type: "route",
    title: "coupon management",
    path: "/dashboard/coupon-management",
    icon: newIcon("mdi:coupon-outline"),
    slug:["apebuzz-coupon_management"], // permisison slug
    // <iconify-icon icon="mdi:coupon-outline"></iconify-icon>
  },
  {
    type: "collapse",
    title: "Business Onboarding",
    icon: newIcon("material-symbols-light:add-business-outline"),
    path: "/dashboard/business",
    slug:["apebuzz-business_onboarding"], // permisison slug
    subRoutes: [
      {
        type: "route",
        name: "Onboarding Requests",
        key: "onboarding-requests",
        path: "/dashboard/business/onboarding-requests",
      },
      {
        type: "route",
        name: "Registered Businessess",
        key: "registered-businessess",
        path: "/dashboard/business/registered-businessess",
      },
    ],
  },
  {
    type: "collapse",
    title: "CMS",
    icon: newIcon("iconoir:notes"),
    path: "/dashboard/cms/privacy-policy",
    slug:["apebuzz-cms"], // permisison slug
    subRoutes: [
      {
        type: "route",
        name: "privacy policy",
        key: "privacy-policy",
        path: "/dashboard/cms/privacy-policy",
      },
      {
        type: "route",
        name: "terms conditions",
        key: "Terms_&_Conditions ",
        path: "/dashboard/cms/terms-conditions",
      },
      {
        type: "route",
        name: "feedback",
        key: "manage-badge",
        path: "/dashboard/cms/feedback",
      },
    ],
  },
  {
    type: "route",
    title: "Reported Users",
    path: "/dashboard/reported-users",
    icon: newIcon("ic:outline-report"),
    slug:["apebuzz-reported_user"], // permisison slug
  },
  {
    type: "route",
    title: "Custom Notification",
    path: "/dashboard/custom-notification",
    icon: newIcon("mage:notification-bell-download"),
    slug:[""], // permisison slug
  },
];

export default navConfig;
