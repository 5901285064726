import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";

import DashboardAppPage from "./pages/DashboardAppPage";

import UserManagement from "./components/adminSection/UserManagement/UserManagement";
import AppAmbassador from "./components/adminSection/AppCustomization/AppAmbassador/AppAmbassador";
import ManageAvatar from "./components/adminSection/AppCustomization/ManageAvatar/ManageAvatar";
import ManageBadge from "./components/adminSection/AppCustomization/ManageBadge/ManageBadge";
import SoundTone from "./components/adminSection/AppCustomization/SoundTone/SoundTone";
import ForgotPassword from "./pages/ForgotPassword";
import Verification from "./pages/Verification";
import ResetPassword from "./pages/ResetPassword";
import PrivacyPolicy from "./components/adminSection/CMS/PrivacyPolicy";
import TermsConditions from "./components/adminSection/CMS/TermsConditions";
import Feedback from "./components/adminSection/CMS/Feedback";
import ReportedUsers from "./components/adminSection/ReportedUsers/ReportedUsers";
import CustomNotification from "./components/adminSection/CustomNotification/CustomNotification";
import Overview from "./components/adminSection/profile";
import HomePage from "./components/ApeLabsSection/HomePage/HomePage";
import Product from "./layouts/apeitnowdashboard/pages/Product";
import SEOSetting from "./layouts/apeitnowdashboard/settings/SEOSetting";
import Orders from "./layouts/apeitnowdashboard/orders/Orders";
import SingleOrderScreen from "./layouts/apeitnowdashboard/orders/SingleOrderScreen";
import ApeItNowDashboard from "./ApeItNowPannel/DashBoard/ApeItNowDashboard";
import AllProduct from "./ApeItNowPannel/Product/AllProduct";

import SuperAdminLogin from "./pages/SuperAdminLogin";

import AllCoupon from "./ApeItNowPannel/CoupanManagement/AllCoupon";
import AddCoupon from "./ApeItNowPannel/CoupanManagement/AddCoupon";

import ManageRole from "./sections/@superadmin/role-management/ManageRole";

import AllReviews from "./ApeItNowPannel/ManageReviews/AllReviews";
import SingleReview from "./ApeItNowPannel/ManageReviews/SingleReview";
import UpdateCoupon from "./ApeItNowPannel/CoupanManagement/UpdateCoupon";
import ManagePermissions from "./sections/@superadmin/permission-management/ManagePermissions";
import ManageAdmins from "./sections/@superadmin/admin-management/ManageAdmins";
import ManageStickers from "./components/adminSection/AppCustomization/ManageStickers/ManageStickers";
import ManageAnimations from "./components/adminSection/AppCustomization/AppAnimations/ManageAnimations";
import CouponManagemet from "./components/adminSection/coupon/CouponManagemet";
import ManageShippingCharges from "./ApeItNowPannel/shippingCharges/ManageShippingCharges";
import CustomerList from "./ApeItNowPannel/customerDetail/CustomerList";
import CustomerDetail from "./ApeItNowPannel/customerDetail/CustomerDetail";
import ContactQueries from "./ApeItNowPannel/ContactQueries/ContactQueries";
import CusomeNotificaionMgt from "./ApeItNowPannel/CustomNotificatuionMgt/CusomeNotificaionMgt";
import ApeitnowPrivacyPolicy from "./ApeItNowPannel/ApeitnowCMS/ApeitnowPrivacyPolicy";
import ApeitnowTermsAndConditions from "./ApeItNowPannel/ApeitnowCMS/ApeitnowTermsAndConditions";
import ApeitnowFeedback from "./ApeItNowPannel/ApeitnowCMS/ApeitnowFeedback";
import ApeitnowReturnAndRefund from "./ApeItNowPannel/ApeitnowCMS/ApeitnowReturnAndRefund";
import CustomEmail from "./ApeItNowPannel/CustomEmail/CustomEmails";
import TransactionManagement from "./ApeItNowPannel/TransactionManagement/TransactionManagement";
import ProtectedRoute from "./protectedRoute";
import Carts from "./ApeItNowPannel/ManageCarts/Carts";
import BusinessOnboardingRequests from "./components/adminSection/Business/BusinessOnboardingRequests";
import RegiseteredBusinessess from "./components/adminSection/Business/RegiseteredBusinessess";
import SingleBusinessRequestDetail from "./components/adminSection/Business/SingleBusinessRequestDetail";
import Shippments from "./ApeItNowPannel/DeliveryAndShipments/Shippments";
import Warehouse from "./ApeItNowPannel/DeliveryAndShipments/Warehouse";

// ----------------------------------------------------------------------

// export default function Router() {
//   const routes = useRoutes([
//     {
//       path: "dashboard",
//       element: <DashboardLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/home" /> },
//         {
//           path: "home",
//           element: <DashboardAppPage />,
//         },
//         {
//           path: "role-management",
//           element: <ManageRole />,
//         },
//         // {
//         //   path: "permissions",
//         //   element: <ManagePermissions />,
//         // },
//         {
//           path: "manage-admins",
//           element: <ManageAdmins />,
//         },
//         { path: "user-management", element: <UserManagement /> },
//         {
//           path: "app-customization",
//           children: [
//             {
//               index: true,
//               element: (
//                 <Navigate to="/app-customization/app-ambassador" replace />
//               ),
//             },
//             { path: "app-ambassador", element: <AppAmbassador /> },
//             { path: "manage-avatar", element: <ManageAvatar /> },
//             { path: "manage-badge", element: <ManageBadge /> },
//             { path: "sound-tone", element: <SoundTone /> },
//             { path: "manage-stickers", element: <ManageStickers /> },
//             { path: "manage-animations", element: <ManageAnimations /> },
//             // ManageAnimations
//           ],
//         },
//         { path: "coupon-management", element: <CouponManagemet /> },
//         {
//           path: "cms",
//           children: [
//             { path: "privacy-policy", element: <PrivacyPolicy /> },
//             { path: "terms-conditions", element: <TermsConditions /> },
//             { path: "feedback", element: <Feedback /> },
//           ],
//         },
//         { path: "reported-users", element: <ReportedUsers /> },
//         { path: "custom-notification", element: <CustomNotification /> },
//         { path: "profile", element: <Overview /> },
//         { path: "apelabs", element: <HomePage /> },
//         { path: "apeitnow", element: <ApeItNowDashboard /> },
//         { path: "orders", element: <Orders /> },
//         { path: "orders/view/:orderId", element: <SingleOrderScreen /> },
//         { path: "shipping-charges", element: <ManageShippingCharges /> },
//         { path: "manage-customer", element: <CustomerList /> },
//         { path: "custom-emails", element: <CustomEmail /> },
//         { path: "transactions", element: <TransactionManagement /> },

//         {
//           path: "manage-customer/customer-detail/:customerId",
//           element: <CustomerDetail />,
//         },
//         {
//           path: "manage-custom-notification",
//           element: <CusomeNotificaionMgt />,
//         },

//         {
//           path: "product",
//           children: [
//             { path: "add-product", element: <Product /> },
//             { path: "all-product", element: <AllProduct /> },
//           ],
//         },
//         {
//           path: "couponManagement",
//           children: [
//             { path: "add-coupon", element: <AddCoupon /> },
//             { path: "all-coupon", element: <AllCoupon /> },

//             { path: "update-coupon/:couponId", element: <UpdateCoupon /> },
//           ],
//         },
//         { path: "all-review", element: <AllReviews /> },
//         {
//           path: "all-review/preview/:reviewId",
//           element: <SingleReview />,
//         },
//         { path: "contact-queries", element: <ContactQueries /> },
//         {
//           path: "apeitnow-cms",
//           children: [
//             {
//               index: true,
//               element: (
//                 <Navigate to="/dashboard/apeitnow-cms/privacy-policy" replace />
//               ),
//             },
//             {
//               path: "privacy-policy",
//               element: <ApeitnowPrivacyPolicy />,
//             },
//             {
//               path: "term-and-conditions",
//               element: <ApeitnowTermsAndConditions />,
//             },
//             {
//               path: "return-and-refund",
//               element: <ApeitnowReturnAndRefund />,
//             },
//             // {
//             //   path: "feedback",
//             //   element: <ApeitnowFeedback/>,
//             // },
//           ],
//         },
//         {
//           path: "settings",
//           children: [{ path: "seo-setting", element: <SEOSetting /> }],
//         },
//       ],
//     },
//     {
//       path: "login",
//       index: true,
//       element: <LoginPage />,
//     },
//     {
//       path: "YCBZ1IAz1ZGEUcMmCjrx94b2trlJmjXvqtQ5iFctBlM",
//       element: <SuperAdminLogin />,
//     },

//     // {
//     //   path: "signup",
//     //   element: <SignupPage />,
//     // },
//     {
//       path: "forget",
//       element: <ForgotPassword />,
//     },
//     {
//       path: "resetPassword",
//       element: <ResetPassword />,
//     },
//     {
//       path: "verification",
//       element: <Verification />,
//     },

//     {
//       element: <SimpleLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/home" />, index: true },
//         { path: "404", element: <Page404 /> },
//         { path: "*", element: <Navigate to="/404" /> },
//       ],
//     },
//     {
//       path: "*",
//       element: <Navigate to="/404" replace />,
//     },
//   ]);

//   return routes;
// }

// ---------------------------------------------------------------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" /> },
        {
          path: "home",
          element: (
            <ProtectedRoute slug={["apebuzz-dashboard"]}>
              <DashboardAppPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "role-management",
          element: (
            <ProtectedRoute slug={["superadmin-role_management"]}>
              <ManageRole />
            </ProtectedRoute>
          ),
        },
        {
          path: "manage-admins",
          element: (
            <ProtectedRoute slug={["superadmin-manage_admins"]}>
              <ManageAdmins />
            </ProtectedRoute>
          ),
        },
        {
          path: "user-management",
          element: (
            <ProtectedRoute slug={["apebuzz-user_management"]}>
              <UserManagement />
            </ProtectedRoute>
          ),
        },
        {
          path: "app-customization",
          children: [
            {
              index: true,
              element: (
                <Navigate to="/app-customization/app-ambassador" replace />
              ),
            },
            {
              path: "app-ambassador",
              element: (
                <ProtectedRoute slug={["apebuzz-app_customization"]}>
                  <AppAmbassador />
                </ProtectedRoute>
              ),
            },
            {
              path: "manage-avatar",
              element: (
                <ProtectedRoute slug={["apebuzz-app_customizationr"]}>
                  <ManageAvatar />
                </ProtectedRoute>
              ),
            },
            {
              path: "manage-badge",
              element: (
                <ProtectedRoute slug={["apebuzz-app_customization"]}>
                  <ManageBadge />
                </ProtectedRoute>
              ),
            },
            {
              path: "sound-tone",
              element: (
                <ProtectedRoute slug={["apebuzz-app_customization"]}>
                  <SoundTone />
                </ProtectedRoute>
              ),
            },
            {
              path: "manage-stickers",
              element: (
                <ProtectedRoute slug={["apebuzz-app_customization"]}>
                  <ManageStickers />
                </ProtectedRoute>
              ),
            },
            {
              path: "manage-animations",
              element: (
                <ProtectedRoute slug={["apebuzz-app_customization"]}>
                  <ManageAnimations />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "coupon-management",
          element: (
            <ProtectedRoute slug={["apebuzz-coupon_management"]}>
              <CouponManagemet />
            </ProtectedRoute>
          ),
        },
        {
          path: "business",
          children: [
            {
              path: "onboarding-requests",
              element: (
                <ProtectedRoute slug={["apebuzz-business_onboarding"]}>
                  <BusinessOnboardingRequests />
                </ProtectedRoute>
              ),
            },
            {
              path: "registered-businessess",
              element: (
                <ProtectedRoute slug={["apebuzz-business_onboarding"]}>
                  <RegiseteredBusinessess />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "/dashboard/business/request/:requestId",
          element: (
            <ProtectedRoute slug={["apebuzz-business_onboarding"]}>
              <SingleBusinessRequestDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: "cms",
          children: [
            {
              path: "privacy-policy",
              element: (
                <ProtectedRoute slug={["apebuzz-cms"]}>
                  <PrivacyPolicy />
                </ProtectedRoute>
              ),
            },
            {
              path: "terms-conditions",
              element: (
                <ProtectedRoute slug={["apebuzz-cms"]}>
                  <TermsConditions />
                </ProtectedRoute>
              ),
            },
            {
              path: "feedback",
              element: (
                <ProtectedRoute slug={["apebuzz-cms"]}>
                  <Feedback />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "reported-users",
          element: (
            <ProtectedRoute slug={["apebuzz-reported_user"]}>
              <ReportedUsers />
            </ProtectedRoute>
          ),
        },
        {
          path: "custom-notification",
          element: (
            <ProtectedRoute slug={["apebuzz-custom_notification"]}>
              <CustomNotification />
            </ProtectedRoute>
          ),
        },
        {
          path: "profile",
          element: (
            <Overview />
            // </ProtectedRoute>
          ),
        },
        {
          path: "apelabs",
          element: (
            <ProtectedRoute slug={["apelabs-home"]}>
              <HomePage />
            </ProtectedRoute>
          ),
        },
        {
          path: "apeitnow",
          element: (
            <ProtectedRoute slug={["apeitnow-dashboard"]}>
              <ApeItNowDashboard />
            </ProtectedRoute>
          ),
        },
        {
          path: "orders",
          element: (
            <ProtectedRoute slug={["apeitnow-order_management"]}>
              <Orders />
            </ProtectedRoute>
          ),
        },
        {
          path: "orders/view/:orderId",
          element: (
            <ProtectedRoute slug={["apeitnow-order_management"]}>
              <SingleOrderScreen />
            </ProtectedRoute>
          ),
        },
        {
          path: "shipping-charges",
          element: (
            <ProtectedRoute slug={["apeitnow-shipping_charges"]}>
              <ManageShippingCharges />
            </ProtectedRoute>
          ),
        },
        {
          path: "delivery-management",
          children: [
            {
              index: true,
              element: (
                <Navigate to="/dashboard/delivery-management/shipments" />
              ),
            },
            {
              path: "/dashboard/delivery-management/shipments",
              element: (
                <ProtectedRoute slug={["apeitnow-shipping_charges"]}>
                  <Shippments/>
                </ProtectedRoute>
              ),
            },
            {
              path: "/dashboard/delivery-management/warehouse",
              element: (
                <ProtectedRoute slug={["apeitnow-shipping_charges"]}>
                  <Warehouse/>
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "manage-customer",
          element: (
            <ProtectedRoute slug={["apeitnow-manage_customer"]}>
              <CustomerList />
            </ProtectedRoute>
          ),
        },
        {
          path: "manage-carts",
          element: (
            <ProtectedRoute slug={["apeitnow-manage_carts"]}>
              <Carts />
            </ProtectedRoute>
          ),
        },
        {
          path: "manage-custom-notification",
          element: (
            <ProtectedRoute slug={["apeitnow-manage_custom_notification"]}>
              <CusomeNotificaionMgt />
            </ProtectedRoute>
          ),
        },
        {
          path: "apeitnow-cms",
          children: [
            {
              path: "privacy-policy",
              element: (
                <ProtectedRoute slug={["apeitnow-cms"]}>
                  <ApeitnowPrivacyPolicy />
                </ProtectedRoute>
              ),
            },
            {
              path: "terms-and-conditions",
              element: (
                <ProtectedRoute slug={["apeitnow-cms"]}>
                  <ApeitnowTermsAndConditions />
                </ProtectedRoute>
              ),
            },
            {
              path: "return-and-refund",
              element: (
                <ProtectedRoute slug={["apeitnow-cms"]}>
                  <ApeitnowReturnAndRefund />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "custom-emails",
          element: (
            <ProtectedRoute slug={["apeitnow-custom-emails"]}>
              <CustomEmail />
            </ProtectedRoute>
          ),
        },
        {
          path: "transactions",
          element: (
            <ProtectedRoute slug={["apeitnow-transactions_management"]}>
              <TransactionManagement />
            </ProtectedRoute>
          ),
        },
        {
          path: "contact-queries",
          element: (
            <ProtectedRoute slug={["apeitnow-contact_queries"]}>
              <ContactQueries />
            </ProtectedRoute>
          ),
        },
        {
          path: "settings",
          children: [
            {
              path: "seo-setting",
              element: (
                <ProtectedRoute slug={["apeitnow-seo"]}>
                  <SEOSetting />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "manage-customer/customer-detail/:customerId",
          element: (
            <ProtectedRoute slug={["apeitnow-customer_management"]}>
              <CustomerDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: "product",
          children: [
            {
              path: "add-product",
              element: (
                <ProtectedRoute slug={["apeitnow-product_management"]}>
                  <Product />
                </ProtectedRoute>
              ),
            },
            {
              path: "all-product",
              element: (
                <ProtectedRoute slug={["apeitnow-product_management"]}>
                  <AllProduct />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "review-management",
          children: [
            {
              path: "all-reviews",
              element: (
                <ProtectedRoute
                  slug={["apeitnow-reviews_and_rating_management"]}
                >
                  <AllReviews />
                </ProtectedRoute>
              ),
            },
            {
              path: "single-review/:reviewId",
              element: (
                <ProtectedRoute
                  slug={["apeitnow-reviews_and_rating_management"]}
                >
                  <SingleReview />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "coupon-management",
          children: [
            {
              path: "add-coupon",
              element: (
                <ProtectedRoute slug={["apeitnow-coupon_management"]}>
                  <AddCoupon />
                </ProtectedRoute>
              ),
            },
            {
              path: "update-coupon/:couponId",
              element: (
                <ProtectedRoute slug={["apeitnow-coupon_management"]}>
                  <UpdateCoupon />
                </ProtectedRoute>
              ),
            },
            {
              path: "all-coupons",
              element: (
                <ProtectedRoute slug={["apeitnow-coupon_management"]}>
                  <AllCoupon />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "superadmin",
          element: <SuperAdminLogin />,
        },
      ],
    },
    {
      path: "login",
      index: true,
      element: <LoginPage />,
    },
    {
      path: "/",
      index: true,
      element: <LoginPage />,
    },
    {
      path: "forget-password",
      element: <ForgotPassword />,
    },
    {
      path: "verification",
      element: <Verification />,
    },
    {
      path: "reset-password/:token",
      element: <ResetPassword />,
    },
    {
      path: "*",
      element: <Page404 />,
    },
  ]);

  return routes;
}

// ----------------------------------------------------------------------
