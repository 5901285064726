import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPanel: ["apebuzz", "apelabs", "apeitnow", "superadmin"],
};
const adminPanelSlice = createSlice({
  name: "panel",
  initialState,
  reducers: {
    selectPanel: (state, action) => {
      state.selectedPanel = action.payload;
    },
  },
});

export const { selectPanel } = adminPanelSlice.actions;
export default adminPanelSlice.reducer;
