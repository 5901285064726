import { useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import HTMLReactParser from "html-react-parser";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import SitemapForm from "./seo/SitemapForm";
import RobotsForm from "./seo/RobotsForm";
import SEOSettingsForm from "./seo/SEOSettingsForm";
import { getErrorMessage } from "../../../reusablecomponents/CustomError/errorMessages";
import { apeitnowBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";

const SEOSetting = () => {
  const dispatch = useDispatch();
  const [isFormChanged, setIsFormChanged] = useState(false);
  // Fetch initial SEO data from the server (for demonstration purposes)
  const initialSEOData = {
    metaTitle: "Example Title",
    metaDescription: "Example description for SEO.",
    metaKeywords: "example, seo, keywords",
    metaImage: "https://example.com/preview-image.jpg", // Example image URL
  };

  const handleSEOFormSubmit = async (seoData, getGlobelSeoSettings) => {
    dispatch(showLoader());
    const formData = new FormData();
    formData.append(`title`, seoData.metaTitle);
    formData.append(`description`, seoData.metaDescription);
    formData.append(`keyword`, seoData.metaKeywords);
    formData.append(`image`, seoData.metaImage);
    try {
      const res = await api.put(
        `${apeitnowBaseURL}/api/v1/apeitnow/settings/update/seo`,
        formData,
      );

      dispatch(hideLoader());
      getGlobelSeoSettings();
      if (res.data.success) {
        dispatch(
          showSnackbar({
            message: "SEO setting has been updated successfully !",
            severity: "success",
          }),
        );
        setIsFormChanged(false);
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };

  const handleRobotsSubmit = async (robotsData, disallow, getRobotsData) => {
    dispatch(showLoader());

    try {
      const res = await api.post(
        `${apeitnowBaseURL}/api/v1/apeitnow/settings/write-file/robot-sitemap-data?scope=robot`,
        {
          useragent: robotsData.useragent,
          sitemap: robotsData.sitemap,
          disallow,
        },
      );
      console.log("update res", res);

      getRobotsData();
      dispatch(hideLoader());
      if (res?.statusText === "OK") {
        dispatch(
          showSnackbar({
            message: res?.data?.message,
            severity: "success",
          }),
        );
        setIsFormChanged(false);
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };

  const handleSitemapSubmit = async (sitemapData, getSitemapData) => {
    console.log("sitemapData", sitemapData);
    dispatch(showLoader());
    try {
      const res = await api.post(
        `${apeitnowBaseURL}/api/v1/apeitnow/settings/write-file/robot-sitemap-data?scope=sitemap`,
        JSON.stringify(sitemapData),
      );
      console.log("update res", res);

      getSitemapData();
      dispatch(hideLoader());
      if (res?.statusText === "OK") {
        dispatch(
          showSnackbar({
            message: res?.data?.message,
            severity: "success",
          }),
        );
        setIsFormChanged(false);
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };

  return (
    <Box padding={4} component={Paper}>
      <Typography variant="h4" gutterBottom>
        Admin Panel: Manage Seo Settings, robots.txt & sitemap.xml
      </Typography>

      <Box marginBottom={4}>
        <Typography variant="h5">Manage Global SEO settings</Typography>
        {/* SEO Settings Form */}
        <SEOSettingsForm
          onSubmit={handleSEOFormSubmit}
          initialData={initialSEOData}
          setIsFormChanged={setIsFormChanged}
          isFormChanged={isFormChanged}
        />
      </Box>
      <Box marginBottom={4}>
        <Typography variant="h5">Manage robots.txt</Typography>
        <RobotsForm onSubmit={handleRobotsSubmit} />
      </Box>

      <Box marginBottom={4}>
        <Typography variant="h5">Manage sitemap.xml</Typography>
        <SitemapForm onSubmit={handleSitemapSubmit} />
      </Box>
    </Box>
  );
};

export default SEOSetting;
