import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { apecenterBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

const ManageAdmins = () => {
  const actionDispatcher = useDispatch();
  const [error, seterror] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [username, setusername] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [allCreatedAdmins, setAllCreatedAdmins] = useState([]);

  const handleRoleChange = (event, newValue) => {
    // Set the selected roles as an array of _id values
    setSelectedRoles(newValue.map((role) => role._id));
  };

  const getAllRoles = async () => {
    actionDispatcher(isLoading(true));
    try {
      const res = await api.get(
        `${apecenterBaseURL}/api/v1/ape-control-center/superadmin/roles/get/all`,
      );
      console.log("res- jj-->", res.data.data);
      setAllRoles(res.data.data);
    } catch (error) {
      actionDispatcher(isLoading(false));
      console.log("error=>", error);
      actionDispatcher(openSnackbar("error", "something went wrong"));
    }
  };

  console.log("selected roles==>", selectedRoles);

  // create sub admin
  const handleCreateSubAdmin = async () => {
    actionDispatcher(isLoading(true));
    if (!name || !email || !username || !password || !phone) {
      actionDispatcher(
        openSnackbar("warning", "required fields can not be empty"),
      );
      return;
    }
    if (!selectedRoles.length > 0) {
      actionDispatcher(
        openSnackbar("warning", "pleaes select at least one role"),
      );
      return;
    }
    try {
      const res = await api.post(
        `${apecenterBaseURL}/api/v1/ape-control-center/superadmin/create/sub-admin`,
        {
          name,
          email,
          password,
          username,
          roles: selectedRoles,
          phone,
        },
      );
      console.log("res==>", res);
      setSelectedRoles([]);
      setPhone("");
      setEmail("");
      setName("");
      setusername("");
      setPassword("");
      getAllAdmins();
    } catch (error) {
      actionDispatcher(isLoading(false));
      console.log("error=>", error);
    }
  };

  // get all created admins
  const getAllAdmins = async () => {
    actionDispatcher(isLoading(true));
    try {
      const res = await api.get(
        `${apecenterBaseURL}/api/v1/ape-control-center/superadmin//get/all/sub-admin`,
      );
      console.log("res- admin-->", res.data.data);
      setAllCreatedAdmins(res.data.data);
      actionDispatcher(isLoading(false));
    } catch (error) {
      actionDispatcher(isLoading(false));
      console.log("error=>", error);
      actionDispatcher(openSnackbar("error", "something went wrong"));
    }
  };

  useEffect(() => {
    getAllRoles();
    getAllAdmins();
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Manage Admins
      </Typography>

      {/* Permission Creation Form */}
      <Card sx={{ marginTop: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Create Admin
          </Typography>
          <Stack
            direction={{ md: "row", xs: "column" }}
            columnGap={2}
            rowGap={2}
          >
            <TextField
              label="Full Name"
              variant="outlined"
              fullWidth
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              helperText={error}
              margin="normal"
            />
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              name="username"
              value={username}
              onChange={(e) => setusername(e.target.value)}
              helperText={error}
              margin="normal"
            />
          </Stack>
          <Stack
            direction={{ md: "row", xs: "column" }}
            columnGap={2}
            rowGap={2}
          >
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={""}
              margin="normal"
              sx={{ marginTop: 2 }}
            />
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              type="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              helperText={""}
              margin="normal"
              sx={{ marginTop: 2 }}
            />
          </Stack>
          <Stack
            direction={{ md: "row", xs: "column" }}
            columnGap={2}
            rowGap={2}
          >
            <TextField
              label="Login Password"
              variant="outlined"
              fullWidth
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText={""}
              margin="normal"
              sx={{ marginTop: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="About Admin"
              variant="outlined"
              fullWidth
              //   value={permissionDescription}
              //   onChange={(e) => setPermissionDescription(e.target.value)}
              helperText={error}
              margin="normal"
            />
          </Stack>
          <Autocomplete
            multiple
            fullWidth
            sx={{ mt: 2 }}
            id="tags-outlined"
            options={allRoles}
            getOptionLabel={(option) => option?.name} // Display the name
            value={allRoles.filter((role) => selectedRoles.includes(role._id))} // Display the selected roles by their name
            onChange={handleRoleChange} // Handle changes to the selection
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Roles"
                placeholder="Favorites"
              />
            )}
          />
          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={handleCreateSubAdmin}
          >
            Add Admin
          </Button>
        </CardContent>
      </Card>

      {/* Display Created Permissions in a Table */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h4" gutterBottom>
          All Admins
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Admin Name</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Assigned Roles</TableCell>
                <TableCell>App Access</TableCell>
                {/* <TableCell>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {allCreatedAdmins &&
                allCreatedAdmins?.map((admin) => (
                  <TableRow key={admin._id}>
                    <TableCell align="center">{admin?.name}</TableCell>
                    <TableCell>{admin?.username}</TableCell>
                    <TableCell>{admin?.email || "N/A"}</TableCell>
                    <TableCell>{admin?.phone || "N/A"}</TableCell>
                    <TableCell>
                      {(
                        admin?.roles && admin?.roles?.map((role) => role.name)
                      ).join(",")}
                    </TableCell>
                    <TableCell>
                      {(
                        admin?.apps && admin?.apps?.map((app) => app.name)
                      ).join(",")}
                    </TableCell>
                    {/* <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeletePermission(permission._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default ManageAdmins;
