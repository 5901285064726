import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  Box,
  FormControl,
  Input,
  Stack,
  InputLabel,
  Paper,
  IconButton,
  InputAdornment,
} from "@mui/material";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import ModalComponent from "../../../reusablecomponents/ModalComponent/ModalComponent";
import { getErrorMessage } from "../../../reusablecomponents/CustomError/errorMessages";
import { apebuzzBaseURL, apecenterBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";

const ChangePassword = ({ open, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordCon, setShowPasswordCon] = useState(false);

  const [formData, setFormData] = useState({
    oldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  });
  const handleSave = async (e) => {
    e.preventDefault();
    dispatch(showLoader());
    try {
      // const res = await api.put(`${apebuzzBaseURL}/api/auth/adminAuth/change/password`, {
      const res = await api.put(
        `${apecenterBaseURL}/api/v1/ape-control-center/subadmin/change-password`,
        {
          oldPassword: formData.oldPassword,
          password: formData.NewPassword,
          confirmPassword: formData.ConfirmPassword,
        },
      );
      console.log("res", res);
      if (res?.data.success) {
        dispatch(hideLoader());
        dispatch(
          showSnackbar({
            message: `password updated successfully !`,
            severity: "success",
          }),
        );
        handleCloseModal();
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  return (
    <ModalComponent open={open} handleClose={handleCloseModal}>
      <Box width={360} p={3}>
        <Grid container spacing={2} component={"form"} onSubmit={handleSave}>
          <Grid item xs={12}>
            <Typography sx={{ color: "rgba(121, 116, 126, 1)" }}>
              Old Password
            </Typography>
            <Box display="flex" alignItems="center" position={"relative"}>
              <TextField
                type={showPasswordOld ? "text" : "password"}
                variant="standard"
                name="password"
                placeholder=". . . . . . . . . . ."
                fullWidth
                required
                value={formData.oldPassword}
                onChange={(e) =>
                  setFormData({ ...formData, oldPassword: e.target.value })
                }
                // browser default hide unhide feature is working
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPasswordOld(!showPasswordOld)}
                        onMouseDown={(e) => e.preventDefault()}
                        aria-label="toggle password visibility"
                      >
                        {showPasswordOld ? (
                          <RemoveRedEyeRoundedIcon />
                        ) : (
                          <VisibilityOffRoundedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <TextField
                            fullWidth
                            variant="standard"
                            value={formData.oldPassword}
                            onChange={(e) => setFormData({ ...formData, oldPassword: e.target.value })}
                        /> */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: "rgba(121, 116, 126, 1)" }}>
              New Password
            </Typography>
            <Box display="flex" alignItems="center" position={"relative"}>
              <TextField
                type={showPasswordNew ? "text" : "password"}
                variant="standard"
                name="password"
                placeholder=". . . . . . . . . . ."
                fullWidth
                required
                value={formData.NewPassword}
                onChange={(e) =>
                  setFormData({ ...formData, NewPassword: e.target.value })
                }
                // browser default hide unhide feature is working
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPasswordNew(!showPasswordNew)}
                        onMouseDown={(e) => e.preventDefault()}
                        aria-label="toggle password visibility"
                      >
                        {showPasswordNew ? (
                          <RemoveRedEyeRoundedIcon />
                        ) : (
                          <VisibilityOffRoundedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <TextField
                            fullWidth
                            variant="standard"
                            value={formData.NewPassword}
                            onChange={(e) => setFormData({ ...formData, NewPassword: e.target.value })}
                        /> */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: "rgba(121, 116, 126, 1)" }}>
              Confirm Password
            </Typography>

            <Box display="flex" alignItems="center" position={"relative"}>
              <TextField
                type={showPasswordCon ? "text" : "password"}
                variant="standard"
                name="password"
                placeholder=". . . . . . . . . . ."
                fullWidth
                required
                value={formData.ConfirmPassword}
                onChange={(e) =>
                  setFormData({ ...formData, ConfirmPassword: e.target.value })
                }
                // browser default hide unhide feature is working
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPasswordCon(!showPasswordCon)}
                        onMouseDown={(e) => e.preventDefault()}
                        aria-label="toggle password visibility"
                      >
                        {showPasswordCon ? (
                          <RemoveRedEyeRoundedIcon />
                        ) : (
                          <VisibilityOffRoundedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <TextField
                            fullWidth
                            variant="standard"
                            value={formData.ConfirmPassword}
                            onChange={(e) => setFormData({ ...formData, ConfirmPassword: e.target.value })}
                        /> */}
            </Box>
            {formData.ConfirmPassword !== formData.NewPassword && (
              <span style={{ fontSize: "12px", color: "red" }}>
                confirm password is not same**
              </span>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="medium"
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(82, 118, 10, 1)",
                  boxShadow: "none",
                  color: "white",
                },
              }}
            >
              Reset Password
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalComponent>
  );
};
ChangePassword.propTypes = {
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};
export default ChangePassword;
