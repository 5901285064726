import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, TextField, Typography } from "@mui/material";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { apeitnowBaseURL } from "../../../../utils/url";
import api from "../../../../utils/axios";

const cleanSitemapData = (data) => {
  // Remove unwanted HTML-encoded characters
  let cleanedData = data
    .replace(/gt;/g, ">")
    .replace(/lt;/g, "<")
    .replace(/&quot;/g, '"')
    .replace(/\s+/g, " ")
    .trim();

  // Basic clean-up of empty strings and malformed tags
  cleanedData = cleanedData
    .replace(/<\/?div>/g, "")
    .replace(/<\/?path>/g, "")
    .replace(/\(\d+\)\s*\[['"]{0,}/g, "")
    .replace(/\]{0,}["']/g, "")
    .replace(/<\/?urlsetxmlns>/g, "");

  // This might need to be more specific depending on the actual data structure
  return cleanedData;
};

const SitemapForm = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const [isFormChanged, setIsFormChanged] = useState(true);

  const [sitemapContent, setSitemapContent] = useState("");
  const getSitemapData = async () => {
    dispatch(showLoader());
    try {
      const res = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/settings/get/file-data?scope=sitemap`,
      );
      setSitemapContent(res?.data);
      // setSitemapContent(typeof res?.dataS === 'string' ? res?.data : JSON.stringify(res?.data));
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getSitemapData();
  }, []);
  const handleSitemapChange = (content) => {
    console.log("on change sitemapContent ", content);

    setSitemapContent(content);
  };

  const handleSubmit = () => {
    // const sitemapData = { content: sitemapContent };
    onSubmit(sitemapContent, getSitemapData);
  };
  const escapeXml = (unsafe) => {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  };
  console.log("sitemapContent", sitemapContent);
  return (
    <Box>
      <Typography variant="h5">Edit sitemap.xml</Typography>
      <Box py={2}>
        <TextField
          label="Sitemap Content"
          value={sitemapContent}
          multiline
          rows={15}
          fullWidth
          variant="outlined"
          onChange={(e) => setSitemapContent(e.target.value)}
        />
      </Box>

      {/* <SunEditor
        setContents={sitemapContent}
        onChange={handleSitemapChange}
        setOptions={{
          height: 200,

          buttonList: [
            ["undo", "redo"],
            ["codeView"], // Enable code view for XML editing
          ],
          mode: "classic",
          codeViewKeepActive: true,
        }}
        height="400px"
      /> */}

      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Update sitemap.xml
      </Button>
    </Box>
  );
};

SitemapForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SitemapForm;
