import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

import Iconify from "../../components/iconify";

const CustomerProfileCard = ({ data }) => {
  return (
    <Box
      width={"100%"}
      elevation={2}
      maxWidth={"360px"}
      p={0.85}
      component={Paper}
      borderRadius={"8px"}
      minHeight={"250px"}
    >
      <Box
        bgcolor={"#61B15A"}
        sx={{ position: "relative" }}
        minHeight={"102px"}
        width={"100%"}
        borderRadius={"0.25rem"}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 2,
            left: "50%",
            height: "8.25rem",
            width: "8.25rem",
            borderRadius: "50%",
            bgcolor: "lightgray",
            border: "5px solid white",
            // boxShadow: "0px 5px 20px -5px #c9c1c1",
            translate: "-50% 50%",
            justifySelf: "center",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            zIndex: 2,
          }}
        >
          <img
            src={data?.detail?.account?.profilePicture?.imageUrl}
            alt={data?.detail?.account?.name}
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        </Box>
      </Box>
      <Box
        bgcolor={"transparent"}
        minHeight={"102px"}
        width={"100%"}
        display={"flex"}
        px={2}
        py={0.25}
        flexDirection={"column"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Typography
          textTransform={"capitalize"}
          variant="body1"
          component={"p"}
        >
          {data?.detail?.account?.name
            ? data?.detail?.account?.name
            : "Anonymous"}
        </Typography>
        <Box
          sx={{
            mt: 0.25,
            backgroundColor: "#E0E2E7",
            height: "1px",
            width: "100%",
          }}
        />
      </Box>
      <Stack>
        <List
          sx={{ width: "100%", maxWidth: "100%", bgcolor: "transparent" }}
          dense
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#E0E2E7" }}>
                {/* <ImageIcon sx={{ color: "#667085" }} /> */}
                <Iconify icon="oui:email" color={"#667085"} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Email" secondary={data?.detail?.email} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#E0E2E7" }}>
                <Iconify icon="carbon:phone" color={"#667085"} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Phone Number"
              secondary={data?.detail?.phone}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#FAE1CF" }}>
                <Iconify icon="proicons:cart" color={"#E46A11"} />
                {/* <BeachAccessIcon sx={{ color: "#E46A11" }} /> */}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Total Orders"
              secondary={data?.totalOrders ? data?.totalOrders : 0}
            />
          </ListItem>
        </List>
      </Stack>
    </Box>
  );
};

export default CustomerProfileCard;
