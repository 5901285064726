import React from "react";
import { Box, Grid, IconButton, Paper } from "@mui/material";
import {
  CloudUpload as UploadIcon,
  Delete as TrashIcon,
  CheckBox,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { setAllVarientImages, setVarientImages } from "../../../ReduxToolkit/Slices/imageSlice";
import ImageLoader from "./image/ImageLoader";

const VarientImageContainer = ({ img, setDisplayProductImage, tab }) => {
  const dispatch = useDispatch();
  const removeFile = (indexToDelete) => {
    const updatedImages = [...img];
    updatedImages.splice(indexToDelete, 1);
    // setDisplayProductImage(updatedImages);
    dispatch(setAllVarientImages(updatedImages));
  };
  console.log("image", img);
  return (
    <Grid container spacing={2}>
      {img?.lenght !== 0 &&
        img?.map((file, index) => (
          <Grid item key={file.file.media.name + index}>
            <Paper
              sx={{
                padding: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                boxShadow: 1,
                position: "relative",
              }}
            >
              <ImageLoader
                src={file.file.media.url}
                alt={file.file.media.name}
                width={100}
                height={100}
              />
              <Box sx={{ position: "absolute", top: 0, right: 5 }}>
                <IconButton color="error" onClick={() => removeFile(index)}>
                  <CloseIcon sx={{ color: "#61B15A" }} />
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        ))}
    </Grid>
  );
};

export default VarientImageContainer;
