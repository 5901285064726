import { useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
// @mui
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, List, ListItemText, Stack } from "@mui/material";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import SidenavCollapse from "./SidenavCollapse";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  apeIt: PropTypes.array,
};

export default function NavSection({ data = [], apeIt, ...other }) {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Box {...other}>
      <List sx={{ p: 1 }} >
        {data.map((item, dataindex) => {
          return (
            <NavItem
              key={dataindex}
              item={item}
              dataindex={dataindex}
              openIndex={openIndex}
              handleToggle={handleToggle}
            />
          );
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  dataindex: PropTypes.number,
  openIndex: PropTypes.number,
  handleToggle: PropTypes.func,
};

function NavItem({ item, dataindex, openIndex, handleToggle }) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { title, path, icon, info, type, subRoutes, key } = item;
  const isOpen = openIndex === dataindex;
  const currentPath = pathName.replace("/dashboard/", "");
  const isActive = (path) =>
    currentPath.startsWith(path.replace("/dashboard/", ""));

  const handleClick = () => {

    if (type === "collapse") {
      handleToggle(dataindex);
    } else {
      navigate(path);
    }
  };

  return (
    <>
      {type === "collapse" ? (
        <StyledNavItem
          // component={RouterLink}
          onClick={handleClick}
          // to={path}
          sx={{
            backgroundColor: isActive(path) ? "rgba(207, 237, 204, 1)" : "",
            color: isActive(path) ? "rgba(97, 177, 90, 1)" : "",
            transition: "all .5s ease-in-out",
            // "&.active": {
            //   color: "rgba(97, 177, 90, 1)",
            //   bgcolor: "rgba(207, 237, 204, 1)",
            //   fontWeight: "fontWeightBold",
            //   "& .box": {
            //     backgroundColor: "transparent",
            //   },
            // },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

          <ListItemText disableTypography primary={title} />
          {type === "collapse" ? (
            isOpen ? (
              <ExpandLess sx={{ transform: "rotate(180deg)" }} />
            ) : (
              <ExpandMore sx={{ transform: "rotate(270deg)" }} />
            )
          ) : (
            ""
          )}
        </StyledNavItem>
      ) : (
        <StyledNavItem
          component={RouterLink}
          onClick={handleClick}
          to={path}
          sx={{
            "&.active": {
              color: "rgba(97, 177, 90, 1)",
              bgcolor: "rgba(207, 237, 204, 1)",
              fontWeight: "fontWeightBold",
              "& .box": {
                backgroundColor: "transparent",
              },
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

          <ListItemText disableTypography primary={title} />
          {type === "collapse" ? (
            isOpen ? (
              <ExpandLess sx={{ transform: "rotate(180deg)" }} />
            ) : (
              <ExpandMore sx={{ transform: "rotate(270deg)" }} />
            )
          ) : (
            ""
          )}
        </StyledNavItem>
      )}
      {type === "collapse" ? (
        <Stack spacing={4} gap={1}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {subRoutes.map((subItem, index) => {
              return (
                <StyledNavItem
                  className="subroutes"
                  key={index}
                  sx={{
                    textAlign: "center",
                    justifySelf: "center",
                    width: "90%",
                    border: "1px solid rgba(97, 177, 90, 1)",
                    height: "40px",
                    color:
                      pathName.includes(subItem.path) && "rgba(97, 177, 90, 1)",
                    bgcolor:
                      pathName.includes(subItem.path) &&
                      "rgba(207, 237, 204, 1)",
                  }}
                  onClick={() => {
                    navigate(subItem.path);
                  }}
                >
                  <ListItemText disableTypography primary={subItem.name} />
                </StyledNavItem>
              );
            })}
          </Collapse>
        </Stack>
      ) : (
        ""
      )}
    </>
  );
}
