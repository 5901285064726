import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import SemiCircleGaugeChart from "./components/SemiCircleGaugeChart";
import TopSellingProductTable from "./components/TopSellingProductTable";

const SaleProgress = ({ progressData, topSellingProducts }) => {
  return (
    <Box width={"100%"}>
      <Grid
        container
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"start"}
        flexGrow={"wrap"}
        height={"400px"}
      >
        <Grid height={"100%"} item xs={12} sm={5.85} component={Paper} p={2}>
          <Typography variant="body1" component={"div"}>
            Sales Progress
            <Typography variant="subtitle1" component={"p"}>
              This Quarter
            </Typography>
          </Typography>
          <SemiCircleGaugeChart data={progressData} />
          <Stack textAlign={"center"}>
            <Typography variant="body2" component={"p"}>
              {/* You succeed earn{" "} */}
              {/* <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Rs240
              </span>{" "} */}
              {/* today, its higher than yesterday */}
              Sale is an art of business, your evaluation is your sale
            </Typography>
          </Stack>
        </Grid>
        <Grid height={"100%"} item xs={12} sm={5.85} component={Paper} p={2}>
          <TopSellingProductTable data={topSellingProducts} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SaleProgress;
