import React, { useState } from "react";
import {
  Box,
  Stack,
  Avatar,
  Grid,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import DeleteModal from "../../../reusablecomponents/DeleteModal/DeleteModal";
import { apebuzzBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";

const PendingActionBox = ({ data, getAllUsers }) => {
  const dispatch = useDispatch();
  const [showFullText, setShowFullText] = useState(false);
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });

  const changeStatusHandler = async (id) => {
    dispatch(showLoader());
    try {
      const res = await api.put(
        `${apebuzzBaseURL}/api/auth/adminAuth/update/action/on/reported/user`,
        {
          reportId: id,
          actionOnReport:
            dialog.buttonText === "Deactivate" ? "deactive" : "ignore",
        },
      );
      if (res?.data.success) {
        dispatch(hideLoader());
        dispatch(
          showSnackbar({
            message: `User ${
              dialog.buttonText === "Deactivate" ? "deactivated" : "ignored"
            } Successfully!`,
            severity: "success",
          }),
        );
        getAllUsers();
        handleClose();
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  const handleClose = () => {
    setDialog({
      open: false,
      title: "",
      buttonText: "",
      data: {
        id: null,
      },
    });
  };
  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={10}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        borderBottom: "1px solid lightgray",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={data?.reported?.profilePicture?.imageUrl}
          sx={{ width: "50px", height: "50px" }}
        />
        <Stack ml={3}>
          <Typography sx={{ fontSize: "20px" }}>
            {data.reported ? data.reported.name : "N/A"}
          </Typography>
          <Typography
            sx={{
              color: "rgba(106, 106, 106, 1)",
              fontSize: "14.25px",
              fontWeight: 350,
            }}
          >{`Reported By ${
            data.reportedBy ? data.reportedBy.name : "N/A"
          }`}</Typography>
          <Typography
            sx={{
              color: "rgba(106, 106, 106, 1)",
              fontSize: "14.25px",
              fontWeight: 350,
            }}
          >{`Reason : ${data.reason ? data.reason : "N/A"}`}</Typography>
          {/* <Box sx={{ width: "500px" }}>
            {`${data.description}`.length >= 120 ? (
              <Typography fontSize="13px">
                {showFullText
                  ? data.description
                  : data.description.slice(0, 150)}
                ...
                <Typography
                  onClick={() => setShowFullText(!showFullText)}
                  style={{
                    cursor: "pointer",
                    color: "rgba(106, 106, 106, 1)",
                    fontSize: "13.25px",
                    fontWeight: 400,
                  }}
                >
                  {showFullText ? " Read less" : " Read more"}
                </Typography>
              </Typography>
            ) : (
              <Typography fontSize="13px">
                {data.reason ? data.reason : "N/A"}
              </Typography>
            )}
          </Box> */}
        </Stack>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Button
          size="medium"
          variant="contained"
          color="secondary"
          sx={{
            "&:hover": {
              backgroundColor: "rgba(82, 118, 10, 1)",
              boxShadow: "none",
              color: "white",
            },
          }}
          onClick={() =>
            setDialog({
              open: true,
              title: "Are you sure you want to deactivate the user?",
              buttonText: "Deactivate",
              data: {
                id: null,
              },
            })
          }
        >
          Deactivate User
        </Button>
        <Button
          size="medium"
          variant="outlined"
          color="secondary"
          sx={{
            ml: 2,
            // "&:hover": {
            //     backgroundColor: "rgba(82, 118, 10, 1)",
            //     boxShadow: "none",
            //     color: "white",
            // },
          }}
          onClick={() =>
            setDialog({
              open: true,
              title: "Are you sure you want to ignore the user?",
              buttonText: "Ignore",
              data: {
                id: null,
              },
            })
          }
        >
          Ignore
        </Button>
      </Box>
      {dialog.open && (
        <DeleteModal
          open={dialog.open}
          handleClose={handleClose}
          dialog={dialog}
          deleteFunc={() => changeStatusHandler(data._id)}
        />
      )}
    </Grid>
  );
};
PendingActionBox.propTypes = {
  data: PropTypes.array,
  getAllUsers: PropTypes.func,
};
export default PendingActionBox;
