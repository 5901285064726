import * as React from "react";
import { Pagination, Stack, Typography, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";

const ProductTable = ({
  rows,
  columns,
  CustomToolbar,
  changePage,
  pageInfo,
}) => {
  const calculateHeight = (height) => {
    switch (height) {
      case 1:
        return height * 250;
      case 5:
        return 440;
      case 6:
        return 480;
      case 10:
        return 720;
      case 20:
        return 1200;
      case 30:
        return 1710;
      case 50:
        return 2610;
      case 100:
        return 4010;
      default:
        return height * 54;
    }
  };
  const getHeight = calculateHeight(pageInfo.count);

  return (
    <Box
      sx={{
        width: "100%",
        height: "450px",
      }}
    >
      <DataGrid
        rows={rows}
        getRowId={(row) => row._id}
        columns={columns}
        hideFooterPagination
        hideFooter
        slots={{
          toolbar: CustomToolbar,
        }}
        sx={{
          width: "fit-content",
          minWidth: "100%",
          fontWeight: 400,
          color: "#344767",
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
            {
              outline: "none",
            },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#F4F6F8 !important",
            color: "black",
            position: "sticky",
            top: 0,
            zIndex: 1,
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            color: "rgba(102, 102, 102, 1)",
            fontSize: "16px",
          },
        }}
        disableColumnMenu
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography sx={{ fontSize: "14.36px" }}>{`Showing ${
          (pageInfo?.page - 1) * pageInfo?.limitPerPage + 1
        } to ${Math.min(
          pageInfo?.page * pageInfo?.limitPerPage,
          pageInfo?.totalProducts,
        )} of ${pageInfo?.totalProducts} entries`}</Typography>

        <Pagination
          count={pageInfo?.totalPages}
          variant="outlined"
          shape="rounded"
          sx={{
            float: "right",
            m: 2,
            "&.MuiPagination-outlined": {
              ".Mui-selected": {
                backgroundColor: "rgba(207, 237, 204, 1)",
                color: "rgba(97, 177, 90, 1)",
              },
            },
          }}
          onChange={(e, page) => changePage(page)}
        />
      </Stack>
    </Box>
  );
};
ProductTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  CustomToolbar: PropTypes.func,
  changePage: PropTypes.func,
  pageInfo: PropTypes.object,
};
export default ProductTable;
