import React, { useState, useEffect } from "react";
import {
  Box,
  CardMedia,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Paper,
  Avatar,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../../ReduxToolkit/Slices/snackbarSlice";
import DeleteModal from "../../../../reusablecomponents/DeleteModal/DeleteModal";
import TrashIcon from "../../../../Assets/solar_trash-bin-2-bold-duotone.svg";
import UploadIcon from "../../../../Assets/solar_upload-line-duotone.png";
import editIcon from "../../../../Assets/editIcon.svg";
import UpdateBadge from "./UpdateBadge";
import { apebuzzBaseURL } from "../../../../utils/url";
import api from "../../../../utils/axios";

const ManageBadge = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [update, setUpdate] = useState({ id: "", open: false });
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [badges, setbadges] = useState([]);
  const [fileSize, setFileSize] = useState(null);
  const [DeletedId, setDeletedId] = useState([]);
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });

  const getAllbadge = async (e) => {
    dispatch(showLoader());
    try {
      const res = await api.get(`${apebuzzBaseURL}/api/auth/adminAuth/get/all/badges`);
      setbadges(res?.data.badges);
      dispatch(hideLoader());
    } catch (error) {
      console.log("error", error);
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getAllbadge();
  }, []);
  const handleClose = () => {
    setDialog({
      open: false,
      title: "",
      message: "",
      buttonText: "",
    });
  };
  const handleCloseUpdateModal = () => {
    setUpdate({ id: "", open: false });
  };

  const handleDelete = async () => {
    // try {
    //   const res = await apiService.deleteImagesBanner(dialog.data.bannerId);
    //   getbadges();
    //   actionDispatcher(openSnackbar("Banner Deleted Successfully", "success"));
    // } catch (error) {
    //   console.log("error", error);
    //   actionDispatcher(openSnackbar(error?.response?.data?.error, "error"));
    // }
  };
  return (
    <>
      <Box py={1}>
        <Typography
          sx={{
            color: "rgba(110, 107, 123, 1)",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          All Badge
        </Typography>
      </Box>
      <Grid container >
        {badges?.map((data, index) => {
          return (
            <Grid
              component={Paper}
              elevation={3}
              item
              xs={12}
              md={8}
              key={index}
              sx={{
                p: 2.5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="userProfileImage"
                  src={data?.badgeImage?.badgeImageUrl}
                  sx={{ width: "65px", height: "65px" }}
                />
              </Box>
              <Stack spacing={2}>
                <Box>
                  <TextField
                    fullWidth
                    placeholder="Write badge name"
                    id="title"
                    value={data.badgeName}
                    onChange={(e) => setTitle(e.target.value)}
                    variant="outlined"
                    label="Badge Name"
                    disabled
                    sx={{
                      width: "300px",
                      "&>.Mui-disabled": {
                        color: "rgba(134, 132, 139, 1)",
                        borderColor: "rgba(134, 132, 139, 1) !important",
                        "& .MuiInputBase-input": {
                          cursor: "not-allowed",
                          borderColor: "rgba(134, 132, 139, 1) !important",
                        },
                      },
                      "&.MuiInputBase-input::placeholder": {
                        color: "red",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    placeholder="Write description"
                    id="title"
                    value={data.description}
                    onChange={(e) => setTitle(e.target.value)}
                    variant="outlined"
                    multiline
                    label="Description"
                    rows={2}
                    disabled
                    sx={{
                      width: "300px",
                      "&>.Mui-disabled": {
                        // opacity: 0.5, // adjust opacity to make it look disabled
                        color: "rgba(134, 132, 139, 1)", // adjust background color to make it look disabled
                        borderColor: "rgba(134, 132, 139, 1) !important", // adjust border color to make it look disabled
                        "& .MuiInputBase-input": {
                          cursor: "not-allowed", // change cursor to indicate it's disabled
                          borderColor: "rgba(134, 132, 139, 1) !important",
                        },
                      },
                      "&.MuiInputBase-input::placeholder": {
                        color: "red", // style the placeholder text
                      },
                    }}
                  />
                </Box>
              </Stack>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  pr: 5,
                  mt: 1,
                }}
                onClick={() => setUpdate({ id: data._id, open: true })}
              >
                <img
                  alt="edit"
                  style={{ width: "31px", height: "31px" }}
                  src={editIcon}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "15px",
                    color: "rgba(105, 121, 135, 1)",
                  }}
                >
                  Edit
                </Typography>
              </Box>
              {/* <Box>
                <img src={TrashIcon} alt='trashIcon' style={{ width: "35px", height: "35px" }} />
                <Typography>Delete</Typography>
              </Box> */}

              {/* <Divider /> */}
            </Grid>
          );
        })}
      </Grid>
      {dialog.open && (
        <DeleteModal
          open={dialog.open}
          handleClose={handleClose}
          dialog={dialog}
          deleteFunc={() => handleDelete()}
        />
      )}
      {update.open && (
        <UpdateBadge
          update={update}
          handleClose={handleCloseUpdateModal}
          getAllbadge={getAllbadge}
        />
      )}
    </>
  );
};

export default ManageBadge;
