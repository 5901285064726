import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { hideLoader, showLoader } from "../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../ReduxToolkit/Slices/snackbarSlice";
import ModalComponent from "../../reusablecomponents/ModalComponent/ModalComponent";
import Loader from "../../reusablecomponents/isLoading/Loader";
import DeleteModal from "../../reusablecomponents/DeleteModal/DeleteModal";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";

const SingleQuery = ({
  queryId,
  handleClose,
  setAllQueries,
  setPaginationData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader.open);
  const [queryStatus, setQueryStatus] = useState("");
  const [query, setQuery] = useState({});
  const [queryComment, setQueryComment] = useState("");
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  const getReview = async () => {
    dispatch(showLoader());
    try {
      const res = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/contact-us/get/single/contact-request/${queryId.id}`,
      );
      setQuery(res?.data?.contactQuery || {});
      setQueryStatus(res?.data?.contactQuery?.status);
      setQueryComment(res?.data?.contactQuery?.comment);
      dispatch(hideLoader());
    } catch (error) {
      // dispatch(
      //   showSnackbar({
      //     message: `${error?.response.data.message} !`,
      //     severity: "error",
      //   }),
      // );
      dispatch(hideLoader());
    }
  };

  const getAllQueries = async () => {
    dispatch(showLoader());
    try {
      const res = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/contact-us/get/all/contact-requests?limit=${10}&page=${1}`,
      );

      setAllQueries(res?.data?.submissions);

      setPaginationData({
        limitPerPage: res?.data?.pagination.perPage,
        totalSubmissions: res?.data.pagination.totalSubmissions,
        totalPages: res?.data?.pagination.totalPages,
        page: res?.data.pagination.currentPage,
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (queryId.id) {
      getReview();
    }
  }, [queryId]);

  const handleCloseDeleteModal = () => {
    setDialog({
      open: false,
      title: "",
      buttonText: "",
      data: {
        id: null,
      },
    });
  };

  const handleQueryChange = (e) => {
    setQueryStatus(e.target.value);
  };

  // const handleQueryCommentChange = (e)=>{
  //   setQueryComment(e.target.value);
  // }

  const handleDelete = async () => {
    try {
      const res =
        dialog.buttonText === "Delete"
          ? await api.delete(
              `${apeitnowBaseURL}/api/v1/apeitnow/contact-us/delete/contact-request/:${queryId.id}`,
            )
          : console.log("");
      // await api.put(
      //     `${apeitnowBaseURL}/api/v1/apeitnow/query-and-rating/sanitize/product-reviews`,
      //     {
      //       reviews_and_Rating_Id: preview.id,
      //       sanitized: true,
      //     },
      //   );

      handleClose();
      handleCloseDeleteModal();
    } catch (error) {
      console.log("error", error);
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
    }
  };

  const handleQueryUpdate = async () => {
    if (queryStatus === "") {
      dispatch(
        showSnackbar({
          message: `Query Status cannot be empty`,
          severity: "error",
        }),
      );
    }
    try {
      const res = await api.put(
        `${apeitnowBaseURL}/api/v1/apeitnow/contact-us/update/contact-request/status/${query._id}`,
        {
          status: queryStatus,
          comment: queryComment,
        },
      );

      handleClose();
      setQuery({});
      getAllQueries();
      setQueryComment("");
      setQueryStatus("");
      dispatch(
        showSnackbar({
          message: `Query updated successfully`,
          severity: "success",
        }),
      );
    } catch (error) {
      console.log("error", error);
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
    }
  };

  // const handlesenitized = async () => {
  //   try {
  //     const res = await axios.post(
  //       `${apilocal}/api/v1/apeitnow/query-and-rating/sanitize/product-reviews`,
  //       {
  //         reviews_and_Rating_Id: preview.id,
  //         sanitized: true,
  //       },
  //     );
  //     dispatch(
  //       showSnackbar({
  //         message: `Review has sentized successfully!`,
  //         severity: "success",
  //       }),
  //     );
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  return (
    <ModalComponent open={queryId.open}>
      {isLoading ? (
        <Loader />
      ) : (
        <Stack
          spacing={1}
          sx={{
            width: {
              md: 600,
              sm: 400,
              xs: 310,
            },
            p: 4,
          }}
        >
          <Box>
            <Button
              variant="text"
              sx={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
              onClick={handleClose}
              startIcon={<CloseIcon sx={{ fontSize: "16px" }} />}
            >
              Cancel
            </Button>
          </Box>
          <Typography sx={{ fontWeight: 600, fontSize: "19px" }}>
            {query?.name
              ? query?.name
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : "N/A"}
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Email :{query?.email ? query?.email : "N/A"}
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            {query?.createdAt
              ? `Posted on : ${new Date(query?.createdAt).toLocaleString(
                  "en-Us",
                  {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  },
                )}`
              : ""}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "#F0F1F3",
              padding: 2,
              borderRadius: "10px",
            }}
          >
            <Typography>{query?.message}</Typography>
          </Box>
          <Box>
            <FormControl sx={{ mt: 1, minWidth: 220 }} size="small">
              <InputLabel id="select-small-label">Query Status *</InputLabel>
              <Select
                labelId="select-small-label"
                id="select-small"
                value={queryStatus}
                disabled={query.status === "resolved"}
                label="Query Status *"
                required
                onChange={handleQueryChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"reviewed"}>Reviewed</MenuItem>
                <MenuItem value={"resolved"}>Resolved</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TextField
            label={"Query Comment"}
            size="small"
            value={queryComment}
            multiline
            minRows={2}
            onChange={(e) => setQueryComment(e.target.value)}
            disabled={query.status === "resolved"}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 2,
            }}
          >
            <Button
              variant="contained"
              size="small"
              disabled={
                (queryStatus === query.status &&
                  queryComment === query?.queryComment) ||
                query.status === "resolved"
              }
              sx={{
                bgcolor: "green",
              }}
              onClick={() =>
                // setDialog({
                //   open: true,
                //   title: "Are you sure to delete this query",
                //   buttonText: "Delete",
                //   data: {
                //     id: null,
                //   },
                // })
                handleQueryUpdate()
              }
            >
              Update Query
            </Button>
            {/* {query?.data?.sanitized ? (
              ""
            ) : (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  // bgcolor: "green",
                  ml: 1,
                }}
                onClick={() =>
                  setDialog({
                    open: true,
                    title: "Are you sure you want to senitize this query",
                    buttonText: "Senitize",
                    data: {
                      id: null,
                    },
                  })
                }
              >
                Sanitize
              </Button>
            )} */}
          </Box>
        </Stack>
      )}
      {dialog.open && (
        <DeleteModal
          open={dialog.open}
          dialog={dialog}
          handleClose={handleCloseDeleteModal}
          deleteFunc={handleDelete}
        />
      )}
    </ModalComponent>
  );
};

export default SingleQuery;
