import { Box, Chip, Paper, Stack, Typography } from "@mui/material";
import React from "react";

const StatsCards = ({
  icon,
  statsNumber,
  statsName,
  statsChangeDirection,
  statsChangePercent,
}) => {
  return (
    <Box
      component={Paper}
      p={2.25}
      maxWidth={{ xs: "100%", sm: "310px" }}
      width={"100%"}
      minHeight={"170px"}
      border={"1px solid #E0E2E7"}
      borderRadius={"10px"}
      elevation={2}
    >
      <Stack alignContent={"space-between"} rowGap={2}>
        {icon}
        <Typography
          variant="h5"
          fontWeight={"light"}
          sx={{ color: "#667085" }}
          component={"span"}
        >
          {statsName}
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          columnGap={1}
          //   mt={1.5}
        >
          <Typography
            variant="h4"
            sx={{ color: "#333843" }}
            component={"blockquote"}
            fontWeight={"light"}
          >
            {statsNumber}
          </Typography>
          <Chip
            variant="filled"
            size="small"
            sx={{
              color: statsChangeDirection === "up" ? "#0D894F" : "#F04438",
              bgcolor: statsChangeDirection === "up" ? "#E7F4EE" : "#FEEDEC",
            }}
            label={statsChangePercent}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default StatsCards;
