import { Box } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

const SplineCharts = ({ data }) => {
  console.log("data==>", data);
  const chartData = {
    series: [
      {
        name: "Sales",
        data: data?.totalSales,
      },
      {
        name: "Revenue",
        data: data?.revenue,
      },
      {
        name: "Collection",
        data: data?.salesCollecion,
      },
    ],
    chart: {
      height: 350,
      type: "area",
      background: "transparent", // Ensure the background doesn't obstruct the view
      foreColor: "#333", // Set the color for the chart elements like text and axis labels
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: data?.months, // Use month names as categories
      //   title: {
      //     text: "Months", // Label for the x-axis
      //   },
      grid: {
        show: false, // Hide the x-axis grid
      },
    },
    yaxis: {
      grid: {
        show: false, // Hide the y-axis grid
      },
      //     title: {
      //     text: "Months", // Label for the x-axis
      //   },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm", // Customize the tooltip format if needed
      },
    },
    colors: ["#FFA500", "#008000","#5C59E8"], // Set custom colors for the series (orange and green)
    plotOptions: {
      area: {
        fillTo: "origin", // Ensure that the series is filled starting from the origin (bottom of the chart)
      },
    },
  };

  return (
    <Box width={"100%"}>
      <Chart
        options={chartData}
        series={chartData?.series}
        type="area"
        width="100%"
        height={"350px"}
      />
    </Box>
  );
};

export default SplineCharts;
