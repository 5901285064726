import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hideLoader, showLoader } from "../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../ReduxToolkit/Slices/snackbarSlice";
import { AppConversionRates } from "../sections/@dashboard/app";
import { apebuzzBaseURL } from "../utils/url";
import api from "../utils/axios";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MonthlyFeedbacks = () => {
  const dispatch = useDispatch();
  const [feedBacks, setfeedBacks] = useState(null);
  const separateFeedbacksByMonth = (feedbackData) => {
    const monthlyFeedbacks = {};
    const currentDate = new Date();
    const last12Months = [];

    for (let i = 0; i < 12; i += 1) {
      const month = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1,
      );
      last12Months.push(month.toLocaleString("default", { month: "long" }));
    }

    last12Months.forEach((month) => {
      monthlyFeedbacks[month] = [];
    });

    feedbackData.forEach((feedback) => {
      const date = new Date(feedback.createdAt);
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      if (
        year === currentDate.getFullYear() ||
        year === currentDate.getFullYear() - 1
      ) {
        if (monthlyFeedbacks[month]) {
          monthlyFeedbacks[month].push(feedback);
        }
      }
    });

    return monthlyFeedbacks;
  };

  const getUserStats = async () => {
    dispatch(showLoader());
    try {
      const res = await api.get(`${apebuzzBaseURL}/api/auth/adminAuth/get/all/feedbacks`);
      //   const monthlyFeedbacks = separateFeedbacksByMonth(res?.data.feedbackData);
      setfeedBacks(separateFeedbacksByMonth(res?.data.feedbackData));
      dispatch(hideLoader());
    } catch (error) {
      console.error(error);
      console.log("error", error);
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getUserStats();
  }, []);
//   console.log("feedBacks", feedBacks);
  return (
    <Grid item xs={12} md={6} lg={8}>
      {feedBacks === null ? (
        "No records found"
      ) : (
        <AppConversionRates
          title="Monthly feedback"
          subheader=""
          chartData={[
            { label: "January", value: feedBacks.January.length },
            { label: "February", value: feedBacks.February.length },
            { label: "March", value: feedBacks.March.length },
            { label: "April", value: feedBacks.April.length },
            { label: "May", value: feedBacks.May.length },
            { label: "June", value: feedBacks.June.length },
            { label: "July", value: feedBacks.July.length },
            { label: "August", value: feedBacks.August.length },
            { label: "September", value: feedBacks.September.length },
            { label: "October", value: feedBacks.October.length },
            { label: "November", value: feedBacks.November.length },
            { label: "December", value: feedBacks.December.length },
          ]}
        />
      )}
    </Grid>
  );
};

export default MonthlyFeedbacks;
