import { Box } from "@mui/material";

import Iconify from "../../../components/iconify/Iconify";

export const newIcon = (iconName) => (
  <Box
    className="box"
    sx={{
      width: 25,
      height: 25,
      borderRadius: "0.25rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#BDC4CB",
      color: "currentcolor",
    }}
  >
    <Iconify icon={iconName} />
  </Box>
);

export const apeitNowConfig = [
  {
    type: "route",
    title: "dashboard",
    path: "/dashboard/apeitnow",
    // icon: icon("Dashboard"),
    // <iconify-icon icon="fluent:arrow-trending-lines-24-filled"></iconify-icon>
    icon: newIcon("fluent:arrow-trending-lines-24-filled"),
    slug: ["apeitnow-dashboard"],
  },
  {
    type: "collapse",
    title: "Product management",
    icon: newIcon("fluent-mdl2:product"),
    path: "/dashboard/product",
    slug: ["apeitnow-product_management"],
    subRoutes: [
      {
        type: "route",
        name: "add product",
        key: "product",
        path: "/dashboard/product/add-product",
      },
      {
        type: "route",
        name: "All product",
        key: "product",
        path: "/dashboard/product/all-product",
      },
    ],
  },
  {
    type: "collapse",
    title: "Coupon Management",
    icon: newIcon("hugeicons:coupon-percent"),
    path: "/dashboard/coupon-management",
   
    slug: ["apeitnow-coupon_management"],
    subRoutes: [
      {
        type: "route",
        name: "add coupon",
        key: "product",
        path: "/dashboard/coupon-management/add-coupon",
      },
      {
        type: "route",
        name: "All coupon",
        key: "product",
        path: "/dashboard/coupon-management/all-coupons",
      },
    ],
  },
  {
    // <iconify-icon icon="iconoir:star"></iconify-icon>
    type: "route",
    title: "Review Management",
    icon: newIcon("iconoir:star"),
    path: "/dashboard/review-management/all-reviews",
    slug: ["apeitnow-reviews_and_rating_management"],
  },
  {
    // <iconify-icon icon="lineicons:user-4"></iconify-icon>
    type: "route",
    title: "Manage Cusotmers",
    path: "/dashboard/manage-customer",
    icon: newIcon("lineicons:user-4"),
    slug: ["apeitnow-manage_customer"],
  },
  {
//  <Icon icon="system-uicons:cart" width="21" height="21" />
    type: "route",
    title: "Manage Carts",
    path: "/dashboard/manage-carts",
    icon: newIcon("system-uicons:cart"),
    slug: ["apeitnow-manage_carts"],
  },
  {
    type: "route",
    title: "Manage Orders",
    path: "/dashboard/orders",
    icon: newIcon("fluent:notepad-edit-20-regular"),
    slug: ["apeitnow-order_management"],
  },
  {
    // <iconify-icon icon="material-symbols-light:local-shipping-outline-rounded"></iconify-icon>
    type: "route",
    title: "Shipping Charges",
    path: "/dashboard/shipping-charges",
    icon: newIcon("material-symbols-light:local-shipping-outline-rounded"),
    slug: ["apeitnow-shipping_charges"],
  },
  {
    // <Icon icon="carbon:delivery-parcel" width="32" height="32" />
    type: "collapse",
    title: "delivery-management",
    path: "/dashboard/delivery-management",
    icon: newIcon("carbon:delivery-parcel"),
    slug: ["apeitnow-delivery_management"],
    subRoutes:[
      {
        type: "route",
        name: "warehouse",
        key: "warehouse",
        path: "/dashboard/delivery-management/warehouse",
      },
      {
        type: "route",
        name: "Shipments",
        key: "shipments",
        path: "/dashboard/delivery-management/shipments",
      },
      // {
      //   type: "route",
      //   name: "warehouse",
      //   key: "warehouse",
      //   path: "/dashboard/apeitnow-delivery_management",
      // },
    ]
  },
  {
    type: "route",
    title: "Custom Notification",
    path: "/dashboard/manage-custom-notification",
    icon: newIcon("mage:notification-bell-download"),
    slug: ["apeitnow-custom_notification"], // permisison slug
  },
  {
    type: "route",
    title: "Custom Email",
    path: "/dashboard/custom-emails",
    icon: newIcon("lets-icons:e-mail-light"),
    slug: ["apeitnow-custom_email"], // permisison slug
  },
  {
    type: "route",
    title: "Transanctions",
    path: "/dashboard/transactions",
    icon: newIcon("bitcoin-icons:transactions-outline"),
    // <iconify-icon icon="bitcoin-icons:transactions-outline"></iconify-icon>
    slug: ["apeitnow-transactions_management"], // permisison slug
  },
  {
    type: "route",
    title: "Contact Queries",
    path: "/dashboard/contact-queries",
    icon: newIcon("carbon:chat"),
    slug: ["apeitnow-contact_queries"],
  },
  {
    type: "collapse",
    title: "apeitnow cms",
    path: "/dashboard/apeitnow-cms",
    icon: newIcon("iconoir:notes"),
    slug: ["apeitnow-cms"], // permisison slug
    subRoutes: [
      {
        type: "route",
        name: "privacy policy",
        key: "privacy-policy",
        path: "/dashboard/apeitnow-cms/privacy-policy",
      },
      {
        type: "route",
        name: "term and conditions",
        key: "term-and-conditions",
        path: "/dashboard/apeitnow-cms/terms-and-conditions",
      },
      {
        type: "route",
        name: "return and refund",
        key: "return-and-refund",
        path: "/dashboard/apeitnow-cms/return-and-refund",
      },
      // {
      //   type: "route",
      //   name: "apeitnow feedback",
      //   key: "apeitnow-feedback",
      //   path: "/dashboard/apeitnow-cms/feedback",
      // },
    ],
  },
  {
    type: "collapse",
    title: "settings",
    icon: newIcon("solar:settings-outline"),
    path: "/dashboard/settings",
    slug: ["apeitnow-app_settings"],
    subRoutes: [
      {
        type: "route",
        name: "seo setting",
        key: "seo-setting",
        path: "/dashboard/settings/seo-setting",
      },
    ],
  },
];
export default apeitNowConfig;
