import React, { useState, useEffect } from "react";

import {
  Box,
  CardMedia,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { hideLoader, showLoader } from "../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../ReduxToolkit/Slices/snackbarSlice";
import DeleteModal from "../../reusablecomponents/DeleteModal/DeleteModal";

import { getErrorMessage } from "../../reusablecomponents/CustomError/errorMessages";
import { apebuzzBaseURL, apeitnowBaseURL } from "../../utils/url";
import api from "../../utils/axios";
import { inputBorderStyle } from "../../GlobelStyles/commonStyle";
import Iconify from "../../components/iconify";

const CusomeNotificaionMgt = () => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(null);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [customNotification, setCustomNotification] = useState(null);
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  const sendCustomNotification = async (e) => {
    e.preventDefault();
    dispatch(showLoader());
    try {
      const data = await api.post(
        `${apeitnowBaseURL}/api/v1/apeitnow/admin/create/custom/notification`,
        {
          title,
          message: description,
        },
      );
      getAllCustomNotifications();
      setTitle("");
      setDescription("");
      dispatch(
        showSnackbar({
          message: "Notification sent successfully!",
          severity: "success",
        }),
      );
      dispatch(hideLoader());
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  const getAllCustomNotifications = async () => {
    dispatch(showLoader());
    try {
      const data = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/admin/get/all/custom/notification`,
      );
      console.log("data", data);
      setCustomNotification(data?.data.data);
      dispatch(hideLoader());
    } catch (error) {
      console.log("error", error);
      if (error?.response?.status === 404) {
        setCustomNotification(null);
      }

      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getAllCustomNotifications();
  }, []);
  const timeAgo = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInMs = now - createdDate;

    // Calculate time differences
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30); // Approximation
    const diffInYears = Math.floor(diffInDays / 365); // Approximation

    // Determine the appropriate time difference string
    let relativeTime = "";
    if (diffInMinutes < 1) {
      relativeTime = `just now`;
    } else if (diffInMinutes < 60) {
      relativeTime = `${diffInMinutes} minute${
        diffInMinutes > 1 ? "s" : ""
      } ago`;
    } else if (diffInHours < 24) {
      relativeTime = `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    } else if (diffInDays < 30) {
      relativeTime = `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    } else if (diffInMonths < 12) {
      relativeTime = `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
    } else {
      relativeTime = `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
    }

    // Formatting the time
    const hours = createdDate.getHours();
    const minutes = createdDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

    return `${formattedTime} (${relativeTime})`;
  };
  const handleDelete = async () => {
    dispatch(showLoader());
    try {
      const res = await api.delete(
        `${apeitnowBaseURL}/api/v1/apeitnow/admin/create/custom/notification/${dialog.data.id}`,
      );
      getAllCustomNotifications();
      if (res?.data.success) {
        dispatch(hideLoader());
        dispatch(
          showSnackbar({
            message: `Custom notification deleted successfully !`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      console.log("Custom Notification error", error);

      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  const handleClose = () => {
    setDialog({
      open: false,
      title: "",
      buttonText: "",
      data: {
        id: null,
      },
    });
  };

  const handleCopyTheNotification = (notificationData) => {
    const textToCopy = `${notificationData?.title} ${notificationData?.message}`;
    setTitle(notificationData.title);
    setDescription(notificationData.message);
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(notificationData._id);
        console.log("copied");
        setTimeout(() => setCopied(null), 2000); // Reset "Copied!" after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  console.log("customNotification", customNotification);
  return (
    <>
      <Grid container columnGap={2} direction={"column"} pr={5}>
        <Box py={3}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 450,
              color: "rgba(32, 33, 36, 1)",
            }}
          >
            Send Notification
          </Typography>
        </Box>
        <Grid
          item
          xs={6}
          component={"form"}
          onSubmit={sendCustomNotification}
          md={7}
        >
          <Stack spacing={3} p={3} component={Paper} elevation={2}>
            <Box>
              <TextField
                fullWidth
                placeholder="Title"
                id="title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                variant="standard"
                required
                sx={inputBorderStyle}
              />
            </Box>
            <Box>
              <TextField
                fullWidth
                placeholder="Write notification here..."
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                variant="standard"
                required
                sx={inputBorderStyle}
              />
            </Box>
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{
                width: "150px",
                "&:hover": {
                  backgroundColor: "rgba(82, 118, 10, 1)",
                  boxShadow: "none",
                  color: "white",
                },
              }}
            >
              send
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container columnGap={2} direction={"column"} pr={5}>
        <Box py={3}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 450,
              color: "rgba(32, 33, 36, 1)",
            }}
          >
            Previous Notifications
          </Typography>
        </Box>
        {customNotification === null ? (
          <Grid item xs={6} component={Paper} elevation={2} md={7} p={3}>
            No records found
          </Grid>
        ) : (
          customNotification.map((notification, index) => {
            return (
              <Grid
                item
                key={index}
                xs={6}
                component={Paper}
                elevation={2}
                md={7}
                px={2}
                py={1}
                my={0.5}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Button
                  variant="contained"
                  color="inherit"
                  size="small"
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disabled={copied === notification._id}
                  onClick={() => handleCopyTheNotification(notification)}
                  sx={{
                    position: "absolute",
                    top: -8,
                    right: 2,
                    fontSize: "10px",
                  }}
                >
                  {copied === notification._id ? "copied" : "copy"}
                </Button>
                <Stack width={"70%"}>
                  <Typography
                    sx={{
                      color: "rgba(32, 33, 36, 1)",
                      fontWeight: 500,
                      fontSize: "17px",
                    }}
                  >
                    {notification?.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontWeight: 400,
                      fontSize: "13px",
                    }}
                  >
                    {notification?.message}
                  </Typography>
                </Stack>
                <Box
                  width={"30%"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>{timeAgo(notification.createdAt)}</Typography>
                  {/* <img
                    alt="edit"
                    style={{ height: "30px", width: "30px", cursor: "pointer" }}
                    src={Notificationedit}
                  /> */}
                  <IconButton
                    onClick={() =>
                      setDialog({
                        open: true,
                        title: "notification",
                        buttonText: "",
                        data: {
                          id: notification._id,
                        },
                      })
                    }
                  >
                    {/* <iconify-icon icon="fluent:delete-28-regular"></iconify-icon> */}
                    <Iconify icon="fluent:delete-28-regular" />
                  </IconButton>
                </Box>
              </Grid>
            );
          })
        )}
      </Grid>
      {dialog.open && (
        <DeleteModal
          open={dialog.open}
          handleClose={handleClose}
          dialog={dialog}
          deleteFunc={() => handleDelete()}
        />
      )}
    </>
  );
};

export default CusomeNotificaionMgt;
