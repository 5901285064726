import React from "react";
import { Card, CardContent, Typography, Grid, Paper, Box } from "@mui/material";

const WarehouseDetailCard = ({ warehouseData }) => {
  // Destructure the warehouse details from props
  const {
    warehouseName,
    mobileNumber,
    email,
    address,
    pincode,
    city,
    state,
    country,
    defaultWarehouse,
  } = warehouseData;

  return (
    <Card sx={{ margin: "auto", mt: 2 }} elevation={4} >
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          Warehouse Details
        </Typography>

        <Grid container spacing={1}>
          {/* Warehouse Name */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>Warehouse Name:</strong> {warehouseName}
            </Typography>
          </Grid>

          {/* Mobile Number */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>Mobile Number:</strong> {mobileNumber}
            </Typography>
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>Email:</strong> {email}
            </Typography>
          </Grid>

          {/* Address */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>Address:</strong> {address}
            </Typography>
          </Grid>

          {/* Pincode */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>Pincode:</strong> {pincode}
            </Typography>
          </Grid>

          {/* City */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>City:</strong> {city}
            </Typography>
          </Grid>

          {/* State */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>State:</strong> {state}
            </Typography>
          </Grid>

          {/* Country */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>Country:</strong> {country}
            </Typography>
          </Grid>

          {/* Default Warehouse */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>Default Warehouse:</strong>{" "}
              {defaultWarehouse ? "Yes" : "No"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WarehouseDetailCard;
