import { Box, Grid } from "@mui/material";
import React from "react";
import StatsCards from "./StatsCards";
import Iconify from "../../components/iconify";

function IconBox({
  icon,
  bgcolor = "#DEDEFA",
  borderColor = "#EFEFFD",
  color = "#5C59E8",
  ...props
}) {
  console.log("props-->", props);
  return (
    <Box
      sx={{
        bgcolor,
        border: `4px solid ${borderColor}`,
        color,
        p: 0.5,
        display: "flex",
        width: "3.25rem",
        height: "3.25rem",
        justifyContent: "center",
        // boxSizing: "border-box",
        alignItems: "center",
        borderRadius: "50%",
        ...props.sx, // Allow additional styles to be passed via props
      }}
      {...props} // Spread other props like event handlers
    >
      {icon} {/* Just render the passed icon element */}
    </Box>
  );
}

const DashboardStats = ({ data }) => {
  console.log("data==>",data)
  return (
    <Grid
      columnGap={1}
      rowGap={2}
      container
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
    >
      <Grid item xs={12} sm={5.85} md={2.8}>
        <StatsCards
          icon={
            <IconBox
              icon={<img src="/assets/icons/moneyIcon.svg" alt="icon" />}
              bgcolor="#DEDEFA"
              borderColor="#EFEFFD"
              color="#5C59E8"
            />
          }
          statsName="Total Revenue"
          statsNumber={`Rs${data?.totalRevenue?.value}`}
          statsChangePercent={`${data?.totalRevenue?.change}`}
          statsChangeDirection="up"
        />
      </Grid>
      <Grid item xs={12} sm={5.85} md={2.8}>
        <StatsCards
          icon={
            <IconBox
              icon={<Iconify icon="meteor-icons:cart-shopping" />}
              bgcolor="#CFE7DC"
              borderColor="#E7F4EE"
              color="#0D894F"
            />
          }
          statsName="Total Sales"
          statsNumber={`${data?.totalSale?.value}`}
          statsChangePercent={`${data?.totalSale?.change}`}
          statsChangeDirection="up"
        />
      </Grid>
      <Grid item xs={12} sm={5.85} md={2.8}>
        <StatsCards
          icon={
            <IconBox
              icon={<Iconify icon="ri:box-3-fill" />}
              bgcolor="#FBFFE0"
              borderColor="#FFF8BA"
              color="#FEC53D"
            />
          }
          statsName="Total order"
          statsNumber={`${data?.totalOrders?.value}`}
          statsChangePercent={`${data?.totalOrders?.change}`}
          statsChangeDirection="up"
        />
      </Grid>
      <Grid item xs={12} sm={5.85} md={2.8}>
        <StatsCards
          icon={
            <IconBox
              icon={<Iconify icon="subway:refresh-time" />}
              bgcolor="#FAE1CF"
              borderColor="#FDF1E8"
              color="#FF9066"
            />
          }
          statsName="Pending Order"
          statsNumber={`${data?.pendingOrders?.value}`}
          statsChangePercent={`${data?.pendingOrders?.change}`}
          statsChangeDirection="down"
        />
      </Grid>
    </Grid>
  );
};

export default DashboardStats;
