import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Chip,
  TextField,
  Card,
  CardContent,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // Import delete icon
import { apecenterBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";

import DeleteModal from "../../../reusablecomponents/DeleteModal/DeleteModal";

const ManageRole = () => {
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState(""); // New state for description
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [groupedPermissions, setGroupedPermissions] = useState({});
  const [permissionsData, setPermissionsData] = useState([]);
  const [createdRoles, setCreatedRoles] = useState([]); // New state for created roles
  const [error, setError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [targetId, setTargetId] = useState(null);

  const openDeleteModalFunc = () => setOpenDeleteModal(true);
  const closeDeleteModalFunc = () => setOpenDeleteModal(false);

  const handleOpenDeleteModal = (targetId) => {
    setTargetId(targetId);
    openDeleteModalFunc();
  };

  const handleCloseDeleteModal = () => {
    setTargetId(null);
    closeDeleteModalFunc();
  };

  // function to fetch permissions
  const fetchPermissions = async () => {
    try {
      const response = await api.get(
        `${apecenterBaseURL}/api/v1/ape-control-center/superadmin/permissions/get/all`,
        {
          params: {
            page: 1,
            limit: 100,
          },
        },
      );

      // Assuming the API response structure is the same as the static data provided
      const permissions = response.data.data; // Adjust based on actual response format

      setPermissionsData(permissions);

      // Group permissions by app
      const grouped = permissions.reduce((acc, permission) => {
        const appName = permission.app.name;

        if (!acc[appName]) {
          acc[appName] = [];
        }

        acc[appName].push(permission);
        return acc;
      }, {});

      setGroupedPermissions(grouped);
    } catch (error) {
      console.error("Error fetching permissions:", error);
      setError("Failed to load permissions.");
    }
  };

  // Fetch created roles
  const fetchRoles = async () => {
    try {
      const response = await api.get(
        `${apecenterBaseURL}/api/v1/ape-control-center/superadmin/roles/get/all`,
      );
      setCreatedRoles(response.data.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setError("Failed to load roles.");
    }
  };

  useEffect(() => {
    // Fetch permissions data from API
    fetchPermissions();

    fetchRoles();
  }, []);

  const handlePermissionChange = (event) => {
    const permissionId = event.target.name;
    setSelectedPermissions((prevSelected) =>
      prevSelected.includes(permissionId)
        ? prevSelected.filter((id) => id !== permissionId)
        : [...prevSelected, permissionId],
    );
  };

  const handleRoleNameChange = (event) => {
    setRoleName(event.target.value);
  };

  const handleRoleDescriptionChange = (event) => {
    setRoleDescription(event.target.value);
  };

  const handleSubmit = async () => {
    if (!roleName) {
      setError("Role name is required.");
      return;
    }
    if (selectedPermissions.length === 0) {
      setError("At least one permission must be selected.");
      return;
    }

    // Format the request object
    const selectedApps = [
      ...new Set(
        selectedPermissions.map(
          (permissionId) =>
            permissionsData.find(
              (permission) => permission._id === permissionId,
            ).app._id,
        ),
      ),
    ];

    const requestData = {
      name: roleName,
      description: roleDescription,
      apps: selectedApps,
      permissions: selectedPermissions,
    };

    try {
      const response = await api.post(
        `${apecenterBaseURL}/api/v1/ape-control-center/superadmin/roles/add-new`,
        requestData,
      );

      console.log("Role Created:", requestData);
      console.log("response-->", response);
      setError(""); // Clear any previous errors

      // Add the new role to the createdRoles state
      setCreatedRoles((prevRoles) => [
        ...prevRoles,
        { ...requestData, _id: response.data._id }, // Assuming response contains the created role ID
      ]);

      // Reset form
      setRoleName("");
      setRoleDescription("");
      setSelectedPermissions([]);
    } catch (error) {
      console.error("Error creating role:", error);
      setError("Failed to create role.");
    }
  };

  const handleDeleteRole = async (roleId) => {
    try {
      await api.delete(
        `${apecenterBaseURL}/api/v1/ape-control-center/superadmin/roles/${roleId}`,
      );
      // Remove the deleted role from the state
      setCreatedRoles((prevRoles) =>
        prevRoles.filter((role) => role._id !== roleId),
      );
    } catch (error) {
      console.error("Error deleting role:", error);
      setError("Failed to delete role.");
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Create Role
        </Typography>

        <Stack direction={{ md: "row", xs: "column" }} columnGap={2} rowGap={2}>
          {/* Role Name Field */}
          <TextField
            label="Role Name"
            variant="outlined"
            fullWidth
            value={roleName}
            onChange={handleRoleNameChange}
            helperText={error}
            margin="normal"
          />
          <TextField
            label="Role Description"
            variant="outlined"
            fullWidth
            value={roleDescription}
            onChange={handleRoleDescriptionChange}
            helperText={error}
            margin="normal"
            multiline
            minRows={1}
          />
        </Stack>

        {/* Permissions Chart in a Common Card */}
        <Card sx={{ marginTop: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Select Permissions for Role
            </Typography>

            {/* Loop through apps and their permissions */}
            <Grid container spacing={2}>
              {Object.keys(groupedPermissions).map((appName) => (
                <Grid item xs={12} key={appName}>
                  <Box sx={{ marginBottom: 2 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", marginBottom: 1 }}
                    >
                      {appName}
                    </Typography>
                    <FormGroup row>
                      {groupedPermissions[appName].map((permission) => (
                        <FormControlLabel
                          key={permission._id}
                          control={
                            <Checkbox
                              checked={selectedPermissions.includes(
                                permission._id,
                              )}
                              onChange={handlePermissionChange}
                              name={permission._id}
                            />
                          }
                          label={
                            <Box>
                              <Typography variant="body2" textTransform={"capitalize"} >
                                {permission.name.split("_").join(" ")}
                              </Typography>
                              {/* <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {permission.description ||
                                  "No description available"}
                              </Typography> */}
                            </Box>
                          }
                        />
                      ))}
                    </FormGroup>
                  </Box>
                  <Divider />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>

        <Box sx={{ marginTop: 3 }}>
          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
          >
            Create Role
          </Button>
        </Box>

        {/* Selected Permissions */}
        <Box sx={{ marginTop: 3 }}>
          <Typography variant="h6" gutterBottom>
            Selected Permissions:
          </Typography>
          {selectedPermissions.length > 0 ? (
            selectedPermissions.map((permissionId) => {
              const permission = permissionsData.find(
                (perm) => perm._id === permissionId,
              );
              return (
                <Chip
                  key={permissionId}
                  label={`${permission.name} (${permission.app.name})`} // Showing permission name with app name
                  sx={{ marginRight: 1, marginBottom: 1 }}
                />
              );
            })
          ) : (
            <Typography variant="body2" color="text.secondary">
              No permissions selected.
            </Typography>
          )}
        </Box>

        {/* Table to show created roles */}
        <Box sx={{ marginTop: 3 }}>
          <Typography variant="h4" gutterBottom>
            All Created Roles:
          </Typography>
          {createdRoles.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Role Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Permissions</TableCell>
                    <TableCell>Permission Slugs</TableCell>
                    <TableCell>Allowed Apps</TableCell>
                    {/* <TableCell>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {createdRoles.map((role) => (
                    <TableRow key={role._id}>
                      <TableCell>{role.name}</TableCell>
                      <TableCell>{role.description}</TableCell>
                      <TableCell>
                        {role.permissions.map((perm) => perm.name).join(", ")}
                      </TableCell>
                      <TableCell>{role?.slugs?.join(", ")}</TableCell>
                      <TableCell>
                        {role?.apps?.map((app) => app?.name)?.join(", ")}
                      </TableCell>
                      {/* <TableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenDeleteModal(role._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body2" color="text.secondary">
              No roles created yet.
            </Typography>
          )}
        </Box>
      </Container>

      <DeleteModal
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        dialog={{ title: "Are you sure want to delete this role?" }}
        deleteFunc={handleDeleteRole}
      />
    </>
  );
};

export default ManageRole;
