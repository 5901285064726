import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Box,
  Chip,
  Paper,
  Popover,
  Stack,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import axios from "axios";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Table from "../../reusablecomponents/Table/Table";
import CustomToolbar from "../../reusablecomponents/CustomToolbar/CustomToolbar";
import { ColumnHeader, fontColor } from "../../GlobelStyles/commonStyle";
import dataFormatter from "../../reusablecomponents/DataFormatter/dataFormatter";
import DeleteModal from "../../reusablecomponents/DeleteModal/DeleteModal";
import { hideLoader, showLoader } from "../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../ReduxToolkit/Slices/snackbarSlice";
import { getErrorMessage } from "../../reusablecomponents/CustomError/errorMessages";
// import SingleReview from "./SingleReview";
import api from "../../utils/axios";
import { apeitnowBaseURL } from "../../utils/url";
import SingleQuery from "./SingleQuery";
import QueryTable from "./QueryTable";

const ContactQueries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // To Open View Profile and delete Popover
  const [anchorEl, setAnchorEl] = useState(null);
  // for pagination
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    limitPerPage: 10,
    totalSubmissions: 5,
    totalPages: 1,
    page: 1,
  });
  const [queries, setAllQueries] = useState([]);
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  const getAllQueries = async () => {
    setLoading(true);
    dispatch(showLoader());

    try {
      const res = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/contact-us/get/all/contact-requests?limit=${paginationData.limitPerPage}&page=${paginationData.page}`,
      );

      setAllQueries(res?.data?.submissions);

      setPaginationData({
        limitPerPage: res?.data?.pagination.perPage,
        totalSubmissions: res?.data.pagination.totalSubmissions,
        totalPages: res?.data?.pagination.totalPages,
        page: res?.data.pagination.currentPage,
      });
      setLoading(false);
      dispatch(hideLoader());
      // if (res?.data.success) {
      // }
    } catch (error) {
      // dispatch(
      //   showSnackbar({
      //     message: `${error?.response.data.message} !`,
      //     severity: "error",
      //   }),
      // );
      setLoading(false);
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getAllQueries();
  }, [paginationData.page, paginationData.limitPerPage]);

  const [active, setActive] = useState({
    id: "",
    status: false,
  });

  // to open single profile
  const [preview, setPreview] = useState({ id: "", open: false });
  // It is using in popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // Configuration object
  const config = {
    staticKeys: {
      SrNo: (item, index) =>
        `${
          index +
          paginationData.page * paginationData.limitPerPage -
          paginationData.limitPerPage +
          1
        }`,
      Name: (item, index) => item.name,
    },
  };
  const formattedQueries = dataFormatter(queries, config);
  // to change pagination
  const changePage = (page) => {
    setPaginationData({ ...paginationData, page });
  };
  // to change pageSize
  const changePageSize = (count) => {
    setPaginationData({ ...paginationData, page: 1, limitPerPage: count });
  };
  const handleClose = () => {
    setPreview({ id: "", open: false });
    setAnchorEl(null);
  };

  function formatMongooseDate(mongooseDateString) {
    return dayjs(mongooseDateString).format("D MMM YYYY");
  }

  const columns = [
    {
      field: "SrNo",
      width: 85,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Sr.No</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            fontSize="15px"
            sx={{
              py: 1.5,
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "name",
      width: 185,
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Name</Typography>,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip placement="left-start" title={params.value}>
              <Typography
                sx={{
                  ...fontColor,
                  fontWeight: 400,
                  py: 1.5,
                }}
              >
                {params.value === ""
                  ? "N/A"
                  : params.value
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1),
                      )
                      .join(" ")}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "email",
      width: 185,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Email Id</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              fontWeight: 400,
              py: 1.6,
            }}
          >
            {params.value === "" ? "N/A" : params.value}
          </Typography>
        );
      },
    },
    // {
    //   field: "phoneNo",
    //   width: 270,
    //   align: "center",
    //   headerAlign: "center",
    //   sortable: false,
    //   filterable: false,
    //   renderHeader: () => <Typography sx={ColumnHeader}>Phone No.</Typography>,
    //   renderCell: (params) => {
    //     return (
    //       <Typography
    //         sx={{
    //           ...fontColor,
    //           fontWeight: 400,
    //           py: 1.6,
    //         }}
    //       >
    //         {params.value === "" ? "N/A" : params.value}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: "message",
      align: "center",
      headerAlign: "center",
      width: 360,
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Message</Typography>,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <Typography
              sx={{
                ...fontColor,
                py: 1.6,
                fontWeight: 400,
              }}
            >
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "createdAt",
      align: "center",
      headerAlign: "center",
      width: 120,
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Date</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              py: 1.6,
              fontWeight: 400,
            }}
          >
            {formatMongooseDate(params.value)}
          </Typography>
        );
      },
    },
    {
      field: "status",
      align: "center",
      headerAlign: "center",
      width: 120,
      sortable: false,
      filterable: false,
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Status</Typography>,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value}
            variant="outlined"
            sx={{
              color: params.value === "resolved" ? "green" : "#393939",
            }}
          />
        );
      },
    },
    {
      field: "Action",
      width: 115,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderHeader: () => <Typography sx={ColumnHeader}>Action</Typography>,
      renderCell: (params) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 1.2,
              }}
            >
              <IconButton
                onClick={() => {
                  setPreview({ id: params.row._id, open: true });
                }}
              >
                <Icon icon="la:pen-solid" style={{ fontSize: "30px" }} />
              </IconButton>
              {/* <IconButton
                onClick={() =>
                  setDialog({
                    open: true,
                    title: "Are you sure to delete this query?",
                    buttonText: "Delete",
                    data: {
                      id: params.row._id,
                    },
                  })
                }
              >
                <Icon
                  icon="material-symbols:delete-outline"
                  style={{ fontSize: "25px" }}
                />
              </IconButton> */}
            </Box>
          </>
        );
      },
    },
  ];

  const handleDelete = async () => {
    try {
      const res = await api.delete(
        `${apeitnowBaseURL}/api/v1/apeitnow/review-and-rating/delete/sinlge/reviews/${dialog.data.id}`,
      );
      dispatch(
        showSnackbar({
          message: `Review deleted successfully !`,
          severity: "success",
        }),
      );
      handleClose();
      handleCloseDeleteModal();
      getAllQueries();
    } catch (error) {
      console.log("error", error);
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
    }
  };
  const handleCloseDeleteModal = () => {
    setDialog({
      open: false,
      title: "",
      buttonText: "",
      data: {
        id: null,
      },
    });
  };
  return (
    <>
      <Stack direction={"column"}>
        <Box>
          {/* It is a product table reusable component i am using same one for coupon Table  */}
          {formattedQueries?.length === 0 ? (
            "No data found"
          ) : (
            <QueryTable
              rows={formattedQueries}
              loading={loading}
              columns={columns}
              CustomToolbar={() =>
                CustomToolbar(paginationData, changePageSize)
              }
              pageCount={paginationData.totalPages}
              pageInfo={paginationData}
              changePage={changePage}
            />
          )}
        </Box>
      </Stack>
      {preview.open && (
        <SingleQuery
          queryId={preview}
          handleClose={handleClose}
          setAllQueries={setAllQueries}
          setPaginationData={setPaginationData}
        />
      )}
      {dialog.open && (
        <DeleteModal
          open={dialog.open}
          dialog={dialog}
          handleClose={handleCloseDeleteModal}
          deleteFunc={handleDelete}
        />
      )}
    </>
  );
};

export default ContactQueries;
