import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { Box, Button, Container, Grid, IconButton, Stack } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  DesktopDateRangePicker,
  MobileDateRangePicker,
} from "@mui/x-date-pickers-pro";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import DashboardStats from "./DashboardStats";
import Statistics from "./Statistics";

import SaleProgress from "./SaleProgress";
import RecentOrdersTable from "./RecentOrdersTable";
import Iconify from "../../components/iconify";
import CustomDatePicker from "./components/CustomDateRangePicker";
import api from "../../utils/axios";
import { apebuzzBaseURL, apeitnowBaseURL } from "../../utils/url";

function extractOrderDetails(data) {
  return data?.map((order) => {
    // Extract the required order details
    const orderId = order._id;
    const products = order.products.map((product) => ({
      productName: product.productName,
      quantity: product.quantity,
      price: product.price,
      total: product.total,
      image: product.image,
    }));
    const customer = {
      name: order.billingInfo.name,
      email: order.billingInfo.email,
      phone: order.billingInfo.phone,
      address: {
        house: order.billingInfo.house,
        area: order.billingInfo.area,
        landmark: order.billingInfo.landmark,
        city: order.billingInfo.city,
        state: order.billingInfo.state,
        zipCode: order.billingInfo.zipCode,
        country: order.billingInfo.country,
      },
    };
    const total = order.totalPrice;
    const status = order.status;
    const orderDate = order.createdAt;

    // Return the formatted order object
    return {
      orderId,
      products,
      customer,
      total,
      status,
      orderDate,
    };
  });
}

const date = new Date();

const ApeItNowDashboard = () => {
  console.log("year==>", date.getFullYear());
  const [rangeType, setrangeType] = useState("monthly");
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [recentOrders, setRecentOrders] = useState([]);

  const [statisticsYear, setstatisticsYear] = useState(`${date.getFullYear()}`);
  const [saleAndRevenueStatistics, setSaleAndRevenueStatistics] =
    useState(null);
  const [statsData, setStatsData] = useState({
    totalRevenue: {
      value: 0,
      change: "0%",
    },
    totalSale: {
      value: 0,
      change: "0%",
    },
    totalOrders: {
      value: 0,
      change: "0%",
    },
    pendingOrders: {
      value: 0,
      change: "0%",
    },
  });
  const navigate = useNavigate();
  const [selectionRange, setSelectionRange] = useState({
    startDate: date,
    endDate: date,
    key: "selection",
  });

  const handleSelect = (e) => {
    console.log("selected=>", e);
  };

  const getDashboardStatsData = async () => {
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/dashboard/stats?rangeType=${rangeType}`,
      );
      console.log("==>result--->", response);
      if (response.status === 200 || response.success) {
        setStatsData(response?.data?.data);
      }
    } catch (error) {
      console.log("error=>", error);
    }
  };

  const getSalesAndRevenueStatistics = async () => {
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/dashboard/slaes-revenu-stats?year=${statisticsYear}`,
      );
      console.log("statistics--->", response);
      if (response.success || response.status === 200) {
        setSaleAndRevenueStatistics(response.data.data);
      }
    } catch (error) {
      console.log("error=>", error);
    }
  };

  // get top selling products
  const getTopSellingsProducts = async () => {
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/dashboard/top-selling-products`,
      );
      console.log("topSelling--->", response);
      if (response.status === 200 || response.success) {
        setTopSellingProducts(response?.data?.data);
      }
    } catch (error) {
      console.log("error=>", error);
    }
  };

  // get recent orders
  const getRecentOrders = async () => {
    try {
      const response = await api.get(
        `${apeitnowBaseURL}/api/v1/apeitnow/dashboard/recent-orders`,
      );
      console.log("recent orders-->", response);
      setRecentOrders(response.data.data);
    } catch (error) {
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getDashboardStatsData();
  }, [rangeType]);

  useEffect(() => {
    getSalesAndRevenueStatistics();
    getTopSellingsProducts();
    getRecentOrders();
  }, []);

  return (
    <Container maxWidth="lg">
      <Stack rowGap={3}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          columnGap={2}
          alignItems={"center"}
        >
          {/* <CustomDatePicker /> */}
          {/* <Button
            variant="outlined"
            color="inherit"
            startIcon={<Iconify icon="iconoir:calendar" />}
          
          >
            Select Dates
          </Button> */}
          {/* <Calendar date={new Date()} onChange={handleSelect} /> */}
          {/* <DateRangePicker
            onChange={handleSelect}
            showSelectionPreview
            locale={"enUS"}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[selectionRange]}
            direction="horizontal"
          /> */}
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                "DateRangePicker",
                "MobileRangePicker",
                "DesktopDateRangePicker",
                "StaticDateRangePicker",
                "SingleInputDateRangeField",
              ]}
            >
              <DemoItem
                label="Desktop variant"
                component="DesktopDateRangePicker"
              >
                <DesktopDateRangePicker
                  defaultValue={[dayjs("2022-04-17"), dayjs("2022-04-21")]}
                />
              </DemoItem>
              <DemoItem
                label="Mobile variant"
                component="MobileDateRangePicker"
              >
                <MobileDateRangePicker
                  defaultValue={[dayjs("2022-04-17"), dayjs("2022-04-21")]}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider> */}
          <Button
            variant="contained"
            startIcon={<Iconify icon="line-md:plus" />}
            sx={{ bgcolor: "#53760A" }}
            onClick={() => navigate(`/dashboard/product/add-product`)}
          >
            Add Product
          </Button>
        </Box>
        <DashboardStats data={statsData} />
        <Statistics data={saleAndRevenueStatistics} />
        <SaleProgress
          progressData={statsData?.totalSale}
          topSellingProducts={topSellingProducts}
        />
        <RecentOrdersTable data={extractOrderDetails(recentOrders)} />
      </Stack>
    </Container>
  );
};

export default ApeItNowDashboard;
