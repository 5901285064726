import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Chip,
  Paper,
  Popover,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import axios from "axios";
import { useDispatch } from "react-redux";
import Table from "../../../reusablecomponents/Table/Table";
import CustomToolbar from "../../../reusablecomponents/CustomToolbar/CustomToolbar";
import { ColumnHeader, fontColor } from "../../../GlobelStyles/commonStyle";
import dataFormatter from "../../../reusablecomponents/DataFormatter/dataFormatter";
import DeleteModal from "../../../reusablecomponents/DeleteModal/DeleteModal";
import SingleUserPreview from "./SingleUserPreview";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import { getErrorMessage } from "../../../reusablecomponents/CustomError/errorMessages";
import { apebuzzBaseURL } from "../../../utils/url";
import api from "../../../utils/axios";

const UserManagement = () => {
  const dispatch = useDispatch();
  // To Open View Profile and delete Popover
  const [anchorEl, setAnchorEl] = useState(null);
  // for pagination
  const [paginationData, setPaginationData] = useState({
    limitPerPage: 5,
    totalData: 5,
    totalPages: 1,
    currentPage: 1,
  });
  const [users, setUsers] = useState([]);

  const getAllUsers = async () => {
    dispatch(showLoader());
    try {
      const res = await api.get(
        `${apebuzzBaseURL}/api/auth/adminAuth/get/all/users?limitPerPage=${paginationData.limitPerPage}&pageNo=${paginationData.currentPage}`,
      );

      setUsers(res?.data?.allUsers);
      setPaginationData(res?.data?.pageInfo);
      if (res?.data.success) {
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getAllUsers();
  }, [paginationData.currentPage, paginationData.limitPerPage]);
  // to set the popover button dynamically
  const [active, setActive] = useState({
    id: "",
    status: false,
  });
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  // to open single profile
  const [preview, setPreview] = useState({ id: "", open: false });
  // It is using in popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // Configuration object
  const config = {
    staticKeys: {
      SrNo: (item, index) =>
        `${
          index +
          paginationData.currentPage * paginationData.limitPerPage -
          paginationData.limitPerPage +
          1
        }`,
      BuzzReceive: (item, index) => item.receivedBuzz,
      BuzzSent: (item, index) => item.sentBuzz,
      PhoneNumber: (item, index) => item.phone,
      TotalScore: (item, index) => item.totalScore,
      Badge: (item, index) => item.badges.length,
      Name: (item, index) => item.name,
      Status: (item, index) => `${item.isActive ? "Active" : "Inactive"}`,
    },
  };
  const formattedUserData = dataFormatter(users, config);
  const filteredUsers = formattedUserData.filter((user) => {
    const lastWeek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const createdAt = new Date(user.createdAt);
    return user.isActive && createdAt >= lastWeek;
  });
  // to change pagination
  const changePage = (currentPage) => {
    setPaginationData({ ...paginationData, currentPage });
  };
  // to change pageSize
  const changePageSize = (limitPerPage) => {
    setPaginationData({ ...paginationData, currentPage: 1, limitPerPage });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDialog({
      open: false,
      title: "",
      buttonText: "",
      data: {
        id: null,
      },
    });
  };
  // to close the popover
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  // to set the active state
  const handleClick = (event, _id, status) => {
    setActive({ _id, status });
    setAnchorEl(event.currentTarget);
  };
  // to set the active state
  const handlePreview = (_id, status) => {
    setActive({ _id, status });
    setPreview({ id: _id, open: true });
  };
  const columns = [
    {
      field: "SrNo",
      // headerName: <Typography fontSize="15px">Sr.No</Typography>,
      width: 85,
      align: "center",
      headerAlign: "center",
      sortable: false,

      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Sr.No</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            fontSize="15px"
            sx={{
              py: 1,
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "Name",
      // headerName: <Typography fontSize="15px">Name</Typography>,
      width: 245,
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Name</Typography>,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt="userProfile"
              src={params.row.profilePicture.imageUrl}
              sx={{ width: "30px", height: "33.5px" }}
            />
            <Typography
              sx={{
                ...fontColor,
                fontWeight: 400,
                py: 1,
                ml: 2,
                color: "rgba(69, 164, 59, 1)",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                handlePreview(params.row._id, params.row.isActive);
              }}
            >
              {params.value === ""
                ? "N/A"
                : params.value
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "PhoneNumber",
      // headerName: <Typography fontSize="15px">Phone No</Typography>,
      width: 145,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Phone No</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              fontWeight: 400,
              py: 1.6,
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "TotalScore",
      // headerName: <Typography fontSize="15px">Total Score</Typography>,
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderHeader: () => (
        <Typography sx={ColumnHeader}>Total Score</Typography>
      ),
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              fontWeight: 400,
              py: 1.6,
            }}
          >
            {params.value > 1000
              ? `${(params.value / 1000).toFixed(1)}k`
              : params.value}
          </Typography>
        );
      },
    },
    {
      field: "BuzzReceive",
      // headerName: <Typography fontSize="15px">Buzz Receive</Typography>,
      align: "center",
      headerAlign: "center",
      width: 120,
      sortable: false,
      filterable: false,
      renderHeader: () => (
        <Typography sx={ColumnHeader}>Buzz Receive</Typography>
      ),
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              py: 1.6,
              fontWeight: 400,
            }}
          >
            {params.value > 1000
              ? `${(params.value / 1000).toFixed(1)}k`
              : params.value}
          </Typography>
        );
      },
    },
    {
      field: "BuzzSent",
      // headerName: <Typography fontSize="15px">Buzz Sent</Typography>,
      align: "center",
      headerAlign: "center",
      width: 120,
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Buzz Sent</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              py: 1.6,
              fontWeight: 400,
            }}
          >
            {params.value > 1000
              ? `${(params.value / 1000).toFixed(1)}k`
              : params.value}
          </Typography>
        );
      },
    },
    {
      field: "Badge",
      // headerName: <Typography fontSize="15px">Badge</Typography>,
      align: "center",
      headerAlign: "center",
      width: 115,
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Badge</Typography>,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              ...fontColor,
              py: 1.6,
              fontWeight: 400,
            }}
          >
            {params.value > 1000
              ? `${(params.value / 1000).toFixed(1)}k`
              : params.value}
          </Typography>
        );
      },
    },
    // {
    //   field: "Preview",
    //   headerName: <Typography fontSize="15px">Profile</Typography>,
    //   width: 90,
    //   align: "center",
    //   headerAlign: "center",
    //   sortable: false,
    //   filterable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Typography
    //         sx={{
    //           ...fontColor,
    //           color: "rgba(69, 164, 59, 1)",
    //           cursor: "pointer",
    //           py: 1.6,
    //         }}
    //         onClick={() => {
    //           handlePreview(params.row._id, params.row.isActive);
    //         }}
    //       >
    //         View
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: "Status",
      width: 140,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderHeader: () => <Typography sx={ColumnHeader}>Status</Typography>,
      renderCell: (params) => {
        return (
          <>
            <Box width="100%">
              <Chip
                label={params.value}
                color={params.value === "Active" ? "success" : "error"}
                // label="active"
                // color="success"
                size="small"
                sx={{
                  borderRadius: "5px",
                  minWidth: "6rem",
                  color: "white !important",
                }}
              />
            </Box>
          </>
        );
      },
    },
    {
      field: "Action",
      width: 115,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: () => <Typography sx={ColumnHeader}>Action</Typography>,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              sx={{
                fontSize: "20.33px",
                fontWeight: "400",
                cursor: "pointer",
                // py: 1.6,
              }}
              disabled={params.row.isDeleted}
              onClick={(e) =>
                handleClick(e, params.row._id, params.row.isActive)
              }
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              id={id}
              elevation={3}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Stack
                direction={"column"}
                spacing={0.5}
                component={Paper}
                variant={"gradient"}
                sx={{ width: "130px", p: 1 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    color: "#6E6B7B",
                    textDecoration: "none",
                    borderRadius: "5px",
                    // width: "138px",
                    justifyContent: "center",
                    "&:hover": {
                      bgcolor: "rgba(82, 118, 10, 1)",
                      color: "white !important",
                      "& p": {
                        color: "white !important",
                      },
                    },
                  }}
                  onClick={() => {
                    setDialog({
                      open: true,
                      title: `Are you sure you want to ${
                        active.status ? "deactivate" : "activate"
                      } this user ?`,
                      buttonText: `${
                        active.status ? "Deactivate" : "Activate"
                      }`,
                      data: {
                        id: active.id,
                      },
                    });
                    setAnchorEl(null);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      py: 0.5,
                      "&:hover": {
                        color: "white.main",
                      },
                    }}
                  >
                    {active.status ? "Deactivate User" : "Activate User"}
                  </Typography>
                </Box>
              </Stack>
            </Popover>
          </>
        );
      },
    },
  ];
  const DeActivateAction = async () => {
    dispatch(showLoader());
    try {
      const res = await api.put(
        `${apebuzzBaseURL}/api/auth/adminAuth/update/user/activation/status?userId=${active._id}`,
        {
          isActiveStatus: !active.status,
        },
      );
      if (res?.data.success) {
        setActive({
          id: "",
          status: false,
        });
        dispatch(hideLoader());
        getAllUsers();
        dispatch(
          showSnackbar({
            message: ` User ${
              active.status ? "Deactivated" : "Activated"
            } Successfully!`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      console.log("User updation error", error);
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
    console.log("Deactivate Action");
  };

  const handleClosePreview = () => {
    setPreview({ id: "", open: false });
  };
  return (
    <>
      <Stack direction={"column"}>
        <Box mt={2}>
          {/* Table component */}
          {formattedUserData?.length === 0 ? (
            "No data found"
          ) : (
            <Table
              rows={formattedUserData}
              columns={columns}
              CustomToolbar={() =>
                CustomToolbar(paginationData, changePageSize)
              }
              pageCount={paginationData.totalPages}
              pageInfo={paginationData}
              changePage={changePage}
            />
          )}
        </Box>
      </Stack>
      {dialog.open && (
        <DeleteModal
          open={dialog.open}
          handleClose={handleClose}
          dialog={dialog}
          deleteFunc={() => DeActivateAction()}
        />
      )}
      {preview.open && (
        <SingleUserPreview
          data={preview}
          handleClose={handleClosePreview}
          deleteFunc={() => DeActivateAction(preview.id)}
        />
      )}
    </>
  );
};

export default UserManagement;
