// export const BASE_URL = "http://localhost:5001/api/v1/admin/get/all/users"
export const IMG_PATH = "http://192.168.29.32:5001/image?path=";

export const apeitnowBaseURL = "https://apebuzz.apeitnow.com";

// export const apeitnowBaseURL = "http://192.168.29.149:5000";

export const apebuzzBaseURL = "https://v2.apebuzz.apeitnow.com";
// export const apebuzzBaseURL = "http://192.168.29.157:4000";

// export const apebuzzBaseURLv1 = "https://apebuzz.apeitnow.com";

export const apelabBaseURL = "https://apelab.apeitnow.com";

export const apecenterBaseURL = "https://superadmin.apeitnow.com";
// export const apecenterBaseURL = "http://192.168.29.149:8001";
