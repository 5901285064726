import React, { useRef, useState } from "react";
// import { useTranslation } from "react-i18next";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Divider,
  Grid,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import {
  CloudUpload as UploadIcon,
  Delete as TrashIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import CustomSelect from "./CustomeSelect";
import { deleteFile, uploadFiles } from "../actions/api";
import ImageLoader from "./image/ImageLoader";
import GlobelImageModal from "../../../components/modal/GlobelImageModal";
import VarientImageContainer from "./VarientImageContainer";

const maxFileSizeInBytes = 20971520;
const multiple = false;
const accept = ".jpg,.png,.jpeg";

const ProductTypeInput = ({
  selectedType,
  colorOption,
  selectedColor,
  setSelectedColor,
  data,
  selectedAttr,
  changeAttr,
  attrItemOption,
  attrItemList,
  setAttrItemList,
  variantInputList,
  handleInputChange,
  galleryImage,
  varientGallery,
  setVarientGalery,
}) => {
  const fileInputField = useRef(null);
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
  const [openModal, setOpenModal] = useState({
    open: false,
    tab: "",
    openedWith: "",
  });
  const AllProductImages = useSelector(
    (state) => state.Images.allVarientImages,
  );

  const removeFile = async (filePublicId) => {
    const uploadResponse = await deleteFile(filePublicId);
    const filteredFiles = files.filter(
      (file) => file.public_id !== filePublicId,
    );
    setFiles(filteredFiles);
    setVarientGalery(filteredFiles);
  };
  // console.log("variantInputList", variantInputList);
  // console.log("attrItemList", attrItemList);
  const handleNewFileUpload = async (e) => {
    const newFiles = e.target.files;
    if (newFiles && newFiles.length > 0) {
      const validFiles = [];
      const fileData = new FormData();
      for (let i = 0; i < newFiles.length; i = 1 + 1) {
        if (newFiles[i].size <= maxFileSizeInBytes) {
          fileData.append(`media`, newFiles[i]);
          const uploadedFile = {
            name: newFiles[i].name,
            url: URL.createObjectURL(newFiles[i]),
            public_id: "",
          };
          validFiles.push(uploadedFile);
        } else {
          alert(`File size of ${newFiles[i].name} is too large (Max 2MB)`);
        }
      }

      if (validFiles.length > 0) {
        if (!multiple) {
          const uploadResponse = await uploadFiles(fileData);
          setFiles([uploadResponse?.uploadedFiles[0]]);
          setVarientGalery([uploadResponse?.uploadedFiles[0]]);
        } else {
          const uploadResponse = await uploadFiles(fileData);
          setFiles((prevFiles) => {
            const updatedFiles = [
              ...prevFiles,
              ...uploadResponse?.uploadedFiles,
            ];
            setVarientGalery(updatedFiles);
            return updatedFiles;
          });
        }
      }
    }
  };
  const handleUploadBtnClick = () => {
    fileInputField.current?.click();
  };
  // handle open modal
  const handleOpenModal = (status, tabName, openedWith) => {
    setOpenModal({ open: status, tab: tabName, openedWith });
  };
  const handleClose = () => {
    setOpenModal({ open: false, tab: "" });
  };
  return (
    <Box>
      {selectedType === "simple" && (
        <Card sx={{ mb: 5, boxShadow: 2, borderRadius: 2 }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                {"Product Information"}
              </Typography>
            }
          />
          <Divider />
          <CardContent>
            <Box>
              <FormControl fullWidth sx={{ py: 3 }}>
                <FormLabel htmlFor="inp-6">
                  {"Item Quantity"}*({"Set -1 to make it unlimited"})
                </FormLabel>
                <TextField
                  type="number"
                  inputProps={{ min: -1 }}
                  id="inp-6"
                  name="qty"
                  defaultValue={1}
                  required
                  fullWidth
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </FormControl>
              <FormControl fullWidth sx={{ py: 3 }}>
                <FormLabel>{"sku"}*</FormLabel>
                <TextField type="text" name="sku" required fullWidth />
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      )}
      {selectedType === "variable" && (
        <Card sx={{ mb: 5, boxShadow: 2, borderRadius: 2 }}>
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                {"Product Variation"}
              </Typography>
            }
            sx={{ bgcolor: "background.paper", py: 2 }}
          />
          <Divider />
          <CardContent>
            <Box sx={{ py: 3 }}>
              <FormLabel>{"Colors"}</FormLabel>
              <Autocomplete
                size="small"
                multiple
                id="colors-autocomplete"
                options={colorOption}
                value={selectedColor}
                onChange={(event, newValue) => setSelectedColor(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label={"Select Colors"}
                    placeholder={"Select Colors"}
                  />
                )}
              />
            </Box>
            <Box sx={{ py: 3 }}>
              <FormLabel>{"Attributes"}</FormLabel>
              <Select
                fullWidth
                defaultValue=""
                size="small"
                onChange={(evt) => changeAttr(evt.target.value)}
              >
                <MenuItem value="" disabled>
                  {"Select Attribute"}
                </MenuItem>
                {data?.attribute?.map((attr, idx) => (
                  <MenuItem value={idx} key={idx}>
                    {attr?.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {selectedAttr >= 0 && (
              <Box sx={{ py: 3 }}>
                <FormLabel>{data.attribute[selectedAttr].name}</FormLabel>
                <Autocomplete
                  multiple
                  id="attribute-items-autocomplete"
                  options={attrItemOption(selectedAttr)}
                  value={attrItemList}
                  onChange={(event, newValue) => setAttrItemList(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      //   label={"Select Attribute Items"}
                      placeholder={"Select Attribute Items"}
                    />
                  )}
                />
              </Box>
            )}
            {variantInputList.length > 0 &&
              variantInputList.map((variant, index) => (
                <Box key={index}>
                  <hr />
                  <Typography variant="h6">
                    {"Variant"}:{" "}
                    {`${variant.color ? variant.color : ""} ${
                      variant.color && variant.attr ? "+" : ""
                    } ${variant.attr ? variant.attr : ""}`}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 2,
                    }}
                  >
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"Price"}*</FormLabel>
                      <TextField
                        type="number"
                        inputProps={{ min: 0, step: 0.01 }}
                        name="variantprice"
                        required
                        fullWidth
                        value={variant.variantprice || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      <Typography variant="caption" color="primary">
                        {"Set 0 to make it free"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"Description"}*</FormLabel>
                      <TextField
                        type="text"
                        inputProps={{ min: 0, step: 0.01 }}
                        name="variantdescription"
                        required
                        fullWidth
                        value={variant.variantdescription || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      <Typography variant="caption" color="primary">
                        {"Description"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"Specification"}*</FormLabel>
                      <TextField
                        type="text"
                        inputProps={{ min: 0, step: 0.01 }}
                        name="specification"
                        required
                        fullWidth
                        value={variant.specification || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      <Typography variant="caption" color="primary">
                        {"specification"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"Discount"}*</FormLabel>
                      <TextField
                        type="text"
                        inputProps={{ min: 0, step: 0.01 }}
                        name="variantdiscount"
                        required
                        fullWidth
                        value={variant.variantdiscount || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      <Typography variant="caption" color="primary">
                        {"Discount"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"Discount Percentage"}*</FormLabel>
                      <TextField
                        type="text"
                        inputProps={{ min: 0, step: 0.01 }}
                        name="varientdiscountPercentage"
                        required
                        fullWidth
                        value={variant.varientdiscountPercentage || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      <Typography variant="caption" color="primary">
                        {"Discount Percentage"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"sku"}*</FormLabel>
                      <TextField
                        type="text"
                        name="sku"
                        required
                        fullWidth
                        value={variant.sku || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                      />
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"Item Quantity"}*</FormLabel>
                      <TextField
                        type="number"
                        inputProps={{ min: -1 }}
                        name="qty"
                        required
                        fullWidth
                        value={variant.qty || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      <Typography variant="caption" color="primary">
                        {"Set -1 to make it unlimited"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"Barcode"}*</FormLabel>
                      <TextField
                        type="string"
                        inputProps={{ min: -1 }}
                        name="barcode"
                        required
                        fullWidth
                        value={variant.barcode || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      <Typography variant="caption" color="primary">
                        {"Set -1 to make it unlimited"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 2 }}>
                      {/* <CustomSelect
                        list={galleryImage || []}
                        dataChange={handleInputChange}
                        rootIndex={index}
                      /> */}
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          color: "#4D5464",
                        }}
                      >
                        {`Varient images(465px x 441px)`}
                      </Typography>
                      {AllProductImages?.length !== 0 && (
                        <VarientImageContainer
                          img={AllProductImages}
                          // setDisplayProductImage={setDisplayProductImage}
                          tab={openModal.tab}
                        />
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() =>
                            handleOpenModal(true, "Product", "Product")
                          }
                          startIcon={<UploadIcon />}
                          sx={{
                            bgcolor: "#CFEDCC",
                            color: "#61B15A",
                            margin: 1,
                            "&:hover": {
                              backgroundColor: "rgba(82, 118, 10, 1)",
                              boxShadow: "none",
                              color: "white",
                            },
                          }}
                        >
                          Add Image
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </CardContent>
        </Card>
      )}
      {openModal.open && (
        <GlobelImageModal
          open={openModal}
          handleClose={handleClose}
          // updateFilesCb={updateDisplayImage}
          // resetCb={resetImageInput}
          // updateDisplayProductImage={updateDisplayProductImage}
          // updateProductVideo={updateProductVideo}
        />
      )}
    </Box>
  );
};

export default ProductTypeInput;
