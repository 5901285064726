import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";

// mock
import { useDispatch, useSelector } from "react-redux";

import { GridCloseIcon } from "@mui/x-data-grid";
import { hideLoader } from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import { selectPanel } from "../../../ReduxToolkit/Slices/adminPanelSlice";

// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";

import { navConfig } from "./config";
import { apeitNowConfig } from "./apeitNowConfig";
import { getErrorMessage } from "../../../reusablecomponents/CustomError/errorMessages";

import yellowAvatar from "../../../Assets/maonky_NEW.png";
import apeLabs from "../../../Assets/apeLabs.png";
import { apecenterBaseURL } from "../../../utils/url";
import superAdminConfig from "./superAdminConfig";
import api from "../../../utils/axios";
import { getAllowedRoutes } from "../../../reusablecomponents/RolePrmission/rolesPermissionAccess";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};
const panelIcons = {
  apebuzz: yellowAvatar,
  apelabs: apeLabs,
  apeitnow: apeLabs, // Assuming the same icon for simplicity
  superadmin: yellowAvatar,
};
export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedPanel = useSelector((state) => state.panel.selectedPanel);
  const adminAuth = useSelector((state) => state.auth);

  const [values, setValues] = useState(null);
  const adminProfile = async () => {
    try {
      // const res = await api.get(
      //   `${apebuzzBaseURL}/api/auth/adminAuth/get/profile/data`,
      // );

      const res = await api.get(
        `${apecenterBaseURL}/api/v1/ape-control-center/subadmin/get/profile`,
      );

      setValues(res?.data.adminData);

      dispatch(hideLoader());
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    adminProfile();
  }, []);

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          p: 1,
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
          boxShadow:
            "0px 5px 5px -3px rgba(145, 158, 171, 0.2), 0px 8px 10px 1px rgba(145, 158, 171, 0.14), 0px 3px 14px 2px rgba(145, 158, 171, 0.12)",
          margin: 0,
        }}
      >
        {selectedPanel === "anonymous" ? (
          <Button variant="outlined" disabled fullWidth>
            no access
          </Button>
        ) : (
          <Select
            fullWidth
            id="select-admin-panel"
            value={selectedPanel}
            name="adminPanel"
            size="small"
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: "none" },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  // border: "1px solid rgba(97, 177, 90, 1) ",
                  // boxShadow: "none",
                },
              },
            }}
            onChange={(e) => {
              const panel = e.target.value;
              dispatch(selectPanel(panel));

              const panelRoutes = {
                apebuzz: "/dashboard/home",
                apelabs: "/dashboard/apelabs",
                apeitnow: "/dashboard/apeitnow",
                superadmin: "/dashboard/role-management",
              };

              if (panel === "apebuzz") {
                const allowedPaths = getAllowedRoutes(
                  navConfig,
                  adminAuth?.user?.roles,
                );
                if (allowedPaths.length > 0) {
                  navigate(allowedPaths[0]?.path);
                } else navigate(panelRoutes[panel]);
              } else if (panel === "apeitnow") {
                const allowedPaths = getAllowedRoutes(
                  apeitNowConfig,
                  adminAuth?.user?.roles,
                );
                if (allowedPaths.length > 0) {
                  navigate(allowedPaths[0]?.path);
                } else navigate(panelRoutes[panel]);
              } else if (panel === "apelab") {
                const allowedPaths = getAllowedRoutes(
                  apeitNowConfig,
                  adminAuth?.user?.roles,
                );
                if (allowedPaths.length > 0) {
                  navigate(allowedPaths[0]?.path);
                } else navigate(panelRoutes[panel]);
              } else if (panel === "superadmin") {
                navigate("/dashboard/role-management");
              } else {
                navigate("/404");
              }
            }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  alt="panelIcon"
                  src={panelIcons[selected]}
                  style={{ width: 28, height: 28, marginRight: 8 }}
                />
                <Typography textTransform={"capitalize"}>{selected}</Typography>
              </Box>
            )}
          >
            {(adminAuth?.user?.superAdmin
              ? ["apebuzz", "apelabs", "apeitnow", "superadmin"]
              : ["apebuzz", "apelabs", "apeitnow"]
            )
              .filter(
                (value) =>
                  adminAuth?.user?.superAdmin ||
                  adminAuth?.user?.apps?.some(
                    (app) => app.name === value.toLowerCase(),
                  ), // Check if the app's name matches
              )
              .map((value) => (
                <MenuItem
                  key={value}
                  value={value}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px",
                    margin: "5px",
                    boxShadow:
                      selectedPanel === value
                        ? "0px 5px 5px -3px rgba(145, 158, 171, 0.2), 0px 8px 10px 1px rgba(145, 158, 171, 0.14), 0px 3px 14px 2px rgba(145, 158, 171, 0.12)"
                        : "none",
                    borderRadius: selectedPanel === value ? "8px" : "0px",
                  }}
                >
                  <img
                    alt="panelIcon"
                    src={panelIcons[value]}
                    style={{ width: 28, height: 28, marginRight: 8 }}
                  />
                  <Typography textTransform={"capitalize"}>{value}</Typography>
                </MenuItem>
              ))}
          </Select>
        )}
      </Box>
      {selectedPanel === "apebuzz" && (
        <NavSection
          data={
            adminAuth?.user?.superAdmin
              ? navConfig
              : getAllowedRoutes(navConfig, adminAuth?.user?.roles)
          }
        />
      )}
      {selectedPanel === "apelabs" && (
        <NavSection
          data={
            adminAuth?.user?.superAdmin
              ? apeitNowConfig
              : getAllowedRoutes(apeitNowConfig, adminAuth?.user?.roles)
          }
        />
      )}
      {selectedPanel === "apeitnow" && (
        <NavSection
          data={
            adminAuth?.user?.superAdmin
              ? apeitNowConfig
              : getAllowedRoutes(apeitNowConfig, adminAuth?.user?.roles)
          }
        />
      )}

      {selectedPanel === "superadmin" && (
        <NavSection
          data={
            adminAuth?.user?.superAdmin
              ? superAdminConfig
              : getAllowedRoutes(superAdminConfig)
          }
        />
      )}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
  const ResponsiveRenderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ mb: 2, mx: 1.1, mt: 2 }}>
        {selectedPanel === "anonymous" ? (
          <Button variant="outlined" disabled fullWidth>
            no access
          </Button>
        ) : (
          <Select
            fullWidth
            id="select-admin-panel"
            value={selectedPanel}
            name="adminPanel"
            size="small"
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: "none" },
            }}
            onChange={(e) => {
              dispatch(selectPanel(e.target.value));
              if (e.target.value === "apebuzz") {
                const allowedPaths = getAllowedRoutes(
                  navConfig,
                  adminAuth?.user?.roles,
                );
                if (allowedPaths.length > 0) {
                  navigate(allowedPaths[0]?.path);
                } else navigate("/dashboard/home");
              }
              if (e.target.value === "apelabs") {
                const allowedPaths = getAllowedRoutes(
                  apeitNowConfig,
                  adminAuth?.user?.roles,
                );
                if (allowedPaths.length > 0) {
                  navigate(allowedPaths[0]?.path);
                } else navigate("/dashboard/apelabs");
              }
              if (e.target.value === "apeitnow") {
                const allowedPaths = getAllowedRoutes(
                  apeitNowConfig,
                  adminAuth?.user?.roles,
                );
                if (allowedPaths.length > 0) {
                  navigate(allowedPaths[0]?.path);
                } else navigate("/dashboard/apeitnow");
              }
              if (e.target.value === "superadmin") {
                navigate("/dashboard/role-management");
              }
            }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  alt="panelIcon"
                  src={panelIcons[selected]}
                  style={{ width: "28px", height: "28px", marginRight: "8px" }}
                />
                <Typography textTransform={"capitalize"}>
                  {selectedPanel}
                </Typography>
              </Box>
            )}
          >
            {adminAuth && adminAuth?.user?.superAdmin
              ? ["apebuzz", "apelabs", "apeitnow", "superadmin"].map(
                  (value, index) => (
                    <MenuItem
                      key={index}
                      value={value}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "10px",
                        margin: "5px",
                        boxShadow:
                          selectedPanel === value
                            ? "0px 5px 5px -3px rgba(145, 158, 171, 0.2), 0px 8px 10px 1px rgba(145, 158, 171, 0.14), 0px 3px 14px 2px rgba(145, 158, 171, 0.12)"
                            : "none",
                        borderRadius: selectedPanel === value ? "8px" : "0px",
                      }}
                    >
                      <img
                        alt="panelIcon"
                        src={panelIcons[value]}
                        style={{
                          width: "28px",
                          height: "28px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography textTransform={"capitalize"}>
                        {value}
                      </Typography>
                    </MenuItem>
                  ),
                )
              : ["apebuzz", "apelabs", "apeitnow"]
                  .filter(
                    (value) =>
                      adminAuth?.user?.superAdmin ||
                      adminAuth?.user?.apps?.some(
                        (app) => app.name === value.toLowerCase(),
                      ), // Check if the app's name matches
                  )
                  .map((value, index) => (
                    <MenuItem
                      key={index}
                      value={value}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        // bgcolor: "yellow",
                      }}
                    >
                      <img
                        alt="panelIcon"
                        src={panelIcons[value]}
                        style={{
                          width: "28px",
                          height: "28px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography>{value}</Typography>
                    </MenuItem>
                  ))}
          </Select>
        )}
      </Box>
      {selectedPanel === "apebuzz" && (
        <NavSection
          data={
            adminAuth?.user?.superAdmin
              ? navConfig
              : getAllowedRoutes(navConfig, adminAuth?.user?.roles)
          }
        />
      )}
      {selectedPanel === "apelabs" && (
        <NavSection
          data={
            adminAuth?.user?.superAdmin
              ? apeitNowConfig
              : getAllowedRoutes(apeitNowConfig, adminAuth?.user?.roles)
          }
        />
      )}
      {selectedPanel === "apeitnow" && (
        <NavSection
          data={
            adminAuth?.user?.superAdmin
              ? apeitNowConfig
              : getAllowedRoutes(apeitNowConfig, adminAuth?.user?.roles)
          }
        />
      )}

      {selectedPanel === "superadmin" && (
        <NavSection
          data={
            adminAuth?.user?.superAdmin
              ? superAdminConfig
              : getAllowedRoutes(superAdminConfig)
          }
        />
      )}
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          <Box
            sx={{
              px: 1,
              // py: 3,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              // position: "absolute",
              // top: 5,
              // right: -5,
            }}
          >
            {/* <Logo /> */}
            <IconButton
              onClick={onCloseNav}
              color="error"
              sx={{ fontSize: "15px" }}
            >
              <GridCloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          {ResponsiveRenderContent}
        </Drawer>
      )}
    </Box>
  );
}
